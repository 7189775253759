import { Grid, Typography, useTheme } from "@mui/material"

import { FormattedMessage } from "react-intl"
import { OceanCurrentListRow } from "./OceanCurrentListRow"

interface OceanCurrentProps {
  oceancurrentList: Current[]
}
export const OceanCurrentOverview: React.FC<OceanCurrentProps> = ({
  oceancurrentList,
}) => {
  const theme = useTheme()

  return (
    <Grid container sx={{ backgroundColor: theme.palette.background.default }}>
      <Grid
        container
        sx={{ marginBottom: theme.spacing(2) }}
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <Typography variant={"h1"}>
            <FormattedMessage id="ocean_current" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {oceancurrentList.map((oceancurrent) => (
            <OceanCurrentListRow
              oceancurrent={oceancurrent}
              key={oceancurrent.id}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
