import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { downloadFileMetadata } from "services/downloadFile"

type Props = {
  url: string
}
export const useOffshoreURLLastModified = ({
  url,
}: Props): { lastModified: string | undefined | null } => {
  const [lastModified, setLastModified] = useState<string | null>()

  const intl = useIntl()

  useEffect(() => {
    downloadFileMetadata({ url })
      .then(({ lastModified }) => {
        if (lastModified === null) {
          setLastModified("")
          return
        }
        setLastModified(lastModified.toISO())
      })
      .catch(() => {
        setLastModified("")
      })
  }, [url, intl])

  return { lastModified }
}
