import { AppBar, Container, Grid, Toolbar } from "@mui/material"
import Box from "@mui/material/Box"
import React from "react"
import { LunaLogo } from "./LunaLogo"
import { SettingsMenu } from "./SettingsMenu"
import { LunaLink } from "./LunaLink"
import { useIntl } from "react-intl"

interface HeaderProps {
  handleLocaleChange: () => void

  /**
   * Preview is used to deactivate features when header
   * is embedded within other applications.
   */
  type: "energy" | "offshore" | "preview"
}

// {intl.formatMessage({ id: "wind_speed" })}
const Header: React.FC<HeaderProps> = ({ type, handleLocaleChange }) => {
  const intl = useIntl()
  return (
    <AppBar position={"relative"} elevation={0}>
      <Container>
        <Box py={2}>
          <Toolbar disableGutters>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item xs={4}>
                <Box paddingLeft={1}>
                  {type === "preview" ? (
                    <LunaLogo role="img" style={{ verticalAlign: "middle" }} />
                  ) : (
                    <LunaLink
                      href="/"
                      title="Luna"
                      aria-label={intl.formatMessage({ id: "logo_aria_label" })}
                    >
                      <LunaLogo
                        role="img"
                        style={{ verticalAlign: "middle" }}
                      />
                    </LunaLink>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                container
                xs={8}
                justifyContent="flex-end"
                alignContent="center"
                spacing={1}
              >
                {type !== "preview" && (
                  <>
                    <Grid item>
                      <SettingsMenu
                        handleLocaleChange={handleLocaleChange}
                        type={type}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </Box>
      </Container>
    </AppBar>
  )
}

export default Header
