import { AvailableMapFileID } from "./AvailableMaps.service"

export const useMapDetails = () => {
  const mapType: AvailableMapFileID = `scandinavia_wind_2m`
  return {
    mapType,
    timeIncrement: { hour: 3 },
    modelStart: { hour: 9, minute: 0, second: 0 },
  }
}
