export const drawArrow = (
  chart: Highcharts.Chart,
  esp_0: number,
  outerArrowRef: React.MutableRefObject<Highcharts.SVGElement | null>,
  innerArrowRef: React.MutableRefObject<Highcharts.SVGElement | null>,
  minified = false
) => {
  const centerX = chart.plotLeft + chart.plotWidth / 2
  const centerY = chart.plotTop + chart.plotHeight / 2
  const arrowLength = minified
    ? chart.plotHeight * 0.5
    : chart.plotHeight * 0.25

  // Convert esp_0 direction to radians
  const angleRad = (esp_0 - 90) * (Math.PI / 180)

  // Calculate the arrow's tip position on the rose edge
  const arrowTipX = centerX + arrowLength * Math.cos(angleRad)
  const arrowTipY = centerY + arrowLength * Math.sin(angleRad)

  // Define arrowhead length and width
  const arrowHeadLength = minified ? 10 : 18 // Length of the arrowhead

  // Calculate position of the tail
  const arrowTailX =
    centerX + (arrowLength - arrowHeadLength) * Math.cos(angleRad)
  const arrowTailY =
    centerY + (arrowLength - arrowHeadLength) * Math.sin(angleRad)

  // Calculate positions for the left and right wings of the arrowhead
  const leftWingX =
    arrowTipX - arrowHeadLength * Math.cos(angleRad - Math.PI / 6)
  const leftWingY =
    arrowTipY - arrowHeadLength * Math.sin(angleRad - Math.PI / 6)
  const rightWingX =
    arrowTipX - arrowHeadLength * Math.cos(angleRad + Math.PI / 6)
  const rightWingY =
    arrowTipY - arrowHeadLength * Math.sin(angleRad + Math.PI / 6)

  // Clean up the old arrows if it exists
  if (innerArrowRef.current) {
    innerArrowRef.current.destroy()
  }
  if (outerArrowRef.current) {
    outerArrowRef.current.destroy()
  }

  innerArrowRef.current = chart.renderer
    .path([
      "M",
      centerX,
      centerY,
      "L",
      arrowTailX,
      arrowTailY,
      "L",
      arrowTipX,
      arrowTipY,
      "M",
      arrowTipX,
      arrowTipY,
      "L",
      rightWingX,
      rightWingY,
      leftWingX,
      leftWingY,
      "Z",
    ] as any)
    .attr({
      "stroke-width": minified ? 8 : 9,
      stroke: minified ? "black" : "white",
      "stroke-linejoin": "butt",
      zIndex: 3,
    })
    .add()
  outerArrowRef.current = chart.renderer
    .path([
      "M",
      centerX,
      centerY,

      "L",
      arrowTailX,
      arrowTailY,
      "L",
      arrowTipX,
      arrowTipY,
      "M",
      arrowTipX,
      arrowTipY,
      "L",
      rightWingX,
      rightWingY,
      "L",
      leftWingX,
      leftWingY,
      "Z",
    ] as any)
    .attr({
      "stroke-width": 6.5,
      stroke: minified ? "white" : "black",
      "stroke-linejoin": "butt",
      zIndex: 4,
    })
    .add()
}
