import { IntlShape } from "react-intl"
import {
  TimezoneID,
  loadJSONFromLocalStorage,
  roundToDecimals,
  saveInLocalStorage,
  WindUnit,
} from "@luna/luna-core"
import * as Moment from "moment"
import { extendMoment } from "moment-range"
import { convertToKnots } from "@luna/luna-core"
import { padStart } from "lodash"
import Highcharts, { SeriesOptionsType } from "highcharts/highstock"
import HcMore from "highcharts/highcharts-more"
HcMore(Highcharts)
const moment = extendMoment(Moment)

const lunaColors = {
  almostBlack: "#323232",
  veryLight: "rgba(96, 98, 113, 0.2)",
  transparent: "rgba(255,255,255,0 )",

  current_speed: "#323232",
  current_speed_mean: "#00BCD4",
  current_speed_median: "#2D11D8",
  current_speed_percentile: "#88A0A3",

  threshold: "#D900FD",
  plotLine: "#E0E0E0",
}

export type SeriesVisibility = {
  current_speed: boolean
  current_speed_mean: boolean
  current_speed_median: boolean
  current_speed_percentile5_95: boolean
}

type Series = keyof SeriesVisibility

const defaultSeries: SeriesVisibility = {
  current_speed: true,
  current_speed_mean: true,
  current_speed_median: true,
  current_speed_percentile5_95: true,
}

const yAxis = {
  current_speed: 0,
}

export type OverrideVisibilityOptions = Partial<SeriesVisibility> | true

type ChartBasis = {
  overrideVisibleSeries?: OverrideVisibilityOptions
  forecast: OffshoreForecast
  intl: IntlShape
  currentTimezone: TimezoneID
  windUnit: WindUnit
}

let getSeriesVisibility: (series: Series) => boolean

const plotLineId = "thresholdLine"

const threshold_value = 0.2

const plotLineOptions = {
  id: plotLineId,
  value: threshold_value,
  width: 2,
  color: lunaColors.threshold,
  dashStyle: "ShortDash",
}
export const calculateChartOptions = (
  chartBasis: ChartBasis
): Highcharts.Options => {
  const { forecast, intl, overrideVisibleSeries, windUnit } = chartBasis

  getSeriesVisibility = initialiseSeriesVisibilityFunc(overrideVisibleSeries)

  const timeseries = forecast.forecast

  const windUnitIsKnots = windUnit === "KT"

  return {
    credits: {
      enabled: false,
    },

    navigator: {
      enabled: false,
    },

    rangeSelector: {
      enabled: false,
    },

    chart: {
      height: "20%",
      spacingBottom: 20,

      zooming: {
        type: "x",
        mouseWheel: {
          enabled: false,
        },
      },

      style: {
        fontFamily: `"Simplon BP Regular", "sans-serif"`,
        fontSize: "14px",
      },
    },
    tooltip: {
      shared: true,
      style: {
        fontSize: "16px",
      },
      headerFormat: `<span style="font-size: 16px"><strong>{point.key}</strong></span><br/>`,
    },
    scrollbar: { enabled: false },

    title: {
      text: "",
    },
    legend: {
      enabled: true,
      itemMarginBottom: -20,
      itemStyle: {
        fontSize: "22px",
        textDecoration: "none",
      },
      itemHiddenStyle: {
        color: "#808080",
      },
      itemHoverStyle: {
        textDecoration: "underline",
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
        events: {
          legendItemClick: (event) => {
            saveSeriesVisibility(
              event.target.options.id as Series,
              event.target.visible
            )
          },
        },
        showInNavigator: false,
      },
    },

    xAxis: {
      type: "datetime",
      offset: 0,
      dateTimeLabelFormats: {
        hour: "%H",
        day: "%H",
      },
      tickPixelInterval: 50,
      crosshair: {
        width: 3,
      },
      tickLength: 0,
      showLastLabel: true,
      min: moment.utc(timeseries[0].valid).valueOf(),
      labels: {
        formatter: function () {
          return ""
        },
      },
    },
    yAxis: [
      {
        startOnTick: true,
        tickInterval: 0.2,

        offset: 0,

        title: {
          text: `${intl.formatMessage({
            id: "current_speed",
          })} [${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}]`,

          style: {
            color: lunaColors.current_speed,
            fontWeight: "bold",
            fontSize: "18px",
          },
        },
        // convertToKnots({ metersPerSecond: data["current_speed_mean"] })
        plotLines: [
          {
            ...plotLineOptions,
            value: windUnitIsKnots
              ? convertToKnots({ metersPerSecond: threshold_value })
              : threshold_value,
            dashStyle: "ShortDash", // Fix: Assign a valid DashStyleValue
          },
        ],
        labels: {
          format: "{value}",
          style: {
            color: lunaColors.current_speed,
            fontSize: "16px",
          },
        },
        opposite: false,
      },
    ],

    exporting: {
      buttons: {
        contextButton: { enabled: false },
      },
    },
    series: [
      ...getCurrentPlots(chartBasis),
      ...getCurrentMeanPlots(chartBasis),
      ...getCurrentMedianPlots(chartBasis),
      ...getCurrentPercentileRangePlots(chartBasis),
      ...getThresholdPlot(chartBasis),
    ] as Highcharts.SeriesOptionsType[],
  }
}

const getThresholdPlot = ({
  intl,
  windUnit,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"
  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.threshold,
      marker: {
        enabled: false,
      },
      name: intl.formatMessage({ id: "threshold_value" }),

      dashStyle: "ShortDash",
      events: {
        legendItemClick: function (e) {
          if (this.visible) {
            this.chart.yAxis[0].removePlotLine(plotLineId)
          } else {
            this.chart.yAxis[0].addPlotLine({
              ...plotLineOptions,
              value: windUnitIsKnots
                ? convertToKnots({ metersPerSecond: threshold_value })
                : threshold_value,
              dashStyle: "ShortDash",
            })
          }
        },
      },
    },
  ]
}

const getCurrentMeanPlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"
  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.current_speed_mean,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed_mean"),

      name: intl.formatMessage({ id: "current_speed_mean" }),
      id: "current_speed_mean",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { y },
          } = this as any
          return `
                <span style="color:${color}">\u25CF</span>
                ${name} <b>${roundToDecimals(y, 2)} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b><br/>`
        },
      },

      lineWidth: 2,
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          moment.utc(valid).valueOf(),
          windUnitIsKnots
            ? convertToKnots({ metersPerSecond: data["current_speed_mean"] })
            : data["current_speed_mean"],
        ]
      }),
    },
  ]
}

const getCurrentMedianPlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"
  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.current_speed_median,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed_median"),

      name: intl.formatMessage({ id: "current_speed_median" }),
      id: "current_speed_median",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { y },
          } = this as any
          return `
                <span style="color:${color}">\u25CF</span>
                ${name} <b>${roundToDecimals(y, 2)} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b><br/>`
        },
      },

      lineWidth: 2,
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          moment.utc(valid).valueOf(),
          windUnitIsKnots
            ? convertToKnots({ metersPerSecond: data["current_speed_median"] })
            : data["current_speed_median"],
        ]
      }),
    },
  ]
}
const getCurrentPercentileRangePlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): Highcharts.SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"
  return [
    {
      type: "arearange",
      id: "percentileRange",
      yAxis: yAxis.current_speed,
      color: lunaColors.current_speed_percentile,
      fillOpacity: 0.2,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed_percentile5_95"),

      name: intl.formatMessage({ id: "current_speed_percentile5_95" }),
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            high,
            low,
          } = this as any
          return `
                <span style="color:${color}">\u25CF</span>
                ${name} <b>${roundToDecimals(low, 2)} - ${roundToDecimals(
            high,
            3
          )} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b><br/>`
        },
      },
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          moment.utc(valid).valueOf(),
          windUnitIsKnots
            ? convertToKnots({
                metersPerSecond: data["current_speed_percentile5"],
              })
            : data["current_speed_percentile5"],
          windUnitIsKnots
            ? convertToKnots({
                metersPerSecond: data["current_speed_percentile95"],
              })
            : data["current_speed_percentile95"],
        ]
      }),
    },
  ]
}

const getCurrentPlots = ({
  forecast,
  intl,
  windUnit,
}: ChartBasis): SeriesOptionsType[] => {
  const windUnitIsKnots = windUnit === "KT"
  return [
    {
      type: "line",
      yAxis: yAxis.current_speed,

      color: lunaColors.current_speed,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("current_speed"),

      name: intl.formatMessage({ id: "current_speed" }),
      id: "current_speed",

      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { y: current, direction },
          } = this as any
          const paddedDirection = padStart(direction, 3, "0")
          return `
                <span style="color:${color}">\u25CF</span>
                ${name}: <b>${paddedDirection}° / 
                ${roundToDecimals(current, 2)} ${intl.formatMessage({
            id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
          })}</b>
                <br/>`
        },
      },

      lineWidth: 2,

      data: forecast.forecast.map(({ valid, data }) => ({
        x: moment.utc(valid).valueOf(),
        y: windUnitIsKnots
          ? convertToKnots({ metersPerSecond: data["current_speed"] })
          : data["current_speed"],
        direction: data["current_direction"],
      })),
    },
  ]
}

const getCompleteSeriesVisibility = () => {
  return (
    loadJSONFromLocalStorage<SeriesVisibility>({
      key: "OceanCurrentSeriesVisibility",
      returnIfNull: { ...defaultSeries },
    }) || defaultSeries
  )
}

/**
 * Purpose: Make it possible to create a getSeriesVisibility function that lets
 * itself be overwritten.
 * @param overrideVisibleSeries If this is 'true' then use default series. Otherwise allow object to the passed in.
 *
 */
const initialiseSeriesVisibilityFunc = (
  overrideVisibleSeries?: OverrideVisibilityOptions
) => {
  return function getSeriesVisibility(series: Series): boolean {
    if (typeof overrideVisibleSeries === "boolean") {
      return defaultSeries[series]
    } else if (overrideVisibleSeries) {
      return !!overrideVisibleSeries[series]
    }
    const seriesFromStorage = getCompleteSeriesVisibility()
    return seriesFromStorage[series]
  }
}

const saveSeriesVisibility = (series: Series, hidden: boolean) => {
  const seriesFromStorage = getCompleteSeriesVisibility()
  seriesFromStorage[series] = !hidden
  saveInLocalStorage<SeriesVisibility>({
    key: "OceanCurrentSeriesVisibility",
    data: seriesFromStorage,
  })
}
