import { SeriesOptionsType } from "highcharts"
import { IntlShape } from "react-intl"
import { padStart } from "lodash"
import { roundToDecimals } from "@luna/luna-core"
import {
  OffshoreDataList,
  OffshoreForecastData,
  OffshoreSeriesKeys,
  TooltipConfig,
} from "../../../../@types/OffshoreGrapOption"
import { forecastOptionsConfig } from "./graphOptionsForecastConfig"
import { ObservedOptionsConfig } from "./graphOptionsObservedConfig"
import { observedAffix } from "./graphStaticValues"
import { getSeriesVisibility } from "./offshoreGraphOptions"

function toolTipFormatter(
  tooltipOptions: TooltipConfig,
  windUnitIsKnots: boolean,
  intl: IntlShape
) {
  return {
    pointFormatter: function () {
      const {
        color,
        series: { name },
        options: { y, direction },
      } = this as any
      const value = roundToDecimals(
        parseFloat(y),
        tooltipOptions.value.decimalPoints
      )
      const unit =
        tooltipOptions.value.unit === "wind_unit"
          ? intl.formatMessage({
              id: windUnitIsKnots ? "wind_unit" : "wind_unit_ms",
            })
          : tooltipOptions.value.unit

      const title = tooltipOptions.isObs ? `Obs: ${name}` : name

      if (tooltipOptions.direction) {
        const directionValue = padStart(
          roundToDecimals(
            direction,
            tooltipOptions.direction.decimalPoints
          ).toString(),
          3,
          "0"
        )
        return `<span style="color:${color}">\u25CF</span>${title}: <b>${directionValue}${tooltipOptions.direction.unit} / ${value} ${unit}</b><br/>`
      }
      return `<span style="color:${color}">\u25CF</span>${title}: <b>${value} ${unit}</b><br/>`
    },
  }
}

export function createForecastOffshorePlots(
  offshoreData: OffshoreForecastData,
  intl: IntlShape,
  windUnitIsKnots: boolean
): SeriesOptionsType[] {
  return Object.entries(forecastOptionsConfig()).map(([id, defaultConfig]) => {
    const { formatData, tooltipOptions, values } = defaultConfig.tools
    return {
      ...defaultConfig?.series,
      visible: getSeriesVisibility(id as OffshoreSeriesKeys),
      name: intl.formatMessage({ id }),
      tooltip: tooltipOptions
        ? toolTipFormatter(tooltipOptions, windUnitIsKnots, intl)
        : {
            pointFormatter: function () {
              return ""
            },
          },
      data: offshoreData.forecast.map(({ valid, data }) => {
        return formatData({
          valid,
          data,
          valueId: values.valueId,
          directionId: values.directionId,
          windUnitIsKnots,
        })
      }),
    } as SeriesOptionsType
  }, [] as SeriesOptionsType[])
}

export function createObservedOffshorePlots(
  observedOffshoreData: OffshoreDataList[],
  intl: IntlShape,
  windUnitIsKnots: boolean
): SeriesOptionsType[] {
  if (!observedOffshoreData.length) return []

  return Object.entries(ObservedOptionsConfig()).map(([id, defaultConfig]) => {
    const { formatData, tooltipOptions, values } = defaultConfig.tools
    const filterObsData = observedOffshoreData.filter(
      (obs) => obs.qualitycode === 0
    )

    const obsData = filterObsData.map(({ valid, data, qualitycode }) => {
      return formatData({
        valid,
        data,
        valueId: values.valueId,
        directionId: values.directionId,
        windUnitIsKnots,
        qualitycode: qualitycode,
      })
    })

    if (values.valueId?.startsWith(observedAffix) && tooltipOptions) {
      tooltipOptions.isObs = true
    }

    obsData.sort((a, b) => a.x - b.x)

    return {
      ...defaultConfig?.series,
      visible: getSeriesVisibility(id as OffshoreSeriesKeys),
      name: intl.formatMessage({ id }),
      tooltip: tooltipOptions
        ? toolTipFormatter(tooltipOptions, windUnitIsKnots, intl)
        : {
            pointFormatter: function () {
              return ""
            },
          },
      data: obsData,
    } as SeriesOptionsType
  }, [] as SeriesOptionsType[])
}
