import { Box, Container, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"
import OffshoreMaps from "../OffshoreMaps/OffshoreMaps"

export const OffshoreMenuMaps: React.FC = () => {
  return (
    <Container maxWidth={"xl"}>
      <Box paddingLeft={2} paddingBottom={3}>
        <Typography variant={"h1"}>
          <FormattedMessage id="weather_maps" />
        </Typography>
      </Box>

      <Box paddingLeft={2} paddingRight={2} paddingBottom={5}>
        <OffshoreMaps />
      </Box>
    </Container>
  )
}
