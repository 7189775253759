import React, { FC, ReactElement } from "react"
import TableBody from "@mui/material/TableBody"
import MetTableRow from "./MetTableRow"
import { ActiveCriteria } from "./OffshoreTable.hook"
import { useIntl } from "react-intl"
import MetTableRowDateDivider from "./MetTableRowDateDivider"
import { RowData, ColumnGroup } from "./OffshoreTable"
import { useTheme } from "@mui/material/styles"

export const OffshoreTableBody: FC<{
  rows: RowData[]
  currentTimezone: string
  columnGroups: ColumnGroup[]
  activeCriteria: ActiveCriteria
}> = ({ activeCriteria, columnGroups, currentTimezone, rows }) => {
  const theme = useTheme()
  const intl = useIntl()

  const totalColumns = columnGroups.reduce(
    (total, { columns }) => total + columns.length,
    0
  )
  return (
    <TableBody>
      {rows.reduce<ReactElement[]>((acc, row, i) => {
        const rowKey = row.dateTime.format("YYYY-DD-MM-HH-mm")
        /**
         * Compare this and previous row to figure out if we should
         * add a day divider.
         */
        const isNewDay = () =>
          rows[i - 1].dateTime.tz(currentTimezone).day() !==
          row.dateTime.tz(currentTimezone).day()
        if (i === 0 || isNewDay()) {
          acc.push(
            <MetTableRowDateDivider
              key={`${rowKey}-divider`}
              totalColumns={totalColumns}
            >
              {row.dateTime.locale(intl.locale).format("dddd DD.MM.YYYY")}
            </MetTableRowDateDivider>
          )
        }
        const backgroundColor =
          i % 2 === 0
            ? theme.palette.background.default
            : theme.palette.background.paper
        acc.push(
          <MetTableRow
            key={`${rowKey}`}
            columnGroups={columnGroups}
            row={row}
            activeCriteria={activeCriteria}
            backgroundColor={backgroundColor}
          />
        )
        return acc
      }, [])}
    </TableBody>
  )
}
