import { Link as MuiLink } from "@mui/material"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

/**
 * Link combines Material UI's link with react-router-dom. This
 * gives us styling and zero-refresh navigation.
 *
 * Learn more: {@link https://mui.com/guides/routing/#link}
 *
 * This file was copied here from app-luna-frontend because this
 * package is in a separate folder and can't simply import from
 * app-luna-frontend. That would create a circular loop.
 */
export const LunaLink = React.forwardRef(({ to, href, ...rest }: any, ref) => {
  // Handle being passed either 'to' or 'href'
  const toInput = to || href || ""

  // If the link is a plain http link we trigger a normal browser navigation.
  if (typeof toInput === "string" && toInput.startsWith("http")) {
    return (
      <MuiLink ref={ref as any} underline="always" {...rest} href={toInput} />
    )
  }
  return (
    <MuiLink
      ref={ref as any}
      underline="always"
      {...rest}
      to={toInput}
      component={RouterLink}
    />
  )
})
