import { createContext, useContext } from "react"

export type WindUnit = "m/s" | "KT"

export const WindUnitContext = createContext<{
  windUnit: WindUnit
  handleWindUnitChange: (windUnit: WindUnit) => void
}>({ windUnit: "KT", handleWindUnitChange: () => {} })

export function useWindUnit() {
  return useContext(WindUnitContext)
}
