import React from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { Link } from "components/Link"
import { config } from "config"
import moment from "moment"
import { useIntl } from "react-intl"
import { useOffshoreURLLastModified } from "./useOffshoreURLLastModified"
import { useTimezone } from "@luna/luna-core"

interface TextForecastListProps {
  text: TextForecast
}

export const TextForecastListRow: React.FC<TextForecastListProps> = ({
  text,
}) => {
  const theme = useTheme()
  const { currentTimezone } = useTimezone()

  const colorGray = "#555555"
  const intl = useIntl()
  const { locale } = useIntl()

  const linkTo = text.has_maps
    ? "/textforecast/" + text.filename
    : "/textforecast/" + text.filename + "/noMap"

  const url = `${config.api.lunaUrl}/text/${
    text.filename
  }-${locale.toUpperCase()}.html`

  const { lastModified } = useOffshoreURLLastModified({ url })

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.background.default}`,
        padding: 2,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={9}
      >
        <Grid item xs={12} sm={5}>
          <Link
            to={linkTo}
            variant={"body1"}
            color="inherit"
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            <Typography>
              {intl.locale === "nb" ? text.title_no : text.title_en}
            </Typography>
          </Link>
        </Grid>
        {lastModified && (
          <Grid item xs={12} sm={7}>
            <Typography variant="body2" style={{ color: colorGray }}>
              {intl.formatMessage({ id: "updated" })}:{" "}
              {moment(lastModified)
                .tz(currentTimezone)
                .format("DD.MM.YYYY HH:mm zz")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
