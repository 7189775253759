import {
  convertToKnots,
  roundToZeroDecimals,
  TimezoneID,
} from "@luna/luna-core"
import {
  Chart,
  PlotWindbarbOptions,
  SeriesLineOptions,
  XAxisPlotLinesLabelOptions,
  XAxisPlotLinesOptions,
} from "highcharts"
import capitalize from "lodash/capitalize"
import cloneDeep from "lodash/cloneDeep"
import * as Moment from "moment"
import { extendMoment } from "moment-range"
import { IntlShape } from "react-intl"

const moment = extendMoment(Moment)

const lunaColors = {
  emc: "#0051F0",
  blue: "#2416C7",
  yellow: "#BD5B09",
  max_gust: "#696E83",
  max_gust_negative: "#B3B4BA",
  localCriteria: "rgba(0, 255, 240, 0.5)",
  globalCriteria: "rgba(255, 0, 31, 0.5)",
  hcCriteria: "rgba(182, 63, 255, 0.5)",
  almostBlack: "#323232",
  veryLight: "rgba(96, 98, 113, 0.2)",
  transparent: "rgba(255,255,255,0 )",
}

type ChartBasis = {
  forecast: XWWForecast
  criterias: Criteria[]
  intl: IntlShape
  currentTimezone: TimezoneID
  currentPlatform: string
}

export const calculateEXWWChartOptions = (
  chartBasis: ChartBasis
): Highcharts.Options => {
  const { forecast, intl, currentTimezone } = chartBasis
  return {
    credits: {
      enabled: false,
    },
    chart: {
      height: 800,
      marginBottom: 280,
      marginTop: 30,
      style: {
        fontFamily: `"Simplon BP Regular", "sans-serif"`,
        fontSize: "16px",
      },
      events: {
        render: function (event: Event) {
          /**
           * For every chart render/update we place a copy
           * of the chart in the window object so that it
           * can be accessed by some preview logic.
           */
          const copiedChart = cloneDeep(this) as Chart
          window.exwwChart = copiedChart
          if (typeof window.exwwChartChangeHandler === "function") {
            /**
             * This is to let EXWWPreviewPage.tsx await with a promise
             * until the Highcharts chart is actually updated.
             */
            window.exwwChartChangeHandler(copiedChart, event)
          }
        },
      },
    },
    tooltip: {
      shared: true,
      // style: {
      //   fontSize: "16px",
      // },
      //      headerFormat: `<span style="font-size: 16px"><strong>{point.key}</strong></span><br/>`,
    },
    title: {
      text: "",
    },
    legend: {
      itemStyle: {
        fontSize: "16px",
        fontWeight: "normal",
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
      },
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        hour: "%H",
        day: "%H",
      },
      tickPixelInterval: 50,
      labels: {
        autoRotation: undefined,
        style: {
          fontSize: "16px",
          color: lunaColors.almostBlack,
        },
        formatter: function () {
          const { isFirst } = this as any
          const tzName = forecast.forecast[0].valid
            .tz(currentTimezone)
            .format("zz")
          return isFirst
            ? `${tzName}:`
            : this.axis.defaultLabelFormatter.call(this)
        },
      },
      min: forecast.forecast[0].valid.valueOf(),
      plotLines: [
        {
          // Measurement/Forecast separator.
          color: "#E0E0E0",
          dashStyle: "Solid",
          value: forecast.meta.run.valueOf(),
          width: 2,
        },
        generatePlotlineLabel({
          forecast,
          labelOptions: {
            align: "left",
            x: -10,
            y: 90,
            text: `<div style="color: ${lunaColors.blue};">${intl.formatMessage(
              { id: "wave_direction" }
            )}</div>`,
          },
        }),
        generatePlotlineLabel({
          forecast,
          labelOptions: {
            align: "left",
            x: -10,
            y: 170,
            text: `<div>${intl.formatMessage({ id: "wind" })} 10m/10min</div>`,
          },
        }),
      ],
      plotBands: [
        ...plotDayBands(chartBasis),
        {
          color: "rgba(244, 246, 250, 0.5)",
          from: forecast.forecast[0].valid.valueOf(),
          to: forecast.meta.run.valueOf(),
          label: {
            text: intl.formatMessage({ id: "observation" }),
            align: "right",
            x: -10,
          },
        },
        {
          color: "rgba(244, 246, 250, 0)",
          from: forecast.meta.run.valueOf(),
          to: forecast.forecast[forecast.forecast.length - 1].valid.valueOf(),
          label: {
            text: intl.formatMessage({ id: "prediction" }),
            align: "left",
            x: 10,
          },
        },
      ],
    },
    yAxis: [
      {
        min: 4.0,
        max: 14.0,
        startOnTick: false,
        title: {
          text: "EMC [m]",
          style: {
            color: lunaColors.almostBlack,
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: lunaColors.almostBlack,
            fontSize: "16px",
          },
        },
      },
      {
        min: 10.0,
        max: 110.0,
        startOnTick: false,
        tickInterval: 10,
        title: {
          text:
            intl.formatMessage({ id: "max_gust_helideck" }) +
            " [" +
            intl.formatMessage({ id: "max_gust_helideck_unit" }) +
            "]",
          style: {
            color: lunaColors.almostBlack,
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: lunaColors.almostBlack,
            fontSize: "16px",
          },
        },
        opposite: true,
      },
    ],
    exporting: {
      buttons: {
        contextButton: { enabled: false },
      },
      sourceWidth: 1024,
      sourceHeight: 768,
    },
    series: [
      ...getEMCPlots(chartBasis),
      ...calculateCriteriaPlots(chartBasis),
      ...getWaveDirectionPlots(chartBasis),
      ...getWindPlots(chartBasis),
    ] as Highcharts.SeriesOptionsType[],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
          chartOptions: {
            chart: {
              marginBottom: 350,
            },
          },
        },
      ],
    },
  }
}

const generatePlotlineLabel = ({
  forecast,
  labelOptions,
}: {
  forecast: XWWForecast
  labelOptions: XAxisPlotLinesLabelOptions
}) => {
  return {
    color: "#E0E0E0",
    dashStyle: "Solid",
    value: forecast.forecast[1].valid.valueOf(),
    width: 0,
    label: {
      verticalAlign: "bottom",
      rotation: 0,
      text: `Some title`,
      ...labelOptions,
    },
  } as XAxisPlotLinesOptions
}

const calculateCriteriaPlots = ({
  criterias,
  forecast,
  currentPlatform,
  intl,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  const plots: Highcharts.SeriesLineOptions[] = []
  const localCriteria = criterias.find(
    ({ location, criteriaType }) =>
      location === currentPlatform && criteriaType === "Lokal"
  )
  if (localCriteria) {
    plots.push({
      type: "line",
      color: lunaColors.localCriteria,
      name: intl.formatMessage({ id: "local_wave_criteria" }),
      tooltip: {
        valueSuffix: " m",
      },
      marker: {
        enabled: false,
      },
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          valid.valueOf(),
          getCriteriaForDegree({
            degree: data.peak_wave_direction,
            criteria: localCriteria,
          }),
        ]
      }),
    })
  }
  const hc_criteria = criterias.find(
    ({ location, criteriaType }) =>
      location === currentPlatform && criteriaType === "HC-Utstyr"
  )
  if (hc_criteria) {
    plots.push({
      type: "line",
      color: lunaColors.hcCriteria,
      name: intl.formatMessage({ id: "hc_criteria" }),
      tooltip: {
        valueSuffix: " m",
      },
      marker: {
        enabled: false,
      },
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          valid.valueOf(),
          getCriteriaForDegree({
            degree: data.peak_wave_direction,
            criteria: hc_criteria,
          }),
        ]
      }),
    })
  }
  const globalCriteriaUnder24Hrs = criterias.find(
    ({ location, criteriaType }) =>
      location === currentPlatform && criteriaType === "Global <24t"
  )
  const globalCriteriaOver24Hrs = criterias.find(
    ({ location, criteriaType }) =>
      location === currentPlatform && criteriaType === "Global >24t"
  )
  if (globalCriteriaUnder24Hrs && globalCriteriaOver24Hrs) {
    let tmpForecast = forecast.meta.run.clone()
    tmpForecast.add(24, "hours")
    const first24Hours = tmpForecast
    plots.push({
      type: "line",
      color: lunaColors.globalCriteria,
      name: intl.formatMessage({ id: "global_wave_criteria" }),
      tooltip: {
        valueSuffix: " m",
      },
      marker: {
        enabled: false,
      },
      data: forecast.forecast.map(({ valid, data }) => {
        return [
          valid.valueOf(),
          getCriteriaForDegree({
            degree: data.peak_wave_direction,
            criteria: valid.isBefore(first24Hours)
              ? globalCriteriaUnder24Hrs
              : globalCriteriaOver24Hrs,
          }),
        ]
      }),
    })
  }
  return plots
}

//"Bølgeretning",
const getWaveDirectionPlots = ({
  forecast,
  intl,
}: ChartBasis): PlotWindbarbOptions[] => {
  const windbarbOptions = {
    type: "windbarb",
    yOffset: 110,
    name: intl.formatMessage({ id: "wave_direction" }),
    showInLegend: false,
    tooltip: {
      pointFormatter: function () {
        return ""
      },
    },
    dataGrouping: {
      enabled: true,
      groupPixelWidth: 30,
    },
    states: {
      inactive: {
        opacity: 1,
      },
    },
    color: lunaColors.blue,
    vectorLength: 20,
    lineWidth: 2,
    data: forecast.forecast.map(({ valid, data }) => [
      valid.valueOf(),
      1, // don't show wave strength in arrow.
      data.peak_wave_direction,
    ]),
  }
  return [
    // Display windbarb arrows.
    windbarbOptions,
    {
      // Second windbarb config just to get information in tooltip.
      ...windbarbOptions,
      dataGrouping: {
        enabled: false,
      },
      lineWidth: 0,
      tooltip: {
        pointFormatter: function () {
          /**
           * To be able to learn all the options for the windbarb point
           * I set a 'debugger' here and looked at the contents of the
           * 'this' variable.
           *
           * Relevant docs: https://api.highcharts.com/highcharts/tooltip.pointFormatter
           */
          const {
            color,
            series: { name },
            options: { direction },
          } = this as any
          return `
              <span style="color:${color}">\u25CF</span>
              ${name}: <b>${direction}°</b>
              <br/>`
        },
      },
    } as PlotWindbarbOptions,
  ]
}

const getEMCPlots = ({
  forecast,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  return [
    {
      type: "line",
      name: "EMC",
      color: lunaColors.emc,
      tooltip: {
        valueDecimals: 1,
        valueSuffix: " m",
      },
      marker: {
        enabled: false,
      },
      data: forecast.forecast.map(({ valid, data }) => {
        return [valid.valueOf(), data.sea_surface_wave_Ekofisk_maximum_crest]
      }),
      lineWidth: 5,
    },
  ]
}

const getWindPlots = ({
  forecast,
  intl,
}: ChartBasis): Array<PlotWindbarbOptions | SeriesLineOptions> => {
  const windbarbOptions = {
    type: "windbarb",
    yOffset: 190,
    name: intl.formatMessage({ id: "wind" }),
    showInLegend: false,
    tooltip: {
      pointFormatter: function () {
        return ""
      },
    },
    states: {
      inactive: {
        opacity: 1,
      },
    },
    color: lunaColors.almostBlack,
    vectorLength: 20,
    lineWidth: 1.5,
    dataGrouping: {
      enabled: true,
      groupPixelWidth: 40,
    },
    data: forecast.forecast.map(({ valid, data }) => [
      valid.valueOf(),
      data.wind_speed_10_meter,
      data.wind_from_direction,
    ]),
  }
  return [
    // Show responsive, datagrouped arrows
    windbarbOptions,
    {
      // Create version without datagroup to
      // enable datagroup.
      ...windbarbOptions,
      dataGrouping: {
        enabled: false,
      },
      lineWidth: 0,
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { value: windStrength, direction },
          } = this as any
          return `
            <span style="color:${color}">\u25CF</span>
            ${name}: <b>${direction}° / ${roundToZeroDecimals(
            convertToKnots({
              metersPerSecond: windStrength,
            })
          )} ${intl.formatMessage({ id: "wind_unit" })} </b>
            <br/>`
        },
      },
    } as PlotWindbarbOptions,
    {
      type: "line",
      name: intl.formatMessage({ id: "max_gust_helideck" }),
      yAxis: 1,
      color: lunaColors.max_gust,
      threshold: 60,
      negativeColor: lunaColors.max_gust_negative,
      lineWidth: 5,
      tooltip: {
        valueDecimals: 0,
        valueSuffix: " " + intl.formatMessage({ id: "wind_unit" }),
      },
      marker: {
        enabled: false,
      },
      data: forecast.forecast.map(({ valid, data }) => [
        valid.valueOf(),
        getMaxGust(data),
      ]),
    },
  ]
}

const plotDayBands = ({
  forecast,
  currentTimezone,
  intl,
}: ChartBasis): Highcharts.XAxisPlotBandsOptions[] => {
  const range = moment.range(
    forecast.forecast[0].valid.clone().tz(currentTimezone),
    forecast.forecast[forecast.forecast.length - 1].valid
      .clone()
      .tz(currentTimezone)
  )
  return Array.from(range.by("days")).map((day) => ({
    borderColor: lunaColors.veryLight,
    borderWidth: 2,
    color: lunaColors.transparent,
    from: day.startOf("day").valueOf(),
    to: day.endOf("day").valueOf(),
    label: {
      verticalAlign: "bottom",
      y: 60,
      text: day.locale(intl.locale).format("dddd Do MMM"),
      formatter: function () {
        const { text } = (this as any).options.label
        // Emphasize name of day, display rest in normal text
        return `<strong>${text
          .split(" ")
          .slice(0, 1)
          .map((str: string) => capitalize(str).slice(0, 3))
          .join()} </strong> ${text.split(" ").slice(1).join(" ")}`
      },
    },
  }))
}

/**
 * Purpose: We have criteria values given in increments of
 * five degrees. When we have a wave direction in degrees
 * we need to know the index in the criteria values table to
 * look.
 *
 * This function builds a lookup table once, where
 * the key (degree) points gives you the correct index in
 * the criteria values array to look.
 */
export const criteriaLookupTable = (() => {
  const increments = []
  for (let degree = 0; degree < 360; degree = degree + 5) {
    increments.push({ from: degree, until: degree + 4 })
  }
  const table: {
    [index: string]: number
  } = {}
  for (let degree = 0; degree < 360; degree++) {
    const indexPositionInCriteriaValues = increments.findIndex(
      ({ from, until }) => from <= degree && degree <= until
    )
    table[`${degree}`] = indexPositionInCriteriaValues
  }
  return table
})()

export const getCriteriaForDegree = ({
  degree,
  criteria,
}: {
  degree: number
  criteria: Criteria
}) => {
  if (degree === 360) {
    degree = 0
  }
  return criteria.criteriaByDegree[criteriaLookupTable[`${degree}`]]
}

export const getMaxGust = ({
  wind_speed_10_meter,
}: {
  wind_speed_10_meter: number
}) => {
  const maxGustHelideckFactor = 1.5961
  return (
    convertToKnots({ metersPerSecond: wind_speed_10_meter }) *
    maxGustHelideckFactor
  )
}
