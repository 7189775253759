import { MessageAlert } from "components/Message/MessageType"
import {
  loadJSONFromLocalStorage,
  saveInLocalStorage,
} from "./localStorageService"

export function updateLocalStorageMessages(messages: MessageAlert[]) {
  // list of existing messages
  const localStorageAlerts = loadJSONFromLocalStorage<string[]>({
    key: "LunaAlertMessages",
  })

  // list of updated messages from api
  const lunaAlertMessages: string[] = []
  messages && messages.map((msg) => lunaAlertMessages.push(msg.id))

  // for each message in localstorage
  // if a message is outdated, remove it's individual message item
  localStorageAlerts?.forEach((message) => {
    if (!lunaAlertMessages.includes(message)) {
      // if key has not been created, nothing happens
      window.localStorage.removeItem(message)
    }
  })

  saveInLocalStorage({ key: "LunaAlertMessages", data: lunaAlertMessages })
}
