import { MenuProps, Menu } from "@mui/material"

export const LunaMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    sx={{
      paper: {
        border: "1px solid #d3d4d5",
      },
    }}
    {...props}
  />
)
