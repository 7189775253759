import { Box, Grid, Paper, Typography } from "@mui/material"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import zipWith from "lodash/zipWith"
import { useMemo } from "react"
import { generateMapDetails } from "./AccumulatedRainMap.service"
import { getMapsFromData, useSpecificMaps } from "./AvailableMaps.service"

import { MapRenderImagePairs } from "./MapRenderer/MapRenderImagePairs"
import { RenderAvailableChildren } from "./MapRenderer/RenderAvailableChildren"
import { FormattedMessage, useIntl } from "react-intl"

const { yesterday, today } = generateMapDetails()

/**
 * This component shows two Weather images side by side. And
 * lets you compare them.
 */
export const AccumulatedRainMap = () => {
  const { data, isSuccess, isLoading } = useSpecificMaps([yesterday, today])

  const yesterdayImages = getMapsFromData(yesterday, data)
  const todayImages = getMapsFromData(today, data)
  const intl = useIntl()

  const mapTitle = intl.formatMessage({ id: "map_text_precipitation_12UTC" })

  const yesterday_prognosis_from_ECMWF = intl.formatMessage({
    id: "yesterday_prognosis_from_ECMWF",
  })
  const today_prognosis_from_ECMWF = intl.formatMessage({
    id: "today_prognosis_from_ECMWF",
  })
  const imagePairs: ImagePair[] = useMemo(() => {
    return zipWith(yesterdayImages, todayImages, (listItem1, listItem2) => {
      return {
        yesterday: listItem1?.url,
        today: listItem2?.url,
      }
    })
  }, [yesterdayImages, todayImages])

  return (
    <>
      <Box pb={2}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID="fullscreen-map"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RenderAvailableChildren
          images={imagePairs}
          isSuccess={isSuccess}
          isLoading={isLoading}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FullscreenDialog
                title={mapTitle}
                id="fullscreen-map"
                showChildrenAlsoWhenClosed
              >
                <MapRenderImagePairs imagePairs={imagePairs}>
                  {(selectedPair) => {
                    const { yesterday, today } = selectedPair
                    return (
                      <>
                        <div style={{ minWidth: 350 }}>
                          <Typography
                            variant="subtitle1"
                            style={{ paddingBottom: 10 }}
                          >
                            {yesterday_prognosis_from_ECMWF}
                          </Typography>
                          {yesterday && (
                            <img
                              alt={yesterday_prognosis_from_ECMWF}
                              src={yesterday}
                            />
                          )}
                        </div>
                        <div style={{ minWidth: 350 }}>
                          <Typography
                            variant="subtitle1"
                            style={{ paddingBottom: 10 }}
                          >
                            {today_prognosis_from_ECMWF}
                          </Typography>
                          {today && (
                            <img alt={today_prognosis_from_ECMWF} src={today} />
                          )}
                        </div>
                      </>
                    )
                  }}
                </MapRenderImagePairs>
              </FullscreenDialog>
            </Grid>

            <Grid item xs={4}>
              <Paper>
                <Typography variant="subtitle1" style={{ padding: 10 }}>
                  <FormattedMessage id="map_explanation" />:
                </Typography>

                <Typography variant="body1" style={{ padding: 10 }}>
                  <FormattedMessage id="map_acc_precipitation_exp_text" />.
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontWeight: "bold", padding: 10 }}
                >
                  <FormattedMessage id="map_acc_precip_map" />:
                </Typography>
                <Typography variant="body1" style={{ padding: 10 }}>
                  <FormattedMessage id="acc_precip_text" />.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </RenderAvailableChildren>
      </Box>
    </>
  )
}
