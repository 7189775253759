import { DateTime } from "luxon"
import { useMemo } from "react"
import { AvailableMapFileID, MapData } from "./AvailableMaps.service"

export type Millimeter = 5.0 | 10 | 20 | 40 | 60 | 80 | 100

const servers = { ECMWF: "ec", AROME: "meps" } as const
const timeIncrements = { ECMWF: { hour: 12 }, AROME: { hour: 3 } }
const modelStartValues = {
  ECMWF: { hour: 0, minute: 0, second: 0 },
  AROME: { hour: 0, minute: 0, second: 0 },
}

const mapVariations = {
  standard: "norway",
  // Customer Glitre requires a different set of zoomed in images.
  glitre: "eastnorway",
} as const

const getAsString = (
  millimeter: Millimeter
): "5.0" | "10" | "20" | "40" | "60" | "80" | "100" => {
  return millimeter === 5 ? "5.0" : `${millimeter}`
}

export const useMapDetails = ({
  millimeters,
  provider,
  period,
  type,
}: {
  millimeters: Millimeter
  provider: "ECMWF" | "AROME"
  period: "24t" | "48t"
  type: "standard" | "glitre"
}) => {
  return useMemo(() => {
    const mapType: AvailableMapFileID = (() => {
      const server = servers[provider]
      if (server === "ec") {
        const ecMap: AvailableMapFileID = `${server}_prec_prb${getAsString(
          millimeters
        )}`
        return ecMap
      }
      const arome_period = period === "48t" ? "48" : "24"
      const mapVariation = mapVariations[type]
      const mepsMap: AvailableMapFileID = `${server}_prec_prb${getAsString(
        millimeters
      )}_${arome_period}_${mapVariation}`
      return mepsMap
    })()

    const timeIncrement = timeIncrements[provider]
    const modelStart = modelStartValues[provider]

    return { mapType, modelStart, timeIncrement }
  }, [provider, period, millimeters, type])
}

// TL;DR: this parses startDate from the URL and discusses alternatives
//
// in order to get the startDateTime we simply parse the date from the URL.
// the alternative would be to complicate the useMarks calculation
// for only this case( chanceOfRain weathermap ), by adding the concept
// of "lag": how much in the future the model is running for.
// This would be needed because the
// mtime for the model predicting the 9th of may is 8th of may 08:07
// for 24t period and the same mtime for predicting 10th of may on 48t period.
// we could then have a modelDetails-object that could look like this:
// modelDetails: {
//  start:    current value of modelStart, ie DateLikeObject,
//  lag?:     DateLikeObject describing how we should add to
//            dateTime in getDifference,
//  fallback: DateLikeObject explicit fallback that useMarks uses internally
//            instead of short-circuiting in the parameters with firstElement
// }
// and have every useMapDetails generate it and pass it to useMarks
// instead of modelStart, which would be
// flexible and extensible, but perhaps a bit hard to grok,
// or we could parse the url of the firstElement
export const findStartTime = ({
  firstImage,
  modelStart,
  timeIncrement,
}: {
  firstImage: MapData
  modelStart: { hour: number }
  timeIncrement: { hour: number }
}) => {
  const arrayOfURLParts = firstImage?.url.split("_")
  const relevantString =
    arrayOfURLParts && arrayOfURLParts[arrayOfURLParts.length - 1]
  const startDateTime =
    firstImage && firstImage.url && relevantString
      ? DateTime.fromISO(relevantString.replace(".png", ""))
      : DateTime.utc().set({
          hour: modelStart.hour + timeIncrement.hour,
          minute: 0,
          second: 0,
        })
  return startDateTime
}
