import React, { FC } from "react"
import { Column, ColumnGroup } from "./OffshoreTable"
import { FormattedMessage } from "react-intl"
import {
  TableCell,
  Box,
  InputProps,
  FormControl,
  InputLabel,
  Input,
  TableRow,
} from "@mui/material"
import { ActiveCriteria, SetActiveCriteria } from "./OffshoreTable.hook"

export const MetCriteriaThresholdRows: FC<{
  columnGroups: ColumnGroup[]
  activeCriteria: ActiveCriteria
  setActiveCriteria: SetActiveCriteria
}> = ({ columnGroups, activeCriteria, setActiveCriteria }) => {
  return (
    <>
      <YellowRow
        columnGroups={columnGroups}
        activeCriteria={activeCriteria}
        setActiveCriteria={setActiveCriteria}
      />
      <RedRow
        columnGroups={columnGroups}
        activeCriteria={activeCriteria}
        setActiveCriteria={setActiveCriteria}
      />
    </>
  )
}

const YellowRow: FC<{
  columnGroups: ColumnGroup[]
  activeCriteria: ActiveCriteria
  setActiveCriteria: SetActiveCriteria
}> = ({ activeCriteria, columnGroups, setActiveCriteria }) => {
  const cellsInRow = columnGroups.flatMap((columnGroup, columnGroupIndex) => {
    const { columns } = columnGroup
    const cellsInColumnGroup = columns.map((column, columnIndex) => {
      const isFirstCellInRow = columnIndex === 0 && columnGroupIndex === 0
      if (isFirstCellInRow) {
        return (
          <TableCell
            sx={(theme) => ({
              position: "sticky",
              left: 0,
              zIndex: 2,
              backgroundColor: "#ffffff",
              borderRight: `1px solid ${theme.palette.common.black}`,
            })}
            key={column.id}
          >
            <Box
              whiteSpace="nowrap"
              fontWeight={500}
              display="flex"
              justifyContent="space-between"
            >
              <Box component="span" fontWeight={700} paddingRight={1}>
                <FormattedMessage id="criteria" />
              </Box>
              <Box component="span">
                <FormattedMessage id="yellow" />:
              </Box>
            </Box>
          </TableCell>
        )
      }
      const isLastColumnInColumnGroup = columnIndex === columns.length - 1
      const isLastColumnInRow =
        isLastColumnInColumnGroup &&
        columnGroupIndex === columnGroups.length - 1
      const isLastColumnInColumnGroupAndIsNotLastColumnInRow =
        isLastColumnInColumnGroup && !isLastColumnInRow

      if (column.deactivateTresholdInput) {
        return (
          <EmptyTableCell
            key={column.id}
            column={column}
            hasBottomBorder={false}
            hasRightBorder={isLastColumnInColumnGroupAndIsNotLastColumnInRow}
          />
        )
      }

      return (
        <EmptyTableCell
          key={column.id}
          column={column}
          hasBottomBorder={false}
          hasRightBorder={isLastColumnInColumnGroupAndIsNotLastColumnInRow}
        >
          <CriteriaInput
            id={`yellow-input-${column.id}`}
            label={column.label}
            inputProps={{
              margin: "dense",
              defaultValue: activeCriteria["yellow"][column.id],
              onBlur: (event) => {
                const value = event.currentTarget.value.replace(/,/gi, ".")

                setActiveCriteria(({ yellow, red }) => ({
                  red,
                  yellow: {
                    ...yellow,
                    [column.id]: value,
                  },
                }))
              },
            }}
          />
        </EmptyTableCell>
      )
    })
    return cellsInColumnGroup
  })

  return <TableRow>{cellsInRow}</TableRow>
}

const RedRow: FC<{
  columnGroups: ColumnGroup[]
  activeCriteria: ActiveCriteria
  setActiveCriteria: SetActiveCriteria
}> = ({ activeCriteria, columnGroups, setActiveCriteria }) => {
  const cellsInRow = columnGroups.flatMap((columnGroup, columnGroupIndex) => {
    const { columns } = columnGroup
    const cellsInColumnGroup = columns.map((column, columnIndex) => {
      const isFirstColumn = columnIndex === 0 && columnGroupIndex === 0
      if (isFirstColumn) {
        return (
          <TableCell
            key={column.id}
            sx={(theme) => ({
              borderRight: `1px solid ${theme.palette.common.black}`,
              borderBottom: `1px solid ${theme.palette.common.black}`,
              position: "sticky",
              left: 0,
              zIndex: 2,
              backgroundColor: "#ffffff",
            })}
          >
            <Box whiteSpace="nowrap" fontWeight={500}>
              <Box component="span" style={{ float: "right" }}>
                <FormattedMessage id="red" />:
              </Box>
            </Box>
          </TableCell>
        )
      }
      const isLastInColumn = columnIndex === columns.length - 1
      const isVeryLastInRow =
        isLastInColumn && columnGroupIndex === columnGroups.length - 1
      const isLastColumnInGroup = isLastInColumn && !isVeryLastInRow

      if (column.deactivateTresholdInput) {
        return (
          <EmptyTableCell
            key={column.id}
            hasRightBorder={isLastColumnInGroup}
            hasBottomBorder
            column={column}
          />
        )
      }

      return (
        <EmptyTableCell
          key={column.id}
          hasRightBorder={isLastColumnInGroup}
          hasBottomBorder
          column={column}
        >
          <CriteriaInput
            id={`red-input-${column.id}`}
            label={column.label}
            inputProps={{
              margin: "dense",
              defaultValue: activeCriteria["red"][column.id],
              onBlur: (event) => {
                const value = event.currentTarget.value.replace(/,/gi, ".")
                setActiveCriteria(({ yellow, red }) => ({
                  yellow,
                  red: {
                    ...red,
                    [column.id]: value,
                  },
                }))
              },
            }}
          />
        </EmptyTableCell>
      )
    })
    return cellsInColumnGroup
  })

  return <TableRow>{cellsInRow}</TableRow>
}

const EmptyTableCell: FC<{
  hasRightBorder: boolean
  hasBottomBorder: boolean
  column: Column
}> = ({ children, hasBottomBorder, column, hasRightBorder }) => {
  return (
    <TableCell
      align={column.align}
      component={"td" as any}
      sx={(theme) => ({
        "&:first-of-type": {
          whiteSpace: "nowrap",
        },
        transition: "background-color 0.5s ease-out",
        ...(hasBottomBorder && {
          borderBottom: `1px solid ${theme.palette.common.black}`,
        }),
        ...(hasRightBorder && {
          borderRight: `1px solid ${theme.palette.common.black}`,
        }),
      })}
    >
      {children}
    </TableCell>
  )
}

const CriteriaInput: React.FunctionComponent<{
  id: string
  label: string
  inputProps: InputProps
}> = ({ id, label, inputProps }) => {
  const labelId = `${id}-helper-text`
  return (
    <FormControl
      variant="filled"
      sx={{
        "& label + .MuiInput-formControl": {
          marginTop: 0,
        },
        "& .MuiInput-input": {
          textAlign: "center",
        },
      }}
    >
      <InputLabel id={labelId} htmlFor={id} style={{ visibility: "hidden" }}>
        {label}
      </InputLabel>
      <Input id={id} {...inputProps} />
    </FormControl>
  )
}
