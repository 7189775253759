import { Box, Container } from "@mui/material"
import { MessageAlerts, useMessages } from "components/Message/MessageAlerts"
import { OceanCurrent } from "components/OceanCurrent/OceanCurrent"
import { useUserProducts } from "components/UserProductsProvider"

import { useParams } from "react-router-dom"

interface QueryParams {
  posID: string
}

export const OceanCurrentPage: React.FC<{ showMsg?: boolean }> = ({
  showMsg = true,
}) => {
  const { messages } = useMessages({ autoRefresh: true })

  const { posID } = useParams<QueryParams>()

  const userProducts = useUserProducts()
  const { currents = [] } = userProducts

  let offshore_current = undefined
  if (currents) {
    offshore_current = currents.find((c) => c.interpol_pos_id === posID)
  }
  return (
    <Container>
      {showMsg && <MessageAlerts messages={messages} />}

      <div>
        <Box padding={4} sx={{ minHeight: "400px" }}>
          <OceanCurrent current={offshore_current} />
        </Box>
      </div>
    </Container>
  )
}
