import { loadJSONFromLocalStorage, saveInLocalStorage } from "@luna/luna-core"
import { defaultSeries } from "./graphStaticValues"
import {
  GraphDataFormat,
  OffshoreDataList,
  OffshoreSeriesKeys,
  OverrideVisibilityOptions,
  SeriesData,
  SeriesVisibility,
} from "../../../../@types/OffshoreGrapOption"

import { OFFSHORE_DATA_ELEMENTS } from "./graphStaticValues"
import { ObservedData } from "../../../../@types/ObservationTypes"
import moment from "moment"
import { getSeriesVisibility } from "./offshoreGraphOptions"

export const convertToKnots = (metersPerSecond: number): number => {
  // one knot is 1852m/h.
  const knotConversionFactor = 3600 / 1852
  return metersPerSecond * knotConversionFactor
}
export function defaultLineFormat(format: GraphDataFormat): {
  x: number
  y: number | undefined | null
  direction?: number | undefined
} {
  const { valid, data, windUnitIsKnots, directionId, valueId, qualitycode } =
    format

  let value = undefined
  let dir = undefined
  if (valueId) {
    // Forecast does not have qualitycode, only showing good observations
    if (qualitycode === undefined || qualitycode === 0) {
      if (
        valueId.startsWith("wind") ||
        valueId.startsWith("gust") ||
        valueId.startsWith("observed_max_wind") ||
        valueId.startsWith("observed_wind")
      ) {
        value = windUnitIsKnots ? convertToKnots(data[valueId]) : data[valueId]
      } else {
        value = data[valueId] ? data[valueId] : null
      }
    }
  }

  if (directionId) {
    if (qualitycode === undefined || qualitycode === 0) {
      dir = data[directionId]
    }
  }

  if (directionId)
    return {
      x: valid.valueOf(),
      y: value,
      direction: dir,
    }
  return {
    x: valid.valueOf(),
    y: value,
  }
}

export function defaultWindbarbFormat(format: GraphDataFormat) {
  const { valid, data, directionId, valueId, qualitycode } = format
  let value = undefined
  let dir = undefined
  if (valueId) {
    // Forecast does not have qualitycode, only showing good observations
    if (qualitycode === undefined || qualitycode === 0) {
      value = data[valueId]
    }
  }
  if (directionId) {
    if (qualitycode === undefined || qualitycode === 0) {
      dir = data[directionId]
    }
  }

  if (!dir) {
    return {
      x: valid.valueOf(),
    }
  }
  return {
    x: valid.valueOf(),
    value: value ? value : 1,
    direction: dir,
  }
}

export const initialiseSeriesVisibility = (
  overrideVisibleSeries?: OverrideVisibilityOptions
) => {
  return function getSeriesVisibility(series: OFFSHORE_DATA_ELEMENTS): boolean {
    if (typeof overrideVisibleSeries === "boolean") {
      return defaultSeries[series]
    } else if (overrideVisibleSeries) {
      return !!overrideVisibleSeries[series]
    }
    const seriesFromStorage = getCompleteSeriesVisibility()
    return seriesFromStorage[series]
  }
}

export const saveSeriesVisibility = (
  series: OFFSHORE_DATA_ELEMENTS,
  hidden: boolean
) => {
  const seriesFromStorage = getCompleteSeriesVisibility()
  seriesFromStorage[series] = !hidden
  saveInLocalStorage<SeriesVisibility>({
    key: "LunaOffshoreGraphSeriesVisibility",
    data: seriesFromStorage,
  })
}

export const getCompleteSeriesVisibility = () => {
  return (
    loadJSONFromLocalStorage<SeriesVisibility>({
      key: "LunaOffshoreGraphSeriesVisibility",
      returnIfNull: { ...defaultSeries },
    }) || defaultSeries
  )
}

export function formatObservedData(
  obs?: ObservedData | undefined
): OffshoreDataList[] {
  if (!obs || !obs.tseries || obs.tseries.length === 0) return []

  const formattedData: OffshoreDataList[] = []
  const observedDataMap = new Map<string, OffshoreDataList>()

  for (const series of obs.tseries) {
    for (const observation of series.observations) {
      const { body, time } = observation
      if (!body || body.qualitycode !== "0" || !time) continue

      const isValidValue = isValidValueForUnit(
        body.value,
        series.header.extra.element.unit
      )
      if (!isValidValue) continue

      const observationTime = moment(time)
      const key = observationTime.toISOString()

      const existingData = observedDataMap.get(key)
      if (existingData) {
        const dataKey = series.header.extra.element.id as OffshoreSeriesKeys
        existingData.data[dataKey] = parseFloat(body.value)
      } else {
        const newData: OffshoreDataList = {
          valid: observationTime,
          qualitycode: parseInt(body.qualitycode),
          data: {
            [series.header.extra.element.id as OffshoreSeriesKeys]: parseFloat(
              body.value
            ),
          } as SeriesData,
        }
        formattedData.push(newData)
        observedDataMap.set(key, newData)
      }
    }
  }

  return formattedData
}

function isValidValueForUnit(value: string, unit: string): boolean {
  if (unit === "meters" || unit === "seconds" || unit === "m/s") {
    const floatValue = parseFloat(value)
    return floatValue >= 0
  } else if (unit === "degC") {
    const floatValue = parseFloat(value)
    return floatValue >= -273 && floatValue <= 100
  }
  return true
}

export function getObservedDataVisibillity(): boolean {
  let isVisisble = false
  Object.values(OFFSHORE_DATA_ELEMENTS).forEach((elem) => {
    if (elem.startsWith("observed"))
      if (getSeriesVisibility(elem)) {
        isVisisble = false
      }
  })
  return isVisisble
}

export function getWindDirectionVisibillity(): boolean {
  return (
    getSeriesVisibility(OFFSHORE_DATA_ELEMENTS.wind_from_direction) ||
    getSeriesVisibility(OFFSHORE_DATA_ELEMENTS.observed_wind_from_direction)
  )
}

export function getWaveDirectionVisibillity(): boolean {
  return (
    getSeriesVisibility(OFFSHORE_DATA_ELEMENTS.sea_surface_wave_to_direction) ||
    getSeriesVisibility(
      OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_from_direction
    )
  )
}

export function getSwellDirectionVisibillity(): boolean {
  return (
    getSeriesVisibility(
      OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_to_direction
    ) ||
    getSeriesVisibility(
      OFFSHORE_DATA_ELEMENTS.observed_sea_surface_swell_wave_to_direction
    )
  )
}

export const xOffsetPlotLine = -110
export const yOffsetWindPlotLine = 75
export const barbedOffset = 75
