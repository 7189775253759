import { useTimezone, useWindUnit } from "@luna/luna-core"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts/highstock"
import BrokenAxis from "highcharts/modules/broken-axis"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting"
import addWindbarb from "highcharts/modules/windbarb"
import Moment from "moment"
import MomentTimeZone from "moment-timezone"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import {
  calculateChartOptions,
  OverrideVisibilityOptions,
} from "./OceanCurrent.dataService"
import merge from "lodash/merge"
import { highchartsLocalizations } from "./OceanCurrent.localization"

// Highcharts timezones requires Moment.js to be globally available.
window.moment = Moment
MomentTimeZone()
BrokenAxis(Highcharts)

HighchartsExporting(Highcharts)
HighchartsOfflineExporting(Highcharts)
addWindbarb(Highcharts)

interface State {
  keyCounter: number
  chartOptions?: Highcharts.Options
}

interface Props {
  apiData: OffshoreForecast

  // Specify which series that should be available.
  overrideVisibleSeries?: OverrideVisibilityOptions
  // Optionally override the generated chart options
  overrideOptions?: Highcharts.Options
}

/**
 * Using forwardRef here so that consumers, can get hold of the Highcharts instance.
 */
const OceanCurrentHighcharts = React.forwardRef<any, Props>(
  (props, chartRef) => {
    const [data, setData] = useState<State>({ keyCounter: 0 })
    const { apiData: forecast, overrideVisibleSeries, overrideOptions } = props

    const intl = useIntl()
    const { currentTimezone } = useTimezone()
    const { windUnit } = useWindUnit()

    useEffect(() => {
      Highcharts.setOptions({
        lang: {
          ...highchartsLocalizations.get(intl.locale),
          // Override Norwegian ',' decimal point to be '.'.
          decimalPoint: ".",
        },
        time: {
          timezone: currentTimezone,
        },
      })
      setData((data) => ({
        ...data,
        forecast,
        keyCounter: data.keyCounter + 1,
        chartOptions: merge(
          calculateChartOptions({
            overrideVisibleSeries,
            forecast,
            intl,
            currentTimezone,
            windUnit,
          }),
          overrideOptions || {}
        ),
      }))
      /**
       * Update chart options based on intl/location change. This might not be optimal - but makes
       * it easier to handle updates in graph.
       */
    }, [
      intl,
      currentTimezone,
      forecast,
      overrideVisibleSeries,
      overrideOptions,
      windUnit,
    ])

    const { chartOptions, keyCounter } = data

    if (!chartOptions) {
      return null
    }

    return (
      <HighchartsReact
        key={keyCounter}
        ref={chartRef}
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={chartOptions}
      />
    )
  }
)

export default OceanCurrentHighcharts
