import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import React from "react"
import WbSunnyIcon from "@mui/icons-material/WbSunny"
import { OFFSHORE_DATA_ELEMENTS } from "./OffshoreGraphOptions/graphStaticValues"
import { orange } from "@mui/material/colors"
import { saveSeriesVisibility } from "./OffshoreGraphOptions/graphOptionsUtils"
import { OffshoreSeriesKeys } from "../../../@types/OffshoreGrapOption"

interface Props {
  visible: boolean
  name: string
  parameterId: string
  color: string
  handleLegendSelect: (selected: boolean, parameterId: string) => void
}

export const OffshoreGraphLegend: React.FC<Props> = ({
  visible,
  name,
  parameterId,
  color,
  handleLegendSelect,
}) => {
  return (
    <FormControlLabel
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
        },
      }}
      control={
        parameterId === OFFSHORE_DATA_ELEMENTS.weather_symbol ? (
          <Checkbox
            sx={{
              padding: "4px 6px 6px 10px ",
              "& .MuiSvgIcon-root": {
                width: 26,
                height: 26,
                color: orange[500],
                opacity: visible ? 1 : 0.2,
              },
              "& .MuiCheckbox-colorPrimary": {},
            }}
            checkedIcon={<WbSunnyIcon />}
            icon={<WbSunnyIcon />}
          />
        ) : (
          <Checkbox
            sx={{
              padding: "4px 6px 6px 10px ",
              "& .MuiSvgIcon-root": {
                width: 26,
                height: 26,
                color: color,
              },
              "& .MuiCheckbox-colorPrimary": {},
              opacity: visible ? 1 : 0.7,
            }}
            checked={visible}
          />
        )
      }
      label={
        <Typography
          sx={{
            opacity: visible ? 1 : 0.7,
          }}
        >
          {name}
        </Typography>
      }
      onChange={() => {
        handleLegendSelect(!visible, parameterId)
        saveSeriesVisibility(parameterId as OffshoreSeriesKeys, visible)
      }}
    />
  )
}
