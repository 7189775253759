import { Typography } from "@mui/material"
import { DateTime, DurationLikeObject } from "luxon"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { Filterer } from "services/filterer"
import { MapData } from "../AvailableMaps.service"

export type Mark = {
  value: number
  label: string | JSX.Element
}

// Placeholder: For intervals that vary wildly we can implement a custom function.
type CustomIncrementFunc = (notImplementedYet: unknown) => void

type MarksInput = DateInput | ParamsInput

type DateInput = {
  type: "days-and-hours" | "hours" | "index"
  totalSteps: number
  timeIncrement: DurationLikeObject | CustomIncrementFunc
  // Default starts at 0
  stepStart?: number
  // Default starts at today
  startDateTime: DateTime | MapData
  // Optionally override language of marks
  locale?: string
  // Optionally filter the amount of generated marks.
  filterBy?: (filterer: Filterer<Mark>) => Mark[]
  modelStart?: DurationLikeObject
}

type ParamsInput = {
  type: "params"
  steps: Array<{ params: RadarOptions; uri: string }>
  // Optionally override language of marks
  locale?: string
  // Optionally filter the amount of generated marks.
  filterBy?: (filterer: Filterer<Mark>) => Mark[]
}

const isStartMapData = (start: DateTime | MapData): start is MapData => {
  return (start as MapData).mtime !== undefined
}

const isTimeIncrementCustomFunc = (
  timeIncrement: DurationLikeObject | CustomIncrementFunc
): timeIncrement is CustomIncrementFunc => {
  return typeof timeIncrement === "function"
}

const boom = (msg: string) => {
  throw new Error(msg)
}

const generateDifference = ({
  start,
  date,
  timeIncrement,
}: {
  start: DurationLikeObject
  date: DurationLikeObject
  timeIncrement: DurationLikeObject | CustomIncrementFunc
}): DurationLikeObject => {
  if (isTimeIncrementCustomFunc(timeIncrement)) {
    boom("generateDifference doest support customIncrementFunc yet")
    return {} as DurationLikeObject
  }
  const keys = Object.keys(start) as [keyof DurationLikeObject]
  const returnable: DurationLikeObject = {}

  keys.forEach((key) => {
    returnable[key] =
      (date[key] || 0) - ((timeIncrement[key] || 0) + (start[key] || 0))
  })
  return returnable
}

const isParamsInput = (elem: MarksInput): elem is ParamsInput =>
  elem.type === "params"

// Separated from useMarks function to make it easier to test.
export const generateMarks = (input: MarksInput): Mark[] => {
  const marks: Mark[] = []
  if (isParamsInput(input)) {
    const { steps } = input
    steps.forEach((elem, index) => {
      if (!elem || !elem.params || !elem.params.time) {
        return
      }
      const {
        params: { time },
      } = elem

      marks.push({
        value: index,
        label: (
          <Typography align="center" variant="caption" display="block">
            <span style={{ whiteSpace: "nowrap" }}>
              {time.toFormat("d.MMM").toLowerCase()}
            </span>
            <br />
            <span style={{ whiteSpace: "nowrap" }}>
              {time.toFormat("kl HH:mm")}
            </span>
          </Typography>
        ),
      })
    })
    return marks
  }

  const {
    type,
    totalSteps,
    timeIncrement,
    startDateTime: unSureIfDateTime,
    stepStart = 0,
    locale = "nb",
    modelStart = {} as DurationLikeObject,
  } = input
  let startDateTime = unSureIfDateTime
  if (isStartMapData(unSureIfDateTime)) {
    startDateTime = DateTime.fromISO(unSureIfDateTime.mtime, { zone: "utc" })
    const difference = generateDifference({
      start: modelStart,
      date: startDateTime,
      timeIncrement,
    })

    if (Object.keys(difference).length > 0) {
      startDateTime = startDateTime.minus(difference)
    }
  } else {
    startDateTime = unSureIfDateTime
  }
  // Decrementing initial values to simplify the while loop a little bit.
  let counter = stepStart - 1
  let dateCounter =
    typeof timeIncrement !== "function"
      ? startDateTime.setLocale(locale).minus(timeIncrement)
      : boom("CustomIncrementFunc not implemented")
  while (counter < totalSteps - 1) {
    counter = counter + 1
    dateCounter =
      typeof timeIncrement !== "function"
        ? dateCounter.plus(timeIncrement)
        : boom("CustomIncrementFunc not implemented")
    if (type === "days-and-hours") {
      marks.push({
        value: counter,
        label: (
          <Typography align="center" variant="caption" display="block">
            <span style={{ whiteSpace: "nowrap" }}>
              {dateCounter.toFormat("d.MMM").toLowerCase()}
            </span>
            <br />
            <span style={{ whiteSpace: "nowrap" }}>
              {dateCounter.toFormat("kl HH")}
            </span>
          </Typography>
        ),
      })
    }
    if (type === "hours") {
      throw new Error("Not implemented because a map has not needed it yet.")
    }
    if (type === "index") {
      marks.push({
        value: counter,
        label: (
          <Typography align="center" variant="caption" display="block">
            <span style={{ whiteSpace: "nowrap" }}>{counter + 1}</span>
            <br />
          </Typography>
        ),
      })
    }
  }
  return marks
}

export const useMarks = (input: MarksInput): { marks: Mark[] } => {
  const { locale } = useIntl()
  const { filterBy } = input
  return useMemo(() => {
    // If any input fields are 'undefined' we just return an empty list,
    // this makes it possible to call useMarks without all parameters
    // being ready just yet.
    if (Object.values(input).some((entry) => entry === undefined)) {
      return { marks: [] }
    }
    const marks = generateMarks({ locale, ...input })
    if (filterBy) {
      return { marks: filterBy(new Filterer(marks)) }
    }
    return { marks }
  }, [filterBy, input, locale])
}
