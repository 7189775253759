import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography as T,
} from "@mui/material"
import React, { Fragment } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { ReactComponent as MailIcon } from "../images/mailIcon.svg"
import { ReactComponent as MetLogo } from "../images/metLogo.svg"
import { ReactComponent as PhoneIcon } from "../images/phoneIcon.svg"

const LunaFooter = styled("footer")(({ theme }) => ({
  color: "#fff",
  backgroundColor: theme.palette.primary.dark,
  padding: theme.spacing(6),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  display: "flex",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(10),
  },
  ".met-logo": {
    width: "150px",
    [theme.breakpoints.up("md")]: {
      width: "300px",
      marginRight: theme.spacing(4),
    },
  },
  ".info-icons": {
    "& svg, & path": { fill: "#fff" },
  },
  "& a": {
    textDecoration: "underline",
    color: "white",
  },
}))

type ContactSection = {
  title: JSX.Element | string
  points: {
    email?: string
    phone?: string
  }[]
}

type Props = {
  sections: ContactSection[]
}

export const FooterBase = ({ sections }: Props) => {
  const intl = useIntl()
  return (
    <>
      <div id="right-above-footer"></div>
      <LunaFooter>
        <Container>
          <T variant="body1" component="div">
            <Grid container>
              <Grid item xs={12} sm={5}>
                <MetLogo className="met-logo" />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={6}>
                    <T
                      variant="h3"
                      component="h1"
                      style={{ marginTop: "18px" }}
                    >
                      <FormattedMessage id={"contact"} />
                    </T>
                    <List>
                      {sections.map((section, index) => {
                        return (
                          <Fragment key={index}>
                            <ListItem disableGutters={true}>
                              <Box fontWeight="bold">{section.title}</Box>
                            </ListItem>
                            {section.points.map((point, index) => {
                              return (
                                <ListItem key={index} disableGutters={true}>
                                  <Box marginRight={2} className="info-icons">
                                    {point.email && <MailIcon />}
                                    {point.phone && <PhoneIcon />}
                                  </Box>
                                  <ListItemText>
                                    {point.email && (
                                      <Link href={`mailto:${point.email}`}>
                                        {point.email}
                                      </Link>
                                    )}
                                    {point.phone && (
                                      <Link href={`tel:${point.phone}`}>
                                        {point.phone}
                                      </Link>
                                    )}
                                  </ListItemText>
                                </ListItem>
                              )
                            })}
                          </Fragment>
                        )
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box style={{ marginTop: "20px" }}>
                      <Link href={intl.formatMessage({ id: "privacy_link" })}>
                        <FormattedMessage id="privacy" />
                      </Link>
                    </Box>
                    <Box style={{ marginTop: "20px" }}>
                      <Link
                        href="https://uustatus.no/nb/erklaringer/publisert/0d05228a-03b7-40fb-84d4-d81555dd54d6"
                        target="_blank"
                      >
                        <FormattedMessage id="declaration_of_availability" />
                      </Link>
                    </Box>
                    <Box style={{ marginTop: "20px" }}>
                      <Link href="mailto:luna@met.no">
                        <FormattedMessage id="ask_about_universal_design" />
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </T>
        </Container>
      </LunaFooter>
    </>
  )
}
