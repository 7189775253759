import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { config } from "../config"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import React from "react"
import { FormattedMessage } from "react-intl"
import { useFileDownloader } from "../services/dataFetcher"

interface Props {
  id: number
}

export const LongTimeForecastDropDown: React.FC<Props> = ({ id }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let menuButtonIcon = <ExpandMoreIcon />

  const links = {
    eps_wind_direction: "/eps/ecEps_" + id + "_61.png",
    eps_wind_speed: "/eps/ecEps_" + id + "_81.png",
    eps_significant_wave_height: "/eps/waveEps_" + id + "_700.png",
    eps_mean_period_primary_swell: "/eps/waveEps_" + id + "_730.png",
    eps_mean_wave_direction: "/eps/waveEps_" + id + "_732.png",
  }

  const { downloadFile } = useFileDownloader()

  return (
    <>
      <Button
        aria-controls="epsPlott-menu"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        onClick={handleClick}
        endIcon={menuButtonIcon}
      >
        <FormattedMessage id="epsPlottMenu" />
      </Button>
      <Menu
        id="epsPlott-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(links).map(([id, url]) => (
          <MenuItem key={id} onClick={handleClose}>
            <a
              href={`${config.api.lunaUrl}${url}`}
              onClick={(e) => {
                // Cancel regular href behavior, before triggering download.
                e.preventDefault()
                downloadFile({ url, filename: url.replace("/eps/", "") })
              }}
            >
              <FormattedMessage id={id} />
            </a>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
