import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import HcMore from "highcharts/highcharts-more"
import { useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import { getCurrentRoseOptions } from "./currentRoseOptions"
import { drawArrow } from "./drawArrow"
import { drawCardinalDirections } from "./drawCardinalDirections"

HcMore(Highcharts)

const getDistribution = (data: CurrentData) => {
  if (!data) return []
  const distribution = [
    data?.current_direction_probability346to15,
    data?.current_direction_probability16to45,
    data?.current_direction_probability46to75,
    data?.current_direction_probability76to105,
    data?.current_direction_probability106to135,
    data?.current_direction_probability136to165,
    data?.current_direction_probability166to195,
    data?.current_direction_probability196to225,
    data?.current_direction_probability226to255,
    data?.current_direction_probability256to285,
    data?.current_direction_probability286to315,
    data?.current_direction_probability316to345,
  ]
  return distribution
}

export type CurrentData = {
  current_speed: number
  current_speed_mean: number
  current_speed_median: number
  current_speed_percentile5: number
  current_speed_percentile95: number
  current_direction: number
  current_direction_probability346to15: number
  current_direction_probability16to45: number
  current_direction_probability46to75: number
  current_direction_probability76to105: number
  current_direction_probability106to135: number
  current_direction_probability136to165: number
  current_direction_probability166to195: number
  current_direction_probability196to225: number
  current_direction_probability226to255: number
  current_direction_probability256to285: number
  current_direction_probability286to315: number
  current_direction_probability316to345: number
}

const CurrentRose = ({
  data,
  minified = false,
}: {
  data: CurrentData
  minified?: boolean
}) => {
  const intl = useIntl()
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const outerArrowRef = useRef<Highcharts.SVGElement | null>(null)
  const innerArrowRef = useRef<Highcharts.SVGElement | null>(null)
  const cardinalDirectionsRef = useRef<Highcharts.SVGElement[] | null>(null)
  const distribution = getDistribution(data)
  const esp_0 = data?.current_direction

  useEffect(() => {
    const chart = chartComponentRef.current?.chart
    if (!chart) return
    drawCardinalDirections(chart, cardinalDirectionsRef, intl, minified)

    drawArrow(chart, esp_0, outerArrowRef, innerArrowRef, minified)

    const onRedraw = () => {
      drawArrow(chart, esp_0, outerArrowRef, innerArrowRef, minified)
      drawCardinalDirections(chart, cardinalDirectionsRef, intl, minified)
    }
    Highcharts.addEvent(chart, "redraw", onRedraw)

    return () => {
      Highcharts.removeEvent(chart, "redraw", onRedraw)
    }
  }, [intl, esp_0, minified])

  if (!data) return null
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getCurrentRoseOptions(distribution, intl, minified)}
      ref={chartComponentRef}
    />
  )
}

export default CurrentRose
