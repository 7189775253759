import React from "react"
import moment from "moment"
import { useTimezone } from "@luna/luna-core"

interface Props {
  from: string
  to: string
}

const FromToDateString: React.FC<Props> = ({ from, to }) => {
  const fromDate = moment(from)
  const toDate = moment(to)
  const { currentTimezone } = useTimezone()
  if (fromDate.isSame(toDate)) {
    return <>{fromDate.tz(currentTimezone).format("DD.MM.YYYY HH zz")}</>
  } else {
    return (
      <>
        {fromDate.tz(currentTimezone).format("DD.MM.YYYY HH")}
        {"-"}
        {toDate.tz(currentTimezone).format("HH zz")}
      </>
    )
  }
}

export default FromToDateString
