import { NumberParam, useQueryParam, withDefault } from "use-query-params"
import LoadingSpinner from "../../LoadingSpinner"
import { ImagePlayer } from "./ImagePlayer"
import type { Mark } from "./UseMarks.service"
import { ImagePlayerContainer } from "./ImagePlayerContainer"
import { MapRenderContainer } from "./MapRenderContainer"
import { useImageQuery } from "./useImageQuery"

type Props = {
  imageURLs: string[] | { url: string; mtime: string }[]
  marks?: Mark[]
  queryParam?: string
  children: (imageAsDataURL: string) => JSX.Element
}

function isStringArray(
  list: string[] | { url: string; mtime: string }[]
): list is string[] {
  return typeof (list as string[])[0] === "string"
}

export const MapRenderImages = ({
  queryParam = "step",
  imageURLs,
  marks,
  children,
}: Props) => {
  const urls = isStringArray(imageURLs)
    ? imageURLs
    : imageURLs.map(({ url }) => url)

  const [index] = useQueryParam(queryParam, withDefault(NumberParam, 0))
  const { isLoading, error, data, isInitialData } = useImageQuery(urls)

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <>{error}</>
  }

  if (!data) {
    return <>Ingen bildedata ble lastet inn. Vennligst kontakt support.</>
  }

  const entry = data[index]

  return (
    <MapRenderContainer
      imagePlayer={
        <ImagePlayerContainer>
          <ImagePlayer
            queryParam={queryParam}
            loading={isInitialData}
            imageList={data}
            marks={marks}
          />
        </ImagePlayerContainer>
      }
    >
      {children(entry)}
    </MapRenderContainer>
  )
}
