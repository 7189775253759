import { NumberParam, useQueryParam, withDefault } from "use-query-params"
import LoadingSpinner from "../../LoadingSpinner"
import { ImagePlayer } from "./ImagePlayer"
import type { Mark } from "./UseMarks.service"
import { ImagePlayerContainer } from "./ImagePlayerContainer"
import { MapRenderContainer } from "./MapRenderContainer"
import { useImagePairQuery } from "./useImagePairQuery"

type Props = {
  imagePairs: ImagePair[]
  marks?: Mark[]
  children: (imagePair: ImagePair) => JSX.Element
}

export const MapRenderImagePairs = ({
  imagePairs: images,
  marks,
  children,
}: Props) => {
  const [index] = useQueryParam("step", withDefault(NumberParam, 0))
  const { isLoading, error, data, isInitialData } = useImagePairQuery(images)

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <>{error}</>
  }

  if (!data) {
    return <>Ingen bildedata ble lastet inn. Vennligst kontakt support.</>
  }

  const entry = data[index]

  return (
    <MapRenderContainer
      imagePlayer={
        <ImagePlayerContainer>
          <ImagePlayer loading={isInitialData} imageList={data} />
        </ImagePlayerContainer>
      }
    >
      {children(entry)}
    </MapRenderContainer>
  )
}
