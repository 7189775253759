import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import React, { FC, useEffect } from "react"
import { getAvailabelArchivedOffshoreForecast } from "services/dataFetcher"
import OffshoreHistoryListRow from "./OffshoreHistoryListRow"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { DateTime } from "luxon"
import { CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"

interface Props {
  orderId: number
  stationName: string
}

const luxonDateFormat = "dd.MM.yyyy"
const initialListSize = 10

const OffshoreHistory: React.FC<Props> = ({ orderId, stationName }) => {
  const intl = useIntl()

  const [availableArchivedForecastList, setAvailableArchivedForecastList] =
    React.useState<AvailableArchivedForecast[]>([])

  const [dateStart, setDateStart] = React.useState<DateTime>(DateTime.now())
  const [dateEnd, setDateEnd] = React.useState<DateTime>(DateTime.now())

  useEffect(() => {
    getAvailabelArchivedOffshoreForecast({ orderId })
      .then((data) => {
        setAvailableArchivedForecastList(data)
        const sortedDates = data
          .map((f) => DateTime.fromISO(f.validFrom))
          .sort()
        setDateStart(
          sortedDates[sortedDates.length - initialListSize] ||
            DateTime.min(...sortedDates)
        )
        setDateEnd(DateTime.max(...sortedDates))
      })
      .catch((err: Error) => {
        const message = "Failed to load available archived forecast"
        console.log(message, err)
        throw new Error(message)
      })
  }, [orderId])

  if (availableArchivedForecastList.length === 0) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant={"body1"}>
            {intl.formatMessage({ id: "historyPageDesc" })}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <DatePicker
                label={intl.formatMessage({ id: "from" })}
                value={dateStart}
                onChange={(newValue) => {
                  if (newValue) setDateStart(newValue)
                }}
                inputFormat={luxonDateFormat}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label={intl.formatMessage({ id: "to" })}
                value={dateEnd}
                onChange={(newValue) => {
                  if (newValue) setDateEnd(newValue)
                }}
                inputFormat={luxonDateFormat}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <hr />
        </Grid>
        <Grid item>
          <Typography variant={"h2"}>
            <FormattedMessage id="prediction" />
            {` ${dateStart.toFormat(luxonDateFormat)} - ${dateEnd.toFormat(
              luxonDateFormat
            )}`}
          </Typography>
        </Grid>
        <Grid item>
          <OffshoreHistoryRows
            archivedForecastList={availableArchivedForecastList.filter(
              (forecast) =>
                DateTime.fromISO(forecast.validFrom) >=
                  dateStart.startOf("day") &&
                DateTime.fromISO(forecast.validFrom) < dateEnd.endOf("day")
            )}
            stationName={stationName}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export const OffshoreHistoryRows: FC<{
  archivedForecastList: AvailableArchivedForecast[]
  stationName: string
}> = ({ archivedForecastList, stationName }) => {
  return (
    <>
      {archivedForecastList.map((forecast) => (
        <OffshoreHistoryListRow
          key={forecast.reportID}
          available={forecast}
          stationName={stationName}
        />
      ))}
    </>
  )
}

export default OffshoreHistory
