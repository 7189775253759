import React, { useState } from "react"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { styled } from "@mui/material"

interface Props {
  selected: string | number
  buttons: Array<string | number | { label: string; value: string | number }>

  onChange: (string: string) => void
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  margin: theme.spacing(0.5),
  "&:first-child": {
    borderRadius: theme.shape.borderRadius,
  },
  "& .Mui-selected, & .Mui-selected:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))

const RadioButtons: React.FC<Props> = ({ selected, buttons, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(selected.toString())

  const handleChange = (event: any, newValue: any) => {
    setSelectedValue(newValue)
    onChange(newValue)
  }

  const isLabelSpecified = (
    button: string | number | { label: string; value: string | number }
  ): button is { label: string; value: string | number } => {
    return (
      (button as { label: string; value: string | number }).label !== undefined
    )
  }
  return (
    <>
      <StyledToggleButtonGroup
        value={selectedValue}
        exclusive
        onChange={handleChange}
        aria-label="text alignment"
      >
        {buttons.map(
          (
            button: string | number | { label: string; value: string | number }
          ) => (
            <ToggleButton
              key={isLabelSpecified(button) ? button.label : button}
              value={
                isLabelSpecified(button)
                  ? button.value.toString()
                  : button.toString()
              }
              aria-label={
                isLabelSpecified(button)
                  ? button.value.toString()
                  : button.toString()
              }
            >
              {isLabelSpecified(button) ? button.label : button}
            </ToggleButton>
          )
        )}
      </StyledToggleButtonGroup>
    </>
  )
}

export default RadioButtons
