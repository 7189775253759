import { useTheme, useMediaQuery, Box, Drawer } from "@mui/material"

export const ImagePlayerContainer: React.FC = ({ children }) => {
  const theme = useTheme() as any
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  if (isDesktop) {
    return (
      <Box paddingY={4} paddingRight={4} style={{ minHeight: "78px" }}>
        {children}
      </Box>
    )
  }
  return (
    <Drawer variant="permanent" anchor="bottom">
      <Box paddingLeft={2} marginRight={7} paddingTop={4} paddingBottom={2}>
        {children}
      </Box>
    </Drawer>
  )
}
