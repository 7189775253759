import React from "react"
import {
  Grid,
  SvgIcon,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import moment from "moment"
import { convertToKnots, convertToMetersPerSecond } from "@luna/luna-core"
import { useTimezone, useWindUnit } from "@luna/luna-core"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "components/Link"
import { round } from "lodash"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.background.default}`,
      overflow: "hidden",
    },
    progress: {
      textAlign: "center",
      verticalAlign: "middle",
      margin: "20em",
    },
  })
)

const threshold = {
  low: {
    windKnots: 25,
    hs: 3.5,
    color: "#E6F7FF",
    fontColor: "#000000",
  },
  high: {
    windKnots: 35,
    hs: 5,
    color: "#0d78c6",
    fontColor: "#FFFFFF",
  },
}

interface CriteriaIconProps {
  color: string
}

interface OverviewProps {
  props?: ActiveForecastSummaryList
}

const OffshoreOverview: React.FC<OverviewProps> = ({ props }) => {
  const classes = useStyles()

  let Body = () => <TableBody />
  if (props) {
    Body = () => (
      <TableBody>
        {props.map((row) => (
          <TableBodyRow {...row} key={row.id} />
        ))}
      </TableBody>
    )
  }

  return (
    <Grid container>
      <OffshoreOverviewHeader />
      <Grid item xs={12}>
        <TableContainer>
          <Table className={classes.table}>
            <Header />
            <Body />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

const OffshoreOverviewHeader = () => {
  const theme = useTheme()
  const { windUnit } = useWindUnit()
  const { high, low } = threshold

  let lowWind = low.windKnots
  let highWind = high.windKnots
  const windUnitIsMs = windUnit === "m/s"
  if (windUnitIsMs) {
    lowWind = convertToMetersPerSecond(low.windKnots)
    highWind = convertToMetersPerSecond(high.windKnots)
  }
  const messageId = windUnitIsMs ? "m/s" : "knots"

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <Typography variant={"h1"} style={{ marginBottom: theme.spacing(2) }}>
          <FormattedMessage id="offshore_forecast_title" />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>
          <FormattedMessage id="max_wind_hs_desc_key" />:{" "}
          <strong>
            <FormattedMessage
              id={
                windUnitIsMs
                  ? "max_wind_hs_desc_value_ms"
                  : "max_wind_hs_desc_value"
              }
            />
          </strong>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ marginBottom: theme.spacing(1) }}>
          <strong>
            <FormattedMessage id="threshold_values" />:
          </strong>{" "}
          <CriteriaColorIcon color={threshold.low.color} /> {lowWind}{" "}
          <FormattedMessage id={messageId} /> / {threshold.low.hs} m{" "}
          <CriteriaColorIcon color={threshold.high.color} /> {highWind}{" "}
          <FormattedMessage id={messageId} /> / {threshold.high.hs} m
        </Typography>
      </Grid>
    </Grid>
  )
}

const Header = () => {
  const classes = useStyles()
  const intl = useIntl()
  const maxValuesTableDays = getTableHeaderDays()
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.table}>
          <Typography variant={"overline"}>
            <FormattedMessage id="locations" />
          </Typography>
          <Typography variant={"body2"} style={{ color: "#555555" }}>
            <FormattedMessage id="last_updated" />
          </Typography>
        </TableCell>
        <TableCell className={classes.table}>
          <Typography variant={"overline"}>
            <FormattedMessage id="day" />
          </Typography>
          <Typography
            variant={"body2"}
            style={{ color: "#555555", textTransform: "capitalize" }}
          >
            <FormattedMessage id="date" />
          </Typography>
        </TableCell>
        {maxValuesTableDays.map((day) => (
          <TableCell className={classes.table} key={day.isoWeekday()}>
            <Typography variant={"overline"}>
              {day.locale(intl.locale).format("dddd")}
            </Typography>
            <Typography variant={"body2"} style={{ color: "#555555" }}>
              {day.format("Do MMM")}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const getTableHeaderDays = () => {
  let day = moment.utc()
  let days = []
  for (let i = 0; i < 7; i++) {
    days[i] = day.clone()
    day.add(1, "days")
  }
  return days
}

function TooltipContent(props: MaxValues) {
  const { currentTimezone } = useTimezone()
  const { windUnit } = useWindUnit()

  let wind = props.wind_speed_10_meter
  if (windUnit === "KT") {
    wind = convertToKnots({
      metersPerSecond: props.wind_speed_10_meter,
    })
  }

  const messageId = windUnit === "KT" ? "knots" : "m/s"
  return (
    <>
      <Typography variant="body2">
        <strong>
          <FormattedMessage id="wind" />:
        </strong>{" "}
        {Number(props.wind_from_direction).toFixed(0)}&deg;/
        {windUnit === "KT" ? round(wind) : wind}{" "}
        <FormattedMessage id={messageId} /> <FormattedMessage id="at_time" />{" "}
        {moment(props.wind_speed_10_meter_time)
          .tz(currentTimezone)
          .format("HH:mm zz")}
      </Typography>
      <Typography variant="body2">
        <strong>Hs:</strong>{" "}
        {Number(props.sea_surface_wave_to_direction).toFixed(0)}&deg;/
        {props.sea_surface_wave_significant_height} m{" "}
        <FormattedMessage id="at_time" />{" "}
        {moment(props.sea_surface_wave_significant_height_time)
          .tz(currentTimezone)
          .format("HH:mm zz")}
      </Typography>
    </>
  )
}

function ArealLabelContent(props: MaxValues) {
  const { windUnit } = useWindUnit()
  const intl = useIntl()
  const { currentTimezone } = useTimezone()

  const windDir = Number(props.wind_from_direction).toFixed(0)
  const hs = Number(props.sea_surface_wave_significant_height).toFixed(1)
  const hsDir = Number(props.sea_surface_wave_to_direction).toFixed(0)

  let wind = props.wind_speed_10_meter
  if (windUnit === "KT") {
    wind = round(
      convertToKnots({
        metersPerSecond: props.wind_speed_10_meter,
      })
    )
  }

  const windUnitStr =
    windUnit === "KT"
      ? intl.formatMessage({ id: "knots" })
      : intl.formatMessage({ id: "m/s" })

  const areaLabel =
    intl.formatMessage({ id: "wind" }) +
    ": " +
    windDir +
    "°/" +
    wind +
    " " +
    windUnitStr +
    " " +
    intl.formatMessage({ id: "at_time" }) +
    " " +
    moment(props.wind_speed_10_meter_time)
      .tz(currentTimezone)
      .format("HH:mm zz") +
    " HS:" +
    hsDir +
    "°/" +
    hs +
    "  m" +
    " " +
    intl.formatMessage({ id: "at_time" }) +
    " " +
    moment(props.sea_surface_wave_significant_height_time)
      .tz(currentTimezone)
      .format("HH:mm zz")

  return areaLabel
}

function CriteriaColorIcon(props: CriteriaIconProps) {
  return (
    <SvgIcon style={{ verticalAlign: "middle" }}>
      <circle
        cx="12"
        cy="10"
        r="9"
        fill={props.color}
        stroke={"#555555"}
        strokeWidth="1px"
      />
    </SvgIcon>
  )
}

function TableCellLocation(props: {
  id: string
  order_id: number
  station: string
  published: Moment
}) {
  const { currentTimezone } = useTimezone()
  const classes = useStyles()

  return (
    <TableCell
      key={props.id + "location"}
      component={"th" as any}
      scope="row"
      className={classes.table}
      colSpan={2}
    >
      <Link
        to={"offshore/summary/" + props.order_id}
        variant={"body1"}
        color="inherit"
        style={{
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        {props.station}
      </Link>
      <Typography variant="body2" style={{ color: "#555555" }}>
        {moment(props.published)
          .tz(currentTimezone)
          .format("DD.MM.YYYY HH:mm zz")}
      </Typography>
    </TableCell>
  )
}

function TableBodyRow(props: ActiveForecastSummary) {
  const days = getTableHeaderDays()
  const row = []

  row.push(TableCellLocation(props))

  days.forEach((day) => {
    let maxForDay = null
    props.maxValuesList.forEach((values) => {
      if (
        day.dayOfYear() ===
        moment.utc(values.wind_speed_10_meter_time).dayOfYear()
      ) {
        maxForDay = values
      }
    })
    if (maxForDay) {
      row.push(
        TableBodyCell(
          props.id + "-" + props.station + "-" + day.dayOfYear(),
          maxForDay
        )
      )
    } else {
      row.push(
        TableBodyCell(props.id + "-" + props.station + "-" + day.dayOfYear())
      )
    }
  })
  return <TableRow>{row}</TableRow>
}

function TableBodyCell(id: string, props?: MaxValues) {
  const theme = useTheme()
  let thresholdBgColor = theme.palette.common.white
  let thresholdFontColor = "#000000"
  const { windUnit } = useWindUnit()

  if (!props) {
    const styles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          color: thresholdFontColor,
          backgroundColor: thresholdBgColor,
          border: `1px solid ${theme.palette.background.default}`,
          textAlign: "center",
        },
      })
    )()

    return <TableCell classes={styles} key={id} />
  }

  const { wind_speed_10_meter, sea_surface_wave_significant_height } = props
  const wind_speed_10_meter_knots = convertToKnots({
    metersPerSecond: wind_speed_10_meter,
  })

  if (
    wind_speed_10_meter_knots >= threshold.low.windKnots ||
    sea_surface_wave_significant_height >= threshold.low.hs
  ) {
    thresholdBgColor = threshold.low.color
    thresholdFontColor = threshold.low.fontColor
  }
  if (
    wind_speed_10_meter_knots >= threshold.high.windKnots ||
    sea_surface_wave_significant_height >= threshold.high.hs
  ) {
    thresholdBgColor = threshold.high.color
    thresholdFontColor = threshold.high.fontColor
  }

  const styles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: thresholdFontColor,
        backgroundColor: thresholdBgColor,
        border: `1px solid ${theme.palette.background.default}`,
        textAlign: "center",
      },
    })
  )()

  const tooltipStyles = makeStyles((theme: Theme) =>
    createStyles({
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
      arrow: {
        color: theme.palette.common.black,
      },
    })
  )()

  let wind = wind_speed_10_meter
  if (windUnit === "KT") {
    wind = round(
      convertToKnots({
        metersPerSecond: wind_speed_10_meter,
      })
    )
  }
  const hs = Number(props.sea_surface_wave_significant_height).toFixed(1)

  return (
    <TableCell classes={styles} key={id}>
      <Tooltip
        title={<TooltipContent {...props} />}
        arrow
        classes={tooltipStyles}
      >
        <Typography
          variant="body1"
          aria-label={ArealLabelContent(props)}
          style={{ fontWeight: "bold" }}
        >
          {windUnit === "KT" ? round(wind) : wind} / {hs}
        </Typography>
      </Tooltip>
    </TableCell>
  )
}

export default OffshoreOverview
