import moment from "moment"
import { authorizationHeader, checkStatus } from "./dataFetcher"
import { config } from "../config"

export async function getActiveOffshoreForecasts(): Promise<ActiveForecastSummaryList> {
  return fetch(`${config.api.lunaUrl}/api/v1/products/offshore/active`, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then(checkStatus)
    .then((res) => res.json())
}

export async function getOffshoreReportData(configValues?: {
  orderId?: string
}): Promise<OffshoreReportData> {
  const { orderId } = configValues || {}
  return fetch(
    `${config.api.lunaUrl}/api/v1/products/offshore/reports/latest/${orderId}`,
    {
      headers: {
        authorization: await authorizationHeader(),
      },
    }
  )
    .then(checkStatus)
    .then((res) => res.json())
    .then(convertOffshoreReport)
}

export const convertOffshoreReport = ({
  dataSchemaVersion,
  id,
  data: { qubaData, offshoreData },
}: OffshoreReport): OffshoreReportData => {
  if (Number.parseInt(dataSchemaVersion) !== 1) {
    throw new Error(`Unexpected data schema version: ${dataSchemaVersion}`)
  }
  return {
    id,
    qubaData: parseOffshoreForecastData(qubaData),
    offshoreData,
  }
}

export async function getCurrentEPSData(
  location: string
): Promise<OffshoreForecast> {
  return fetch(
    `${config.api.lunaUrl}/api/v1/products/offshore/current/${location}`,
    {
      headers: {
        authorization: await authorizationHeader(),
      },
    }
  )
    .then(checkStatus)
    .then((res) => res.json())
}

export async function getOffshoreMap(path: string): Promise<string> {
  return fetch(`${config.api.lunaUrl}${path}`, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then((res) => res.blob())
    .then((res) => URL.createObjectURL(res))
}

export async function getPlainImage(url: string): Promise<string> {
  return fetch(url)
    .then((res) => res.blob())
    .then((res) => URL.createObjectURL(res))
}

export function getOffshoreMaps(paths: Array<string>): Promise<Array<string>> {
  let mapImgs = paths.map((path) => {
    return getOffshoreMap(path)
  })
  return Promise.all(mapImgs)
}

const parseOffshoreForecastData = (
  rawForecast: RawOffshoreForecast
): OffshoreForecast => {
  const { forecast, meta, maxValuesList = [], ...rest } = rawForecast
  const { id, longitude, latitude, ...restOfStation } = meta.station
  return {
    ...rest,
    maxValuesList: maxValuesList.map((row) => ({
      wind_speed_10_meter: parseFloat(row.wind_speed_10_meter),
      wind_from_direction: parseFloat(row.wind_from_direction),
      wind_speed_10_meter_time: moment.utc(row.wind_speed_10_meter_time),
      sea_surface_wave_significant_height: parseFloat(
        row.sea_surface_wave_significant_height
      ),
      sea_surface_wave_significant_height_time: moment.utc(
        row.sea_surface_wave_significant_height_time
      ),
      sea_surface_wave_to_direction: parseFloat(
        row.sea_surface_wave_to_direction
      ),
    })),
    meta: {
      ...meta,
      run: moment.utc(meta.run),
      station: {
        id: Number.parseInt(id),
        latitude: Number.parseFloat(latitude),
        longitude: Number.parseFloat(longitude),
        ...restOfStation,
      },
    },
    forecast: forecast.map(({ valid, data }) => ({
      valid: moment.utc(valid),
      data: Object.entries(data).reduce((acc, [key, value]) => {
        var valueNew = Number.parseFloat(value)

        if (key === "diskret_sikt") {
          acc[key] = convertToVisabiltyString(value)
        } else {
          if (valueNew === 0) {
            if (
              key === "wave_direction" ||
              key === "wind_from_direction" ||
              key === "sea_surface_swell_wave_to_direction"
            ) {
              valueNew = 360
            } else {
              valueNew += 0.00000001
            }
          }

          acc[key] = valueNew
        }

        return acc
      }, {} as any),
    })),
  }
}

function convertToVisabiltyString(value: String): String {
  let returnStr: String = ""
  if (value === "11.0") {
    returnStr = "10+"
  } else if (value === "10.0") {
    returnStr = "6-10"
  } else if (value === "9.0") {
    returnStr = "4-10 in showers"
  } else if (value === "8.0") {
    returnStr = "4-10"
  } else if (value === "7.0") {
    returnStr = "3-8"
  } else if (value === "6.0") {
    returnStr = "1-4 in showers"
  } else if (value === "5.0") {
    returnStr = "1-4"
  } else if (value === "4.0") {
    returnStr = "0.5-2 in showers"
  } else if (value === "3.0") {
    returnStr = "0.5-2"
  } else if (value === "2.0") {
    returnStr = "0.4"
  } else if (value === "1.0") {
    returnStr = "0-1"
  } else {
    returnStr = value
  }
  return returnStr
}
