import { KeycloakProvider } from "@react-keycloak/web"
import Keycloak from "keycloak-js"
import React, { useEffect, useMemo } from "react"
import LoadingSpinner from "./components/LoadingSpinner"
import { config } from "./config"

declare global {
  interface Window {
    configuredKeycloak: Keycloak.KeycloakInstance
  }
}

/**
 * AppSecurity activates Keycloak login protection, and skips
 * it during testing.
 */
export const AppSecurity: React.FC = ({ children }) => {
  const keycloak = useMemo(() => {
    if (process.env.NODE_ENV === "test") {
      // Only used when testing.
      return "" as never
    }
    return Keycloak(config.api.oidc)
  }, [])

  const params = getQueryParams()

  useEffect(() => {
    if (!params["idp"]) {
      return // do nothing
    }
    keycloak.init({ pkceMethod: "S256" }).then(() => {
      if (keycloak.authenticated) {
        window.history.replaceState(null, "", "/")
        return // don't login if user is already logged in.
      }
      keycloak.login({ idpHint: params["idp"] })
    })
  }, [keycloak, params])

  if (params["idp"]) {
    // Show nothing while we wait to trigger login flow.
    return null
  }

  // Store it globally so it can be reached
  // in services that fetch data.
  window.configuredKeycloak = keycloak

  if (process.env.NODE_ENV === "test") {
    return <>{children}</>
  }

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={{ onLoad: "login-required", pkceMethod: "S256" }}
      LoadingComponent={<LoadingSpinner />}
    >
      {children}
    </KeycloakProvider>
  )
}

export function getQueryParams(
  location = window.location
): Record<string, string> {
  const { search = "" } = location
  return search
    .replace(/\?/gi, "")
    .split("&")
    .reduce((acc, valuePair) => {
      const [key, value] = valuePair.split("=")
      acc[key] = value
      return acc
    }, {} as Record<string, string>)
}
