import React, { Fragment, useEffect } from "react"
import { XWWOutlookReportData, getXWWOutlookReportData } from "./dataFetcher"
import {
  Container,
  Grid,
  Theme,
  Breadcrumbs,
  Typography,
  Box,
} from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { getImage } from "./dataFetcher"
import { LunaLink } from "./LunaLink"
import { useParams } from "react-router-dom"
import { injectIntl, FormattedMessage } from "react-intl"
import CircularProgress from "@mui/material/CircularProgress"
import { MessageAlerts, useMessages } from "components/Message/MessageAlerts"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    outlook: {
      margin: theme.spacing(3),
    },
    paper: {
      height: "100%",
    },
    progress: {
      textAlign: "center",
      verticalAlign: "middle",
      margin: "20em",
    },

    header: {
      fontWeight: "bold",
    },
  })
)

interface Params {
  reportId: string
}

const EXWWOutlookPage: React.FC = () => {
  const classes = useStyles()
  const { reportId } = useParams<Params>()
  const { messages } = useMessages({ autoRefresh: true })

  const [epsPlot, setEpsPlot] = React.useState("string")

  useEffect(() => {
    const epsUrls = "/eps/waveEps_Ekofisk_os.png"
    getImage(epsUrls)
      .then((data) => {
        setEpsPlot(data)
      })
      .catch((err: Error) => {
        const message = "Failed to load eps plot"
        console.log(message, err)
        throw new Error(message)
      })
  }, [])

  const [report, setReport] = React.useState<XWWOutlookReportData | undefined>()
  useEffect(() => {
    getXWWOutlookReportData("ekofisk", { reportId })
      .then((data) => setReport(data))
      .catch((err: Error) => {
        const message = "Failed to load EXWWOutlook report."
        console.log(message, err)
        // Re-throw error so it's caught by <ErrorBoundary />
        throw new Error(message)
      })
  }, [reportId])

  if (!report) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    )
  }

  const { xwwOutlookData } = report

  return (
    <Fragment>
      <MessageAlerts messages={messages} />

      <Container className={classes.root} maxWidth={"xl"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box pt={2} pb={2} pl={2}>
              <Breadcrumbs aria-label="breadcrumb">
                <LunaLink to="/">Luna</LunaLink>

                <Typography color="textPrimary">
                  <FormattedMessage id="seven_day_exww" />
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.outlook}>
            <Typography variant="body1">{xwwOutlookData.headline}</Typography>
            <br></br>
            <Typography variant="h3">EKOFISK</Typography>
            <br></br>
            <Typography variant="body1">{xwwOutlookData.body}</Typography>
            <br></br>

            <Typography variant="body1">EXWW-varsler</Typography>
            <Typography variant="body1">{xwwOutlookData.createdBy}</Typography>
            <Typography variant="body1">90588934</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Wave Eps Ekofisk</Typography>
            {epsPlot ? (
              <img src={epsPlot} alt="EPS plot" />
            ) : (
              <p>failed to load maps</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Ensemble prediction system</Typography>
            <Typography variant="body1">
              At the European Centre for Medium Range Weather Forecast (ECMWF)
              52 forecasts are produced to reflect the uncertainty in the
              initial state of the atmosphere. These uncertainties are due to
              errors in the observations and insufficient sampling. Instead of
              using just one model run, many runs with slightly different
              initial conditions are made. An average, or "ensemble mean", of
              the different forecasts is created. This ensemble mean will likely
              have more skill because it averages over the many possible initial
              states and essentially smoothes the chaotic nature of weather.
              Forecasting probabilities of different conditions is possible
              because of the large ensemble of forecasts available.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default injectIntl(EXWWOutlookPage)
