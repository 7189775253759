import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AnnouncementIcon from "@mui/icons-material/Announcement"
import InfoIcon from "@mui/icons-material/Info"
import WarningIcon from "@mui/icons-material/Warning"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { MessageAlert } from "./MessageType"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export const Message: React.FC<MessageAlert> = (
  messageResponse: MessageAlert
) => {
  const [open, setOpen] = useState(true)
  const [expanded, setExpanded] = React.useState<string | false>(
    messageResponse.id
  )

  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const intl = useIntl()

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  useEffect(() => {
    async function updateLanguage() {
      if (intl.locale === "nb") {
        setTitle(messageResponse.no_NB.title)
        setMessage(messageResponse.no_NB.message)
      } else {
        setTitle(messageResponse.en_GB.title)
        setMessage(messageResponse.en_GB.message)
      }
    }
    updateLanguage()
  }, [
    intl.locale,
    messageResponse.en_GB.message,
    messageResponse.en_GB.title,
    messageResponse.no_NB.message,
    messageResponse.no_NB.title,
  ])

  return (
    <Collapse in={open} sx={{ width: "100%" }}>
      <Alert
        iconMapping={{
          info: <AnnouncementIcon sx={{ color: "#0d78c6" }} />,
          warning: <InfoIcon sx={{ color: "#FFD22D" }} />,
          error: <WarningIcon sx={{ color: "#E03939" }} />,
        }}
        severity={messageResponse.type}
        action={
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false)
              window.localStorage.setItem(
                messageResponse.id,
                JSON.stringify({ closed: true })
              )
            }}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{ mb: 2, mt: 2 }}
      >
        <Accordion
          expanded={expanded === messageResponse.id}
          disableGutters
          sx={{
            backgroundColor: "transparent",
            border: "0px",
            maxWidth: "600px",
          }}
          onChange={handleChange(messageResponse.id)}
        >
          <AccordionSummary // fighting mui styling with negative margins
            sx={{
              minHeight: "20px",
              mt: "-10px",
              mb: "-6px",
            }}
            expandIcon={<ExpandMoreIcon />}
            id={messageResponse.id}
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{message}</Typography>
          </AccordionDetails>
        </Accordion>
      </Alert>
    </Collapse>
  )
}
