import { Paper, Box, Grid, InputLabel, Typography } from "@mui/material"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import {
  createEnumParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from "use-query-params"
import RadioButtons from "../RadioButtons"
import { useMapDetails, Millimeter } from "./ChanceOfRainMap.service"
import { FormattedMessage, useIntl } from "react-intl"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"

export const ChanceOfRainMap = () => {
  const [query, setQuery] = useQueryParams({
    millimeter: withDefault(NumberParam, 5.0),
    provider: withDefault(createEnumParam(["ECMWF", "AROME"]), "AROME"),
  })
  const intl = useIntl()
  const millimeters = query.millimeter as Millimeter
  const provider = query.provider as "ECMWF" | "AROME"
  const period = "24t"

  const { mapType } = useMapDetails({
    millimeters: millimeters,
    provider,
    period,
    type: "standard",
  })

  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  const mapTitle = intl.formatMessage({ id: "map_change_of_rain" })
  return (
    <>
      <Box pb={4}>
        <Box pb={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2" component="h1">
                {mapTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <InputLabel id="select-map-label">
                    <FormattedMessage id="display_model" />:
                  </InputLabel>
                </Grid>
                <Grid item>
                  <RadioButtons
                    buttons={[
                      {
                        value: "AROME",
                        label: `Arome - ${intl.formatMessage({
                          id: "short_term",
                        })}`,
                      },
                      {
                        value: "ECMWF",
                        label: `ECMWF - ${intl.formatMessage({
                          id: "long_term",
                        })}`,
                      },
                    ]}
                    onChange={(value: any) =>
                      setQuery({ provider: value }, "replaceIn")
                    }
                    selected={provider}
                  />
                </Grid>
                <Grid item>
                  <FullscreenDialogButton
                    type="minimal"
                    dialogID="fullscreen-map"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box pb={2}>
          <Typography variant="subtitle1">
            <FormattedMessage id="map_change_of_rain_subtitle" />:
          </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <RadioButtons
              buttons={[
                { label: "5.0 mm", value: 5.0 },
                { label: "10 mm", value: 10 },
                { label: "20 mm", value: 20 },
                { label: "40 mm", value: 40 },
                { label: "60 mm", value: 60 },
                { label: "80 mm", value: 80 },
                { label: "100 mm", value: 100 },
              ]}
              onChange={(value: any) =>
                setQuery({ millimeter: value }, "replaceIn")
              }
              selected={millimeters}
            />
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return (
                    <img
                      alt="kart over sannsynlighet nedbør"
                      src={selectedImage}
                    />
                  )
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>

          <Grid item xs={4}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                <FormattedMessage id="map_explanation" />
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                <FormattedMessage id="map_change_of_rain_exp" />
              </Typography>

              <Typography variant="body1" style={{ padding: 10 }}>
                <FormattedMessage id="map_change_of_rain_exp2" />
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
