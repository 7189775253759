import { useCallback } from "react"
import { useQuery } from "react-query"
import { loadLunaFile, loadWeatherAPIFile } from "services/dataFetcher"
import { NumberParam, useQueryParam, withDefault } from "use-query-params"

/**
 * Loads a series of image URLs using Keycloak token and
 * returns data URIs containing the image data.
 *
 * See also:
 * {@link ./useImagePairQuery}
 * {@link https://css-tricks.com/data-uris/}
 */
export const useImageQuery = (images: string[]) => {
  const [index] = useQueryParam("step", withDefault(NumberParam, 0))
  const loadFileData = useCallback(async () => {
    const data: string[] = []
    for (const imageUrl of images) {
      if (imageUrl.includes("radar")) {
        const imageData = await loadWeatherAPIFile(imageUrl)
        data.push(imageData)
      } else {
        const imageDataUrl = await loadLunaFile(imageUrl)
        data.push(imageDataUrl)
      }
    }
    return data
  }, [images])

  const imagesCache = images.reduce((acc, elem) => acc + elem, "")

  const cacheKey = ["ImageComparer", imagesCache]

  const result = useQuery(cacheKey, loadFileData, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  // Fetch only the image located at the current index.
  const loadInitialFileData = useCallback(async (): Promise<string[]> => {
    const data: string[] = []
    // Placing the data at the exact index we expect it to be.
    if (images[index].includes("radar")) {
      data[index] = await loadWeatherAPIFile(images[index])
    } else {
      data[index] = await loadLunaFile(images[index])
    }
    return data
    // Disabling warning here because we do not want to trigger
    // re-render when the index value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images /* index, */])

  const initialCacheKey = ["ImageComparerInitial", images[index]]

  const initialResult = useQuery(initialCacheKey, loadInitialFileData, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  if (!result.isLoading) {
    return { ...result, isInitialData: false }
  }

  return { ...initialResult, isInitialData: true }
}
