import React from "react"
import { Typography, Theme, Container, Box } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)

const NoMatch404Page: React.FC = () => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Box pt={4} paddingBottom={2}>
        <Typography variant={"h1"}>Splitte mine bramseil!</Typography>
        <p>Du er kommet til en side som ikke finnes.</p>
        <p>
          <Link to="/">Ta meg tilbake til forsiden.</Link>
        </p>
      </Box>
    </Container>
  )
}

export default NoMatch404Page
