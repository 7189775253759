import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import React, { CSSProperties } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { Button, Tooltip, Typography } from "@mui/material"
import { BorderedIconButton } from "./BorderedIconButton"

interface Props {
  type?: "default" | "minimal"
  dialogID: string
  style?: CSSProperties
}

/**
 * Purpose of this button is to add query parameter in url for 'dialogID',
 * this button is meant to be used in combination with the FullscreenDialog
 * component, which is programmed to react to url changes.
 */
export const FullscreenDialogButton: React.FunctionComponent<Props> = ({
  type = "default",
  dialogID,
  style,
}) => {
  const location = useLocation()
  const intl = useIntl()
  const history = useHistory()
  const onClickHandler = () => {
    // Unpack any existing query params and include openDialog param.
    const searchParams = queryString.parse(location.search)
    const newParams = { openDialog: dialogID, ...searchParams }
    history.push(`${location.pathname}?${queryString.stringify(newParams)}`)
  }

  if (type === "minimal") {
    return (
      <BorderedIconButton
        onClick={onClickHandler}
        aria-label={intl.formatMessage({ id: "openInFullscreen" })}
      >
        <Tooltip
          title={
            <Typography variant="caption">
              <FormattedMessage id="openInFullscreen" />
            </Typography>
          }
          placement="top-start"
        >
          <OpenInNewIcon />
        </Tooltip>
      </BorderedIconButton>
    )
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onClickHandler}
      startIcon={<OpenInNewIcon />}
      style={style}
      aria-label={intl.formatMessage({ id: "openInFullscreen" })}
    >
      <FormattedMessage id="openInFullscreen" />
    </Button>
  )
}
