import { getXWWOutlookReportData, getXWWReportData } from "@luna/luna-xww"
import { useEffect, useState } from "react"
import { SpecialForecastValues } from "./SpecialForecastOverview"

export const useSpecialForecasts = ({
  userProducts,
}: {
  userProducts: UserProducts
}) => {
  const [specialForecasts, setSpecialForecasts] = useState<
    SpecialForecastValues[]
  >([])

  useEffect(() => {
    if (userProducts?.products.includes("exwwoutlook")) {
      getXWWOutlookReportData("ekofisk")
        .catch((err: Error) => {
          const message = "Failed to load EXWWOutlook report."
          console.log(message, err)
          // Re-throw error so it's caught by <ErrorBoundary />
          throw new Error(message)
        })
        .then((exwwoutlookData) => {
          const exwwoutlookForecast: SpecialForecastValues = {
            uri: "/exwwoutlook",
            name: "seven_day_exww",
            update_time: exwwoutlookData.xwwOutlookData.published,
            alertness_level: "",
          }
          setSpecialForecasts((prevValue) => {
            return [...prevValue, exwwoutlookForecast]
          })
        })
    }

    if (userProducts?.products.includes("exww")) {
      getXWWReportData("ekofisk")
        .catch((err: Error) => {
          const message = "Failed to load EXWW report."
          console.log(message, err)
          // Re-throw error so it's caught by <ErrorBoundary />
          throw new Error(message)
        })
        .then((exwwData) => {
          const exwwForecast: SpecialForecastValues = {
            uri: "/exww/summary",
            name: "exww_name",
            update_time: exwwData.xwwData.published,
            alertness_level: exwwData.xwwData.alert_level,
          }
          setSpecialForecasts((prevValue) => {
            return [...prevValue, exwwForecast]
          })
        })
    }
    // Convert object to string value to ensure fewer re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userProducts)])

  return { specialForecasts }
}
