import type { KeycloakConfig } from "keycloak-js"

interface APIConfig {
  environment: "dev" | "production" | "staging" | "localhost"
  lunaUrl: string
  filesUrl: string
  weatherUrl: string
  frostUrl: string
  locationforecastUrl: string
  oidc: KeycloakConfig
}

const frostURL = "https://frost-beta.met.no"

const dev: APIConfig = {
  environment: "dev",
  lunaUrl: "https://staging-luna-backend-b.met.no",
  filesUrl: "https://files.dev.luna.met.no",
  weatherUrl: "https://api.met.no/weatherapi",
  frostUrl: frostURL,
  locationforecastUrl:
    "https://api.met.no/weatherapi/locationforecast/2.0/complete",
  oidc: {
    realm: "External",
    url: "https://login.met.no/auth/",
    clientId: "luna-dev",
  },
}

const local: APIConfig = {
  environment: "localhost",
  lunaUrl: "https://staging-luna-backend-b.met.no",
  filesUrl: "https://files.staging.luna.met.no",
  weatherUrl: "https://api.met.no/weatherapi",
  frostUrl: frostURL,
  locationforecastUrl:
    "https://api.met.no/weatherapi/locationforecast/2.0/complete",
  oidc: {
    realm: "External",
    url: "https://login.met.no/auth/",
    clientId: "luna-dev",
  },
}

const staging: APIConfig = {
  environment: "staging",
  lunaUrl: "https://staging-luna-backend-a.met.no",
  filesUrl: "https://files.staging.luna.met.no",
  weatherUrl: "https://api.met.no/weatherapi",
  frostUrl: frostURL,
  locationforecastUrl:
    "https://api.met.no/weatherapi/locationforecast/2.0/complete",
  oidc: {
    realm: "External",
    url: "https://login.met.no/auth/",
    clientId: "luna-staging",
  },
}

const production: APIConfig = {
  environment: "production",
  lunaUrl: "https://luna-backend.met.no",
  filesUrl: "https://files.luna.met.no",
  weatherUrl: "https://api.met.no/weatherapi",
  frostUrl: frostURL,
  locationforecastUrl:
    "https://api.met.no/weatherapi/locationforecast/2.0/complete",
  oidc: {
    realm: "External",
    url: "https://login.met.no/auth/",
    clientId: "luna",
  },
}

const isRunningOnDomain = (domain: string | Array<string>): boolean => {
  if (typeof window === "undefined") {
    return false
  }
  if (Array.isArray(domain)) {
    return !!domain.find(isRunningOnDomain)
  }
  return window.location.host.indexOf(domain) !== -1
}

/**
 * This lookup table is created when the application loads. Based
 * on the window object it specifies an API. This is done this way
 * to avoid needing to statically build this code with different
 * environment variables.
 */
const api: APIConfig = (() => {
  const host = window.location.host
  if (isRunningOnDomain("luna-dev.met.no")) {
    window.config = dev
    return dev
  } else if (isRunningOnDomain("luna-staging.met.no")) {
    window.config = staging
    return staging
  } else if (isRunningOnDomain("luna.met.no")) {
    window.config = production
    return production
  } else if (host.includes("localhost")) {
    window.config = local
    return local
  }
  console.warn("Didn't recognize domain, using production url set", {
    host: window.location.host,
  })
  return production
})()

export const isProduction = api.environment === "production"

/**
 * Specifying this export like so to enforce that callers need to write
 * config.api.*, this will make it clearer that this comes from
 * a centralised configuration.
 */
export const config = {
  api,
}
