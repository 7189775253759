import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import CircularProgress from "@mui/material/CircularProgress"
import Highcharts, { Chart } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsDataGrouping from "highcharts/modules/datagrouping"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting"
import addWindbarb from "highcharts/modules/windbarb"
import Moment from "moment"
import MomentTimeZone from "moment-timezone"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"
import { useTimezone } from "@luna/luna-core"
import { XWWReportData } from "../dataFetcher"
import { calculateEXWWChartOptions } from "./EXWWGraph.dataService"

import { highchartsLocalizations } from "./XWWGraph.localization"

// Highcharts timezones requires Moment.js to be globally available.
window.moment = Moment
MomentTimeZone()

HighchartsExporting(Highcharts)
HighchartsOfflineExporting(Highcharts)
HighchartsDataGrouping(Highcharts)
addWindbarb(Highcharts)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(3),
    },
    header: {
      display: "inline-flex",
      padding: theme.spacing(3),
    },
    headerText: {
      flexGrow: 1,
    },
    formControl: {
      minWidth: 150,
      alignSelf: "flex-end",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    chart: {
      display: "inline-flex",
    },
    boxContent: {
      color: "#555555",
    },
    progress: {
      textAlign: "center",
      verticalAlign: "middle",
    },
  })
)

interface State {
  keyCounter: number
  errMessage?: string
  isDataLoaded: boolean
  criterias: Criteria[]
  forecast?: XWWForecast
  chartOptions?: Highcharts.Options
}

// "No operation", default function
const noop = (input: any) => undefined

interface Props extends WrappedComponentProps {
  currentPlatform?: string
  onPlatformChange?: (e: React.ChangeEvent<{ value: unknown }>) => void
  report: XWWReportData
}

const EXWWGraph: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [data, setData] = useState<State>({
    criterias: [],
    isDataLoaded: false,
    keyCounter: 0,
  })
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  const {
    intl,
    report,
    onPlatformChange = noop,
    currentPlatform = "Ekofiskfeltet",
  } = props
  const { currentTimezone } = useTimezone()

  useEffect(() => {
    Highcharts.setOptions({
      lang: highchartsLocalizations.get(intl.locale),
      time: {
        timezone: currentTimezone,
      },
    })
    const { criteriaData: criterias, qubaData: forecast } = report
    setData((data: State) => ({
      ...data,
      criterias,
      forecast,
      keyCounter: data.keyCounter + 1,
      isDataLoaded: true,
      chartOptions: calculateEXWWChartOptions({
        forecast,
        criterias,
        intl,
        currentPlatform,
        currentTimezone,
      }),
    }))
    /**
     * Update chart options based on intl/location change. This might not be optimal - but makes
     * it easier to handle updates in graph.
     */
  }, [intl, currentTimezone, currentPlatform, report])

  const { chartOptions, errMessage = "", isDataLoaded, keyCounter } = data
  if (errMessage) {
    return <>errMessage</>
  } else if (!isDataLoaded) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    )
  }
  const locations = data.criterias.map((item) => item.location)
  const uniqueLocations = locations.filter(
    (item, index) => locations.indexOf(item) === index
  )

  return (
    <Container className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerText} variant={"h4"}>
          <FormattedMessage id={currentPlatform} />
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel ref={inputLabel} id="outlined-label">
            <FormattedMessage id="choose_platform" />
          </InputLabel>
          <Select
            labelId="outlined-label"
            value={currentPlatform}
            onChange={(e) =>
              // Hack: Casting this as a plain ChangeEvent. However, the MUI docs
              // note that this event won't be a native event unless polyfills are applied.
              // As long as we're only calling e.target.value this should be fine.
              onPlatformChange(e as React.ChangeEvent<{ value: unknown }>)
            }
            variant={"standard"}
          >
            {uniqueLocations.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  <FormattedMessage id={item} />
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <HighchartsReact
        key={keyCounter}
        ref={chartRef}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Container>
  )
}

export default injectIntl(EXWWGraph)
