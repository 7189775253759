import produce from "immer"
import { authorizationHeader, checkStatus } from "./dataFetcher"
import { config } from "../config"
import {
  ObservedData,
  ObservedDataBase,
  WeatherElements,
} from "../@types/ObservationTypes"
import { observedAffix } from "components/Offshore/OffshoreGraph/OffshoreGraphOptions/graphStaticValues"

export async function getObsData(configValues?: {
  obsId?: number | undefined
}): Promise<ObservedData> {
  const { obsId } = configValues || {}
  const toDate = new Date()
  const fromDate = new Date()
  fromDate.setDate(fromDate.getDate() - 2)
  const timestep = `${fromDate.toISOString()}/${toDate.toISOString()}`

  const weatherElements = Object.values(WeatherElements).join(",")

  const url = `${config.api.frostUrl}/api/v1/obs/met.no/filter/get?incobs=true&qualitycodes=0&time=${timestep}&stationids=${obsId}&elementids=${weatherElements}`

  return fetch(url, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then(checkStatus)
    .then((res) => res.json())
    .then((data) => {
      // we add observed_ as a affix to the id, so it wont overlap with offshore model data ids.
      const nextData = produce(data as ObservedDataBase, (draft) => {
        draft.data.tseries = draft.data.tseries.map((elem) => {
          if (
            elem.header.extra.element.id === "max(wind_speed_of_gust PT10M)"
          ) {
            elem.header.extra.element.id = "max_wind_speed_10m"
          }
          elem.header.extra.element.id =
            observedAffix + elem.header.extra.element.id

          return elem
        })
      })

      return nextData.data
    })
}
