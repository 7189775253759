import { Container, Grid, Theme } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"
import XWWPageContent from "./XWWPageContent"
import { XWWReportData } from "./dataFetcher"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)

interface Props {
  report?: XWWReportData
  platform?: string
  onPlatformChange?: (e: React.ChangeEvent<{ value: unknown }>) => void
  overrideTheme?: ({
    defaultTheme,
    lunaTheme,
  }: {
    defaultTheme: any
    lunaTheme: Theme
  }) => Theme
}

const EXWWPreviewPage: React.FC<Props> = ({
  platform,
  onPlatformChange,
  report,
}) => {
  const classes = useStyles()
  if (!report) {
    return (
      <Container className={classes.root} maxWidth={"xl"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Venter på forhåndsvisningsdata.
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <XWWPageContent
      platform={platform}
      onPlatformChange={onPlatformChange}
      report={report}
    />
  )
}

export default EXWWPreviewPage
