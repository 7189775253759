import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { MessageAlerts, useMessages } from "components/Message/MessageAlerts"
import SymbolExplanation from "components/SymbolExplanation"
import { TextForecast } from "components/TextForecast/TextForecast"
import { MapRenderContainer } from "components/WeatherMaps/MapRenderer/MapRenderContainer"
import { XMLForecastLoader } from "components/XMLForecastLoader"

import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

interface QueryParams {
  noSynopticMap?: string
  filename?: string
}

export const TextForecastPage: React.FC<{ showMsg?: boolean }> = ({
  showMsg = true,
}) => {
  const { messages } = useMessages({ autoRefresh: true })
  const theme = useTheme()

  const intl = useIntl()
  const { filename, noSynopticMap } = useParams<QueryParams>()

  const synopticMap = noSynopticMap ? false : true

  const mdSynopticMap = synopticMap ? 4 : 12

  return (
    <Container>
      {showMsg && <MessageAlerts messages={messages} />}
      <Stack spacing={4}>
        <div>
          <Grid container spacing={4} sx={{ paddingBottom: theme.spacing(4) }}>
            <Grid item md={mdSynopticMap}>
              <Paper variant="elevation" sx={{ height: "100%" }}>
                <Box padding={4} sx={{ minHeight: "400px" }}>
                  {filename ? (
                    <TextForecast fileName={filename} />
                  ) : (
                    <TextForecast />
                  )}
                </Box>
              </Paper>
            </Grid>
            {synopticMap && (
              <Grid item md={8}>
                <Paper variant="elevation">
                  <Box padding={4}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <Typography variant="h3" component="h2">
                            <FormattedMessage id="weather_maps" />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={4}
                          wrap="nowrap"
                          alignItems="center"
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <Grid item>
                            <SymbolExplanation />
                          </Grid>
                          <Grid item>
                            <FullscreenDialogButton
                              type="minimal"
                              dialogID="analysekart"
                              style={{ float: "right" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FullscreenDialog
                          id="analysekart"
                          title={intl.formatMessage({ id: "weather_maps" })}
                          showChildrenAlsoWhenClosed
                        >
                          <MapRenderContainer>
                            <XMLForecastLoader type="analyse-kart">
                              {({ latest, formattedDate }) => (
                                <div>
                                  <Typography
                                    variant="h3"
                                    component="h2"
                                    gutterBottom
                                  >
                                    <FormattedMessage id="analysis" />
                                  </Typography>
                                  <Typography variant="subtitle1" gutterBottom>
                                    {formattedDate}
                                  </Typography>
                                  <img src={latest.uri} alt="analyse kart" />
                                </div>
                              )}
                            </XMLForecastLoader>
                            <XMLForecastLoader type="prognose-kart">
                              {({ latest, formattedDate }) => (
                                <div>
                                  <Typography
                                    variant="h3"
                                    component="h2"
                                    gutterBottom
                                  >
                                    <FormattedMessage id="prognosis" />
                                  </Typography>
                                  <Typography variant="subtitle1" gutterBottom>
                                    {formattedDate}
                                  </Typography>
                                  <img src={latest.uri} alt="prognose kart" />
                                </div>
                              )}
                            </XMLForecastLoader>
                          </MapRenderContainer>
                        </FullscreenDialog>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </div>
      </Stack>
    </Container>
  )
}
