import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { config } from "../config"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import React from "react"
import { FormattedMessage } from "react-intl"
import { useFileDownloader } from "../services/dataFetcher"
import { useWindUnit } from "@luna/luna-core"

import moment from "moment"

interface Props {
  id: string
  report: OffshoreReportData
}

export const DownLoadFileDropDown: React.FC<Props> = ({ id, report }) => {
  const { windUnit } = useWindUnit()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let menuButtonIcon = <ExpandMoreIcon />

  const { downloadFile } = useFileDownloader()

  const pdfURL = `/api/v1/products/offshore/reports/${id}/pdf`
  const csvURL = `/api/v1/products/offshore/reports/${id}/csv?windunit=${windUnit}`

  return (
    <>
      <Button
        aria-controls="downLoadFile-menu"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        onClick={handleClick}
        endIcon={menuButtonIcon}
      >
        <FormattedMessage id="downLoadFileMenu" />
      </Button>
      <Menu
        id="downLoadFile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem key="{id}-pdf" onClick={handleClose}>
          <a
            href={`${config.api.lunaUrl}+${pdfURL}`}
            onClick={(e) => {
              // Cancel regular href behavior, before triggering download.
              const filename: string =
                "Offshore Weather for " +
                report.offshoreData.position_name +
                " valid from " +
                moment(report.qubaData.meta.run)
                  .tz("UTC")
                  .format("DD.MM.YYYY HH zz") +
                " by MET NORWAY.pdf"
              e.preventDefault()
              downloadFile({ url: pdfURL, filename: filename })
            }}
          >
            <FormattedMessage id="as_PDF" />
          </a>
        </MenuItem>
        <MenuItem key="{id}-csv" onClick={handleClose}>
          <a
            href={`${config.api.lunaUrl}+${csvURL}`}
            onClick={(e) => {
              // Cancel regular href behavior, before triggering download.
              const filename: string =
                "Offshore Weather for " +
                report.offshoreData.position_name +
                " valid from " +
                moment(report.qubaData.meta.run)
                  .tz("UTC")
                  .format("DD.MM.YYYY HH zz") +
                " by MET NORWAY.csv"
              e.preventDefault()
              downloadFile({ url: csvURL, filename: filename })
            }}
          >
            <FormattedMessage id="as_CSV" />
          </a>
        </MenuItem>
      </Menu>
    </>
  )
}
