import { createTheme, Theme } from "@mui/material"

/**
 * We can customize components here instead
 * of overriding them every time we use them.
 *
 * See:
 * https://material-ui.com/customization/components/#5-global-theme-variation
 */

export function createLunaTheme() {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#BEB9D7",
        main: "#6c689d",
        dark: "#464769",
      },
      // Using secondary for places where we need to invert
      // colors. Very useful for IconButtons and such because
      // it will calculate light-colored ripple effects.
      secondary: {
        main: "#fff",
      },
      background: {
        default: "#F8F7FD",
      },
      warning: {
        main: "#FFEA9C",
      },
      error: {
        main: "#FF8888",
      },
    },
  })
  return configureBreakPoints(
    configureComponentOverrides(configureTypography(theme))
  )
}

function configureBreakPoints(theme: Theme): Theme {
  return {
    ...theme,
    breakpoints: {
      ...theme.breakpoints,
      values: {
        ...theme.breakpoints.values,
        lg: 1800,
        xl: 1980,
      },
    },
  }
}

function configureComponentOverrides(theme: Theme): Theme {
  return {
    ...theme,
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            whiteSpace: "pre-line",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: "4px",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 700,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: 18,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow:
              "0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 40px rgba(0, 0, 0, 0.05);",
          },
        },
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardError: {
            backgroundColor: "#FFC9C9",
            color: "#555555",
          },
          standardWarning: {
            backgroundColor: "#FFFBEE",
            color: "#555555",
          },
          standardInfo: {
            backgroundColor: "#E6F7FF",
            color: "#555555",
          },
          icon: {
            marginTop: "4px",
          },
        },
      },
    },
  }
}

function configureTypography(theme: Theme): Theme {
  const commonTitleStyles = {
    fontFamily: ["Simplon BP Medium", "sans-serif"].join(","),
    lineHeight: 1.2,
    fontWeight: 500,
  }
  const commonBodyTextStyles = {
    fontFamily: ["Simplon BP Regular", "sans-serif"].join(","),
    fontWeight: 500,
  }
  theme.typography = {
    ...theme.typography,
    ...{
      fontFamily: commonBodyTextStyles.fontFamily,
      fontSize: 16,
    },
    ...{
      // Main content styles.
      body1: {
        ...commonBodyTextStyles,
        fontSize: 16,
        lineHeight: 1.5,
        [theme.breakpoints.up("lg")]: {
          fontSize: 18,
        },
      },
      body2: {
        ...commonBodyTextStyles,
        fontSize: 12,
        lineHeight: 1.5,
        [theme.breakpoints.up("lg")]: {
          fontSize: 18,
        },
      },
      subtitle1: {
        fontSize: 16,
        ...commonTitleStyles,
        [theme.breakpoints.up("lg")]: {
          fontSize: 18,
        },
      },
      subtitle2: {
        fontSize: 14,
        ...commonTitleStyles,
        [theme.breakpoints.up("lg")]: {
          fontSize: 16,
        },
      },
      button: {
        fontSize: 14,
        ...commonTitleStyles,
        [theme.breakpoints.up("lg")]: {
          fontSize: 16,
        },
      },
      caption: {
        ...commonBodyTextStyles,
        fontSize: 12,
        lineHeight: 1.2,
        [theme.breakpoints.up("md")]: {
          fontSize: 14,
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: 16,
        },
      },
      overline: {
        ...commonTitleStyles,
        fontSize: 10,
        textTransform: "uppercase",
        letterSpacing: 0.5,
        [theme.breakpoints.up("md")]: {
          fontSize: 12,
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: 14,
        },
      },
    },
    ...{
      // Title typography
      h1: {
        ...commonTitleStyles,
        fontSize: "28px",
        [theme.breakpoints.up("md")]: {
          fontSize: "36px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "40px",
        },
      },
      h2: {
        ...commonTitleStyles,
        fontSize: "22px",
        [theme.breakpoints.up("md")]: {
          fontSize: "30px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "32px",
        },
      },
      h3: {
        ...commonTitleStyles,
        fontSize: "20px",
        [theme.breakpoints.up("md")]: {
          fontSize: "24px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "26px",
        },
      },
      h4: {
        ...commonTitleStyles,
        fontSize: "18px",
        [theme.breakpoints.up("md")]: {
          fontSize: "20px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "24px",
        },
      },
      h5: {
        ...commonTitleStyles,
        fontSize: "17px",
        [theme.breakpoints.up("md")]: {
          fontSize: "18px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "22px",
        },
      },
      h6: {
        ...commonTitleStyles,
        fontSize: "16px",
        [theme.breakpoints.up("md")]: {
          fontSize: "16px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "18px",
        },
      },
    },
  }
  return theme
}
