import { IntlShape } from "react-intl"

export const drawCardinalDirections = (
  chart: Highcharts.Chart,
  cardinalDirections: React.MutableRefObject<Highcharts.SVGElement[] | null>,
  intl: IntlShape,
  minified = false
) => {
  if (minified) return
  const origo = {
    x: chart.chartWidth / 2,
    y: chart.chartHeight / 2,
  }
  if (Array.isArray(cardinalDirections.current)) {
    cardinalDirections.current.forEach((element) => element?.destroy())
  }
  const southText = chart.renderer
    .text(`${intl.formatMessage({ id: "south" })}`, origo.x, origo.y + origo.y)
    .css({
      fontSize: "14px",
      fontWeight: "bold",
    })
    .add()

  const southBBox = southText.getBBox()
  southText.attr({
    x: origo.x - southBBox.width / 2,
    y: origo.y + origo.y * 0.95,
  })

  const northText = chart.renderer
    .text(`${intl.formatMessage({ id: "north" })}`, origo.x, origo.y - origo.y)
    .css({
      fontSize: "14px",
      fontWeight: "bold",
    })
    .add()

  const northBBox = northText.getBBox()

  northText.attr({
    x: origo.x - northBBox.width / 2,
    y: origo.y - origo.y * 0.95,
  })

  const directions = [
    northText,
    southText,
    chart.renderer
      .text(
        `${intl.formatMessage({ id: "east" })}`,
        origo.x + origo.y * 0.95,
        origo.y
      )
      .css({ fontSize: "14px", fontWeight: "bold" })
      .add(),
    chart.renderer
      .text(
        `${intl.formatMessage({ id: "west" })}`,
        origo.x - origo.y * 1.1,
        origo.y
      )
      .css({ fontSize: "14px", fontWeight: "bold" })
      .add(),
  ]

  cardinalDirections.current = directions as Highcharts.SVGElement[]
}
