import React, { FC, ReactElement } from "react"
import Table from "@mui/material/Table"
import TableContainer from "@mui/material/TableContainer"
import { convertToKnots, convertVisibilityString } from "@luna/luna-core"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material"
import padStart from "lodash/padStart"
import {
  DisabledColumns,
  useDisabledColumns,
  useOffshoreTableCriteriaState,
} from "./OffshoreTable.hook"
import { TimezoneID, useTimezone, useWindUnit } from "@luna/luna-core"
import moment from "moment"
import { useIntl, IntlShape } from "react-intl"
import { OffshoreTableBody } from "./OffshoreTableBody"
import { OffshoreTableHead } from "./OffshoreTableHead"

/**
 * ColumnID defines allowed data IDs in the table.
 * We first list out custom data IDs created by
 * this table before leaning on IDs provided
 * by the Forecast data.
 * */
export type ColumnID =
  /**
   * These types are defined here because we create them
   * dynamically based on the API data.
   */
  | keyof GeneratedTableData
  /**
   * With 'keyof' we ensure that allowed column IDs can
   * only be one's that exist in the data returned by
   * the API.
   */
  | keyof OffshoreForecast["meta"]["units"]

interface GeneratedTableData {
  dateTime: Moment
}

export interface Column {
  id: ColumnID
  label: string
  unit: string | ReactElement
  minWidth?: number
  maxWidth?: number
  deactivateTresholdInput?: boolean
  align?: "center" | "right"
  format?: (value: number) => string
}

export interface ColumnGroupHeader {
  label: string
  colspan?: number
  align?: "center" | "right"
}

export interface ColumnGroup {
  header: ColumnGroupHeader
  columns: Column[]
}

export const formatDegrees = (degree: number): string => {
  if (degree === 0) {
    degree = 360
  }

  return padStart(degree.toFixed(0), 3, "0")
}

export const formatDecimals = (value: number, precision: number): string => {
  var multiplier = Math.pow(10, precision || 0)

  return (Math.round(value * multiplier) / multiplier).toFixed(precision)
}

export const configureColumnGroups = ({
  report,
  currentTimezone,
  intl,
  windUnit,
}: {
  report: OffshoreReportData
  currentTimezone: TimezoneID
  intl: IntlShape
  windUnit: any
}): ColumnGroup[] => {
  const getIdAndUnit = (id: keyof OffshoreForecast["meta"]["units"]) => {
    let unit = report.qubaData.meta.units[id]

    if (unit === "m/s" && windUnit === "KT") {
      unit = intl.formatMessage({ id: "wind_unit" })
    }
    if (unit === "deg") {
      unit = intl.formatMessage({ id: "direction_unit" })
    }

    return { id, unit: unit ? `(${unit})` : "" }
  }

  let columnGroup: ColumnGroup[] = [
    {
      header: {
        label: intl.formatMessage({ id: "date_time" }),
      },
      columns: [
        {
          id: "dateTime",
          align: "center",

          unit: <div>({moment().tz(currentTimezone).format("zz")})</div>,
          label: intl.formatMessage({ id: "time_zone" }),

          format: (date: any) =>
            date.locale(intl.locale).tz(currentTimezone).format("DD.MM HH:00"),
        },
      ],
    },
    {
      header: {
        label: intl.formatMessage({ id: "wind" }) + " 10m",
        colspan: 3,
      },
      columns: [
        {
          ...getIdAndUnit("wind_from_direction"),
          label: intl.formatMessage({ id: "direction" }),
          align: "center",
          format: formatDegrees,
        },
        {
          ...getIdAndUnit("wind_speed_10_meter"),
          label: intl.formatMessage({ id: "speed" }),
          align: "center",

          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("wind_speed_of_gust_10_meter"),
          label: intl.formatMessage({ id: "gust" }),
          align: "center",
          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
      ],
    },
    {
      header: {
        label: intl.formatMessage({ id: "wind" }) + " 50m",
        colspan: 2,
      },
      columns: [
        {
          ...getIdAndUnit("wind_speed_50_meter"),
          label: intl.formatMessage({ id: "speed" }),
          align: "center",
          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("wind_speed_of_gust_50_meter"),
          label: intl.formatMessage({ id: "gust" }),
          align: "center",
          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
      ],
    },
  ]

  getIdAndUnit("wind_speed_100_meter").unit &&
    columnGroup.push({
      header: {
        label: intl.formatMessage({ id: "wind" }) + " 100m",
        colspan: 1,
      },
      columns: [
        {
          ...getIdAndUnit("wind_from_direction_100_meter"),
          label: intl.formatMessage({ id: "direction" }),
          align: "center",
          format: formatDegrees,
        },
        {
          ...getIdAndUnit("wind_speed_100_meter"),
          label: intl.formatMessage({ id: "speed" }),
          align: "center",
          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("wind_speed_of_gust_100_meter"),
          label: intl.formatMessage({ id: "gust" }),
          align: "center",
          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
      ],
    })

  getIdAndUnit("wind_speed_150_meter").unit &&
    columnGroup.push({
      header: {
        label: intl.formatMessage({ id: "wind" }) + " 150m",
        colspan: 1,
      },
      columns: [
        {
          ...getIdAndUnit("wind_from_direction_150_meter"),
          label: intl.formatMessage({ id: "direction" }),
          align: "center",
          format: formatDegrees,
        },
        {
          ...getIdAndUnit("wind_speed_150_meter"),
          label: intl.formatMessage({ id: "speed" }),
          align: "center",
          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("wind_speed_of_gust_150_meter"),
          label: intl.formatMessage({ id: "gust" }),
          align: "center",
          format: (val) =>
            windUnit === "KT"
              ? formatDecimals(convertToKnots({ metersPerSecond: val }), 0)
              : formatDecimals(val, 1),
        },
      ],
    })

  columnGroup.push(
    {
      header: { label: intl.formatMessage({ id: "weather_temp" }), colspan: 5 },
      columns: [
        {
          ...getIdAndUnit("air_pressure_at_sea_level"),
          label: "MSLP",
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("visibility"),
          unit: "[km]",
          label: intl.formatMessage({ id: "visibility" }),
          align: "center",
          format: (val) => convertVisibilityString(val),
          deactivateTresholdInput: true,
        },
        {
          ...getIdAndUnit("air_temperature"),
          label: intl.formatMessage({ id: "air" }),
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("dew_point_temperature"),
          label: intl.formatMessage({ id: "dew_point" }),
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("sea_surface_temperature"),
          label: intl.formatMessage({ id: "sea" }),
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
      ],
    },
    {
      header: { label: intl.formatMessage({ id: "comb_sea" }), colspan: 5 },
      columns: [
        {
          ...getIdAndUnit("sea_surface_wave_to_direction"),
          label: intl.formatMessage({ id: "direction" }),
          align: "center",
          format: formatDegrees,
        },
        {
          ...getIdAndUnit("sea_surface_wave_significant_height"),
          label: "Hs",
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("sea_surface_maximum_wave_height"),
          label: "Hmax",
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("sea_surface_wave_mean_period_TM02"),
          align: "center",
          label: "Tm02",
          format: (val) => formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("sea_surface_wave_peak_period"),
          label: "Tp",
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
      ],
    },
    {
      header: { label: intl.formatMessage({ id: "swell" }), colspan: 3 },
      columns: [
        {
          ...getIdAndUnit("sea_surface_swell_wave_to_direction"),
          label: intl.formatMessage({ id: "direction" }),
          align: "center",
          format: formatDegrees,
        },
        {
          ...getIdAndUnit("sea_surface_significant_swell_wave_height"),
          label: intl.formatMessage({ id: "height" }),
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
        {
          ...getIdAndUnit("sea_surface_swell_wave_period"),
          label: "Tp",
          align: "center",
          format: (val) => formatDecimals(val, 1),
        },
      ],
    },

    {
      header: { label: intl.formatMessage({ id: "ocean" }), colspan: 1 },
      columns: [
        {
          ...getIdAndUnit("sea_surface_elevation"),
          label: intl.formatMessage({ id: "water_level" }),
          align: "center",
          format: (val) => formatDecimals(val, 2),
        },
      ],
    }
  )
  return columnGroup
}

/**
 * We unpack and grab the Forecast typing data defined in OffshoreForecast.d.ts
 */
type Unpack<T> = T extends (infer U)[] ? U : never
type OffshoreData = Unpack<OffshoreForecast["forecast"]>["data"]
export type RowData = OffshoreData & GeneratedTableData

export const configureRows = (report: OffshoreReportData): RowData[] => {
  return report.qubaData.forecast.map((f) => {
    return {
      ...(f.data as OFFSHORE_DATA_ELEMENTS),
      dateTime: f.valid,
    }
  })
}

const OffshoreTable: FC<{
  report: OffshoreReportData
  showLegendFilter?: boolean
}> = ({ report, showLegendFilter = false }) => {
  const intl = useIntl()
  const { windUnit } = useWindUnit()
  const { currentTimezone } = useTimezone()
  const columnGroupsNotFiltered = configureColumnGroups({
    report,
    currentTimezone,
    intl,
    windUnit,
  })
  const rows = configureRows(report)
  const { activeCriteria, setActiveCriteria } = useOffshoreTableCriteriaState()

  const { disabledColumns, setDisabledColumns } = useDisabledColumns()

  const columnGroupsFiltered = columnGroupsNotFiltered.reduce<ColumnGroup[]>(
    (acc, group, groupIndex) => {
      const isTimeGroup = groupIndex === 0
      if (isTimeGroup) {
        return [...acc, group]
      }

      const enabledGroupColumns = group.columns.filter(
        (column) => !disabledColumns[column.id]
      )
      if (enabledGroupColumns.length === 0) {
        return acc
      }
      return [...acc, { ...group, columns: enabledGroupColumns }]
    },
    []
  )

  return (
    <div>
      {showLegendFilter && (
        <LegendFilter
          columnGroups={columnGroupsNotFiltered}
          disabledColumns={disabledColumns}
          setDisabledColumns={setDisabledColumns}
        />
      )}
      <TableContainer
        sx={{
          backgroundColor: "white",
          height: "80vh",
        }}
      >
        <Typography component="div" variant="body1">
          <Table aria-label="sticky table" sx={{ borderCollapse: "separate" }}>
            <OffshoreTableHead
              columnGroups={columnGroupsFiltered}
              activeCriteria={activeCriteria}
              setActiveCriteria={setActiveCriteria}
            />
            <OffshoreTableBody
              rows={rows}
              currentTimezone={currentTimezone}
              columnGroups={columnGroupsFiltered}
              activeCriteria={activeCriteria}
            />
          </Table>
        </Typography>
      </TableContainer>
    </div>
  )
}

export default OffshoreTable

const LegendFilter: FC<{
  columnGroups: ColumnGroup[]
  disabledColumns: DisabledColumns
  setDisabledColumns: (disabledColumns: DisabledColumns) => void
}> = ({ columnGroups, disabledColumns, setDisabledColumns }) => {
  return (
    <Paper elevation={0} sx={{ py: 4 }}>
      <Grid container justifyContent="space-around">
        {columnGroups.map((group, groupIndex) => {
          const isTimeGroup = groupIndex === 0
          if (isTimeGroup) {
            return null
          }
          return (
            <FormControl key={group.header.label}>
              <FormLabel sx={{ color: "black" }}>
                <strong>{group.header.label}</strong>
              </FormLabel>
              <FormGroup>
                {group.columns.map((parameter) => (
                  <FormControlLabel
                    key={parameter.id}
                    control={
                      <Checkbox
                        sx={{ py: 0 }}
                        size="small"
                        color="default"
                        checked={!disabledColumns[parameter.id]}
                        name={parameter.id}
                        onChange={() => {
                          const prevDisabled = disabledColumns[parameter.id]
                          setDisabledColumns({
                            ...disabledColumns,
                            [parameter.id]: !prevDisabled,
                          })
                        }}
                      />
                    }
                    label={parameter.label}
                  />
                ))}
              </FormGroup>
            </FormControl>
          )
        })}
      </Grid>
    </Paper>
  )
}
