import { Box, Container } from "@mui/material"
import { MarkdownLoader } from "components/MarkdownLoader"
import { useIntl } from "react-intl"

export const OffshoreMenuExplanation: React.FC = () => {
  const { locale } = useIntl()

  return (
    <Container maxWidth={"xl"}>
      <Box paddingLeft={2} paddingBottom={3}>
        <MarkdownLoader url={`/markdown/offshore-explanation-${locale}.md`} />
      </Box>
    </Container>
  )
}
