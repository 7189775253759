import { Grid, Theme, Typography, Hidden } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react"
import { FormattedMessage } from "react-intl"
import moment from "moment"
import { useTimezone } from "@luna/luna-core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxHeader: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    boxContent: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
  })
)

interface Props {
  color: string
  time: string
}

const NextForecastTime: React.FC<Props> = ({ color, time }) => {
  const classes = useStyles()
  const { currentTimezone } = useTimezone()
  if (color.toUpperCase() === "HVITT") {
    return (
      <>
        <Grid item xs={12} lg={12}>
          <Typography className={classes.boxHeader}>
            <FormattedMessage id="next_warning_ended" />
          </Typography>
        </Grid>
      </>
    )
  }
  return (
    <>
      <Hidden only={["xs", "sm", "lg", "xl"]}>
        <Grid item xs={6} md={4} lg={6}>
          <Typography variant="body2">
            <FormattedMessage id="next_warning" />
          </Typography>
        </Grid>
        <Grid item xs={6} md={8} lg={6}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {moment(time).tz(currentTimezone).format("DD.MM.YYYY HH:mm zz")}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden only={["md"]}>
        <Grid item xs={6} md={4} lg={6}>
          <Typography variant="body1">
            <FormattedMessage id="next_warning" />
          </Typography>
        </Grid>
        <Grid item xs={6} md={8} lg={6}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            {moment(time).tz(currentTimezone).format("DD.MM.YYYY HH:mm zz")}
          </Typography>
        </Grid>
      </Hidden>
    </>
  )
}

export default NextForecastTime
