export const convertToKnots = ({
  metersPerSecond,
}: {
  metersPerSecond: number
}): number => {
  // one knot is 1852m/h.
  const knotConversionFactor = 3600 / 1852
  return metersPerSecond * knotConversionFactor
}
export const convertToMetersPerSecond = (knots: number): number => {
  const metersPerSecond = knots * 0.514444444
  return roundToZeroDecimals(metersPerSecond)
}

export const roundToZeroDecimals = (num: number): number =>
  Number.parseFloat((Math.round((num * 1000) / 10) / 100).toFixed(0))

export const roundToDecimals = (value: number, precision: number): number => {
  var multiplier = Math.pow(10, precision || 0)
  var num = Number.parseFloat(
    (Math.round(value * multiplier) / multiplier).toFixed(precision)
  )

  return num
}
export const convertVisibilityString = (num: number): string => {
  let returnStr = ""

  if (num === 11.0) {
    returnStr = "10+"
  } else if (num === 10.0) {
    returnStr = "6-10"
  } else if (num === 9.0) {
    returnStr = "4-10"
  } else if (num === 8.0) {
    returnStr = "4-10"
  } else if (num === 7.0) {
    returnStr = "3-8"
  } else if (num === 6.0) {
    returnStr = "1-4"
  } else if (num === 5.0) {
    returnStr = "1-4"
  } else if (num === 4.0) {
    returnStr = "0.5-2"
  } else if (num === 3.0) {
    returnStr = "0.5-2"
  } else if (num === 2.0) {
    returnStr = "0.4"
  } else if (num === 1.0) {
    returnStr = "0-1"
  } else {
    returnStr = String(num)
  }
  return returnStr
}
