import { Paper, Box, Grid, InputLabel, Typography } from "@mui/material"
import { createEnumParam, useQueryParams, withDefault } from "use-query-params"
import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useMapDetails } from "./PrecipitationMap.service"
import RadioButtons from "../RadioButtons"
import { useMemo } from "react"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { FullscreenDialog } from "components/FullscreenDialog"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { useIntl } from "react-intl"

export const PrecipitationMap = () => {
  const [query, setQuery] = useQueryParams({
    provider: withDefault(createEnumParam(["ECMWF", "AROME"]), "ECMWF"),
  })
  const intl = useIntl()
  const provider = query.provider as "ECMWF" | "AROME"

  const { mapType } = useMapDetails({ provider })
  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  const mapTitle = useMemo(() => {
    return `${
      provider === "ECMWF"
        ? intl.formatMessage({
            id: "map_precip_title_ec",
          })
        : intl.formatMessage({
            id: "map_precip_title_arome",
          })
    }`
  }, [provider, intl])

  const mapSubTitle = useMemo(() => {
    return `${provider}: ${
      provider === "ECMWF"
        ? intl.formatMessage({
            id: "map_precip_subtitle_ec",
          })
        : intl.formatMessage({
            id: "map_precip_subtitle_arome",
          })
    }`
  }, [provider, intl])

  return (
    <>
      <Box pb={2}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>

          <Grid item>
            <Grid item container alignItems={"center"} spacing={2}>
              <Grid item>
                <InputLabel id="select-map-label">
                  {intl.formatMessage({ id: "show_model" })}:
                </InputLabel>
              </Grid>
              <Grid item>
                <RadioButtons
                  buttons={[
                    {
                      value: "AROME",
                      label: `Arome - ${intl.formatMessage({
                        id: "short_term",
                      })}`,
                    },
                    {
                      value: "ECMWF",
                      label: `ECMWF - ${intl.formatMessage({
                        id: "long_term",
                      })}`,
                    },
                  ]}
                  onChange={(value: any) =>
                    setQuery({ provider: value }, "replaceIn")
                  }
                  selected={provider}
                />
              </Grid>
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID="fullscreen-map"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">{mapSubTitle}</Typography>
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return <img alt="kart over temperatur" src={selectedImage} />
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>

          <Grid item xs={5}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_explanation",
                })}
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_precip_exp_text",
                })}
                .
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                {intl.formatMessage({
                  id: "map_precipitation",
                })}
                :
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_precip_exp_text_precip",
                })}
                .
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                {intl.formatMessage({
                  id: "map_pressure",
                })}
                :
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_precip_exp_text_pressure",
                })}
                .
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
