import { Button } from "@mui/material"

type Props = Parameters<typeof Button>[0]

/**
 * IconButton with a border.
 */
export const BorderedIconButton: React.FC<Props> = (props) => {
  return (
    <Button
      {...props}
      variant="outlined"
      color="primary"
      sx={{
        aspectRatio: "1 / 1",
        minWidth: "43px",
        padding: "0",
      }}
    />
  )
}
