import { useState, useEffect } from "react"
import { TimezoneID } from "./timezoneContext"
import messages_en from "./translations/messages_en.json"
import messages_nb from "./translations/messages_nb.json"
import moment from "moment"
import "moment-timezone"
import "moment/locale/nb"
import {
  loadStringFromLocalStorage,
  addLocalStoragePersistence,
} from "./localStorageService"
import { WindUnit } from "./WindUnitContext"

export const useAppTimezone = () => {
  useEffect(() => {
    /**
     * Override timezone names so that we can use 'zz' like
     * moment.format('MM dd YY zz) to show LT instead of CET.
     * See also: https://momentjs.com/timezone/docs/#/using-timezones/formatting/
     */
    moment.fn.zoneName = function () {
      const abbrs = {
        CET: "LT",
        CEST: "LT",
      } as any
      const abbr = this.zoneAbbr() as any
      return abbrs[abbr] || abbr
    }
  }, [])
  const key = "LunaTimezone"
  const [currentTimezone, setCurrentTimezone] = useState<TimezoneID>(
    loadStringFromLocalStorage<TimezoneID>({
      key,
      returnIfNull: "CET",
    })
  )
  return {
    currentTimezone,
    setCurrentTimezone: addLocalStoragePersistence({
      key,
      setStateFunc: setCurrentTimezone,
    }),
  }
}

export type LunaLocale = "en" | "nb"

type LocalizedMessages = {
  [K in LunaLocale]: Record<string, string>
}

const messages: LocalizedMessages = {
  nb: messages_nb,
  en: messages_en,
}

export const useAppLocale = () => {
  const key = "LunaLocale"
  const [locale, setLocale] = useState<LunaLocale>(
    loadStringFromLocalStorage<LunaLocale>({
      key,
      returnIfNull: "en",
    })
  )
  const handleLocaleChange = () => {
    const newLocale: LunaLocale = locale === "en" ? "nb" : "en"
    setLocale(newLocale)
    localStorage.setItem(key, newLocale)
    window.document.documentElement.lang = newLocale
  }
  return { locale, messages, handleLocaleChange }
}

export const useAppWindUnit = () => {
  const key = "LunaWindUnit"
  const [windUnit, setWindUnit] = useState<WindUnit>(
    loadStringFromLocalStorage<WindUnit>({ key, returnIfNull: "KT" })
  )

  const handleWindUnitChange = (windUnit: WindUnit) => {
    setWindUnit(windUnit)
    localStorage.setItem(key, windUnit)
  }

  return { windUnit, handleWindUnitChange }
}
