import React from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import SpecialForecastListRow from "./SpecialForecastListRow"
import moment from "moment"
import { useTimezone } from "@luna/luna-core"

export interface SpecialForecastValues {
  uri: string
  name: string
  update_time: string
  alertness_level: string
}

interface SpecialForecastProps {
  forecasts: SpecialForecastValues[]
}

const SpecialForecastOverview: React.FC<SpecialForecastProps> = ({
  forecasts,
}) => {
  const intl = useIntl()
  const { currentTimezone } = useTimezone()
  const theme = useTheme()

  return (
    <Grid container sx={{ backgroundColor: theme.palette.background.default }}>
      <Grid
        container
        sx={{ marginBottom: theme.spacing(2) }}
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <Typography variant={"h1"}>
            <FormattedMessage id="special_forecast" />
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {forecasts.map((row) => (
          <SpecialForecastListRow
            props={{
              title: intl.formatMessage({ id: row.name }),
              uri: row.uri,
              datetime:
                intl.formatMessage({ id: "updated" }) +
                ": " +
                moment(row.update_time)
                  .tz(currentTimezone)
                  .format("DD.MM.YYYY HH:mm zz"),
              alertColor: row.alertness_level,
            }}
            key={row.uri}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default SpecialForecastOverview
