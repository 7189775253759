import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material"
import React, { useEffect, useState } from "react"

interface Props {
  currentAppVersion?: string
  getAppVersion?: () => Promise<string>
}

const getAppversionDefault = () =>
  fetch("/static/version.txt", {
    headers: {
      "Cache-Control": "no-cache",
    },
  })
    .then((res) => res.text())
    .then((version) => {
      if (version.toLocaleLowerCase().startsWith("<!doctype html>")) {
        // Guess that it was a 404 and we got redirected back to homepage.
        return ""
      }
      // Extract git-info, remove any new-lines.
      const result = /(\w|\d)*/gi.exec(version)
      if (!result) {
        return ""
      }
      return result[0]
    })

/**
 * This component periodically compares the version
 * of the running code with the version returned
 * from polling the backend server. The goal is to
 * ensure that we don't have any applications out
 * there with old versions running.
 */
const AppVersionChecker: React.FC<Props> = ({
  currentAppVersion = process.env.REACT_APP_COMMIT_ID,
  getAppVersion = getAppversionDefault,
}) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (!currentAppVersion) {
      return // currentAppVersion was not defined. Exiting.
    }
    const checkVersion = async () => {
      if (open) {
        return
      }
      const version = await getAppVersion()
      if (!version) {
        return
      }
      if (currentAppVersion !== version) {
        setOpen(true)
      }
    }
    // Check version immediately
    checkVersion()
    const timerID = setInterval(checkVersion, 1000 * 60 * 15)
    return () => clearInterval(timerID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Oppdatering tilgjengelig
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          En ny versjon av denne applikasjonen er tilgjengelig.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Avbryt
        </Button>
        <Button
          onClick={() => window.location.reload()}
          color="primary"
          autoFocus
        >
          Oppdater
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppVersionChecker
