import React, { useEffect } from "react"
import { getActiveOffshoreForecasts } from "services/offshore.service"

type Props = {
  autoRefresh?: true
}

export const useOffshoreSummary = (props?: Props) => {
  const { autoRefresh } = props || {}
  const [offshoreSummary, setOffshoreSummary] = React.useState<
    ActiveForecastSummaryList | undefined
  >()

  useEffect(() => {
    const activeForecastData = () =>
      getActiveOffshoreForecasts()
        .then((data) => {
          setOffshoreSummary(data)
        })
        .catch((err: Error) => {
          const message = "Failed to load active offshore forecasts"
          console.log(message, err)
          // Re-throw error so it's caught by <ErrorBoundary />
          throw new Error(message)
        })
    activeForecastData()

    if (!autoRefresh) {
      return
    }

    const intervalID = setInterval(activeForecastData, 1000 * 60 * 10) // ten minute intervals.
    return () => {
      clearInterval(intervalID)
    }
  }, [autoRefresh])

  return { offshoreSummary }
}
