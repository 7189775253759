import { AvailableMapFileID } from "./AvailableMaps.service"

const modelStartValues = {
  ECMWF: { hour: 6, minute: 0, second: 0 },
  AROME: { hour: 6, minute: 0, second: 0 },
}

const timeIncrements = {
  ECMWF: { hour: 6 },
  AROME: { hour: 6 },
}
export const useMapDetails = ({
  provider,
}: {
  provider: "ECMWF" | "AROME"
}) => {
  const mapType: AvailableMapFileID = provider === "ECMWF" ? "ec_tt" : "meps_tt"
  return {
    mapType,
    modelStart: modelStartValues[provider],
    timeIncrement: timeIncrements[provider],
  }
}
