import { useKeycloak } from "@react-keycloak/web"
import { KeycloakProfile } from "keycloak-js"
import { useState, useEffect } from "react"

/**
 * UserLoader is a higher-order component that
 * loads the Logged in user from Keycloak and calls the
 * children prop as a function with the user data.
 */
export const UserLoader: React.FC = ({ children }) => {
  const [user, setUser] = useState<KeycloakProfile>()
  const { keycloak } = useKeycloak()
  useEffect(() => {
    keycloak?.loadUserProfile().then((user: KeycloakProfile) => {
      setUser(user)
    })
  }, [setUser, keycloak])

  if (!user) {
    return null
  }
  if (typeof children === "function") {
    return children(user)
  }
  return <>{children}</>
}
