import React from "react"
import { TableRow, TableCell, useTheme } from "@mui/material"

interface Props {
  totalColumns: number
}

const MetTableRowDateDivider: React.FC<Props> = ({
  children,
  totalColumns,
}) => {
  const theme = useTheme()
  const restOfCells = []
  for (let index = 0; index < totalColumns - 1; index++) {
    restOfCells.push(<TableCell key={index} />)
  }
  return (
    <TableRow
      sx={{
        backgroundColor: theme.palette.grey[800],
        "& .MuiTableCell-root": {
          color: theme.palette.common.white,
          whiteSpace: "nowrap",
        },
      }}
    >
      <TableCell sx={{ position: "sticky", left: 0, zIndex: 2 }}>
        {children}
      </TableCell>
      {restOfCells}
    </TableRow>
  )
}

export default MetTableRowDateDivider
