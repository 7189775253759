import { IntlShape } from "react-intl"
import cloneDeep from "lodash/cloneDeep"
import capitalize from "lodash/capitalize"
import padStart from "lodash/padStart"

import {
  Chart,
  XAxisPlotLinesLabelOptions,
  XAxisPlotLinesOptions,
  PlotWindbarbOptions,
  SeriesLineOptions,
  YAxisOptions,
} from "highcharts"
import {
  roundToDecimals,
  TimezoneID,
  loadJSONFromLocalStorage,
  saveInLocalStorage,
} from "@luna/luna-core"
import * as Moment from "moment"
import { extendMoment } from "moment-range"

const moment = extendMoment(Moment)

let acc_prep: number = 0

const lunaColors = {
  almostBlack: "#323232",
  veryLight: "rgba(96, 98, 113, 0.2)",
  transparent: "rgba(255,255,255,0 )",

  air_temperature: "#FF0000",
  air_temperature_minus: "#0d78c6",
  dew_point_temperature: "#FFBA53",

  wind_10m: "#000000",
  wind_50m: "#9F9F9F",
  gust_speed_10_meter: "#616161",
  gust_speed_50_meter: "#D1D1D1",
  pressure: "#6DE879",
  relative_humidity: "purple",
  precipitation: "#0051F0",
  precipitation_acc: "#CEEFFF",

  plotLine: "#E0E0E0",
}

export type SeriesVisibility = {
  air_temperature: boolean
  wind_speed_10_meter: boolean
  air_pressure_at_sea_level: boolean
  weather_symbol: boolean
  dew_point_temperature: boolean
  relative_humidity: boolean
  precipitation_amount: boolean
  precipitation_acc: boolean
}

type Series = keyof SeriesVisibility

const defaultSeries: SeriesVisibility = {
  air_temperature: true,
  dew_point_temperature: false,
  wind_speed_10_meter: true,
  air_pressure_at_sea_level: false,
  weather_symbol: false,
  relative_humidity: false,
  precipitation_amount: true,
  precipitation_acc: true,
}

const yAxis = {
  wind: 0,
  temp: 1,
  pressure: 2,
  relative_humidity: 3,
  precipitation: 4,
  symbol: 5,
}

export type OverrideVisibilityOptions = Partial<SeriesVisibility> | true

type ChartBasis = {
  overrideVisibleSeries?: OverrideVisibilityOptions
  forecast: LocationForecastData
  intl: IntlShape
  currentTimezone: TimezoneID
}

let getSeriesVisibility: (series: Series) => boolean

export const calculateChartOptions = (
  chartBasis: ChartBasis
): Highcharts.Options => {
  const { forecast, intl, currentTimezone, overrideVisibleSeries } = chartBasis

  getSeriesVisibility = initialiseSeriesVisibilityFunc(overrideVisibleSeries)

  const timeseries = forecast.properties.timeseries

  return {
    credits: {
      enabled: false,
    },

    navigator: {
      enabled: false,
    },

    rangeSelector: {
      enabled: false,
    },

    chart: {
      height: 900,
      marginBottom: 300,
      marginTop: 20,

      zooming: {
        type: "x",
        mouseWheel: {
          enabled: false,
        },
      },

      style: {
        fontFamily: `"Simplon BP Regular", "sans-serif"`,
        fontSize: "14px",
      },
      events: {
        render: function (event: Event) {
          /**
           * For every chart render/update we place a copy
           * of the chart in the window object so that
           */
          window.exwwChart = cloneDeep(this) as Chart
        },
      },
    },
    tooltip: {
      shared: true,
      style: {
        fontSize: "16px",
      },
      headerFormat: `<span style="font-size: 16px"><strong>{point.key}</strong></span><br/>`,
    },

    title: {
      text: "",
    },
    legend: {
      enabled: true,
      itemStyle: {
        fontSize: "14px",
        textDecoration: "none",
      },
      itemHiddenStyle: {
        color: "#808080",
      },
      itemHoverStyle: {
        textDecoration: "underline",
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
        events: {
          legendItemClick: (event) => {
            //if (overrideVisibleSeries) {
            //  // Don't let users interact with legends if overriden.
            //  return event.preventDefault()
            //}
            saveSeriesVisibility(
              event.target.options.id as Series,
              event.target.visible
            )
            maybeUpdateTemperatureYAxis(event.target.chart)
          },
        },
        showInNavigator: false,
      },
    },

    xAxis: {
      type: "datetime",
      offset: 0,
      dateTimeLabelFormats: {
        hour: "%H",
        day: "%H",
      },
      tickPixelInterval: 50,
      crosshair: {
        width: 3,
      },
      tickLength: 0,
      showLastLabel: true,
      min: timeseries[0].time.valueOf(),
      labels: {
        autoRotation: undefined,
        style: {
          fontSize: "16px",
          color: lunaColors.almostBlack,
        },
        y: 50,
        formatter: function () {
          const { isFirst } = this as any
          const tzName = timeseries[0].time
            .clone()
            .tz(currentTimezone)
            .format("zz")
          return isFirst
            ? `${tzName}:`
            : this.axis.defaultLabelFormatter.call(this)
        },
      },
      plotLines: [
        generatePlotlineLabel({
          forecast,
          labelOptions: {
            align: "left",
            x: -10,
            y: 490,
            text: `<div>${intl.formatMessage({ id: "wind" })} 10m</div>`,
          },
        }),
      ],
      plotBands: [...plotDayBands(chartBasis)],
    },
    yAxis: [
      {
        startOnTick: true,
        tickInterval: 5,
        top: "85%",
        height: "15%",
        offset: 0,

        title: {
          text: `${intl.formatMessage({ id: "wind_speed" })} [m/s]`,

          style: {
            color: lunaColors.wind_10m,
            fontWeight: "bold",
            fontSize: "18px",
          },
        },

        plotLines: [
          {
            width: 2,
            color: lunaColors.wind_10m,
            dashStyle: "LongDash",
            zIndex: 3,
          },
        ],
        labels: {
          format: "{value} m/s",
          style: {
            color: lunaColors.wind_10m,
            fontSize: "16px",
          },
        },
        opposite: false,
      },
      {
        id: "air-temperature-series",
        startOnTick: true,
        tickInterval: 2.5,
        height: "80%",
        labels: {
          format: "{value} °C",
          x: getTemperatureYAxisOffset(),
          style: {
            color: lunaColors.air_temperature,
            fontSize: "16px",
          },
        },
        opposite: false,
      },
      {
        startOnTick: true,
        height: "80%",

        labels: {
          format: "{value} hPa",
          style: {
            color: lunaColors.pressure,
            fontSize: "16px",
          },
        },
        opposite: true,
      },
      {
        startOnTick: true,
        tickInterval: 5,
        height: "80%",

        labels: {
          format: "{value} %",
          style: {
            color: lunaColors.relative_humidity,
            fontSize: "16px",
          },
        },
        opposite: true,
      },
      {
        startOnTick: true,
        height: "80%",

        labels: {
          format: "{value} mm",
          style: {
            color: lunaColors.precipitation,
            fontSize: "16px",
          },
        },
        opposite: true,
      },

      {
        height: "80%",
        offset: 10,
        visible: false,
        startOnTick: false,
        max: 1,
        min: 0,
      },
    ],

    exporting: {
      buttons: {
        contextButton: { enabled: false },
      },
    },
    series: [
      ...getNormalizedPlots(chartBasis),
      ...getPrecipitationPlots(chartBasis),
      ...getWindPlots(chartBasis),
      ...getTemperaturePlots(chartBasis),
      ...getPressurePlots(chartBasis),
      ...getRelativHumidityPlots(chartBasis),
      ...getWeatherSymbolPlots(chartBasis),
      ...getAccPrecipitationPlots(chartBasis),
    ] as Highcharts.SeriesOptionsType[],
  }
}

/**
 * Create invisible always-active line to ensure that each day
 * looks to have the same length in the graph.
 */
const getNormalizedPlots = ({
  forecast,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  const range = moment.range(
    forecast.properties.timeseries[0].time,
    forecast.properties.timeseries[forecast.properties.timeseries.length - 1]
      .time
  )
  const normalizedRange = Array.from(range.by("hour", { step: 1 }))
  return [
    {
      type: "line",
      showInLegend: false,
      visible: true,
      lineWidth: 0,
      color: lunaColors.transparent,
      enableMouseTracking: false,
      name: "normalized dates",
      id: "normalized_dates",
      dataGrouping: {
        enabled: false,
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
      data: normalizedRange.map((date) => {
        return {
          x: date.valueOf(),
        }
      }),
    },
  ]
}

const getPressurePlots = ({
  forecast,
  intl,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  return [
    {
      type: "line",
      yAxis: yAxis.pressure,
      color: lunaColors.pressure,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("air_pressure_at_sea_level"),

      name: intl.formatMessage({ id: "pressure" }),
      id: "air_pressure_at_sea_level",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            options: { y },
          } = this as any
          return `
                <span style="color:${color}">\u25CF</span>
                MSLP: <b>${roundToDecimals(y, 1)} hPa</b><br/>`
        },
      },
      lineWidth: 3,
      data: forecast.properties.timeseries.map(({ time, data }) => {
        return [
          time.valueOf(),
          data.instant.details["air_pressure_at_sea_level"],
        ]
      }),
    },
  ]
}

const getWindPlots = ({
  forecast,
  intl,
}: ChartBasis): Array<PlotWindbarbOptions | SeriesLineOptions> => {
  return [
    {
      type: "windbarb",
      yOffset: 20,
      name: intl.formatMessage({ id: "wind_symbol" }),
      id: "wind",
      showInLegend: true,
      tooltip: {
        pointFormatter: function () {
          return ""
        },
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
      color: lunaColors.wind_10m,
      vectorLength: 20,
      lineWidth: 1.5,
      dataGrouping: {
        enabled: true,
        groupPixelWidth: 100,
      },
      data: forecast.properties.timeseries.map(({ time, data }) => [
        time.valueOf(),
        data.instant.details["wind_speed"],
        data.instant.details["wind_from_direction"],
      ]),
      enableMouseTracking: false,
    } as PlotWindbarbOptions,
    {
      type: "line",
      name: intl.formatMessage({ id: "wind_speed_10m" }),
      id: "wind_speed_10_meter",
      yAxis: yAxis.wind,
      color: lunaColors.wind_10m,
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            series: { name },
            options: { y: wind, direction },
          } = this as any
          const paddedDirection = padStart(direction, 3, "0")
          return `
                <span style="color:${color}">\u25CF</span>
                ${name}: <b>${paddedDirection}° / ${wind} m/s</b>
                <br/>`
        },
      },
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("wind_speed_10_meter"),
      data: forecast.properties.timeseries.map(({ time, data }) => ({
        x: time.valueOf(),
        y: roundToZeroDecimals(data.instant.details["wind_speed"]),
        direction: roundToZeroDecimals(
          data.instant.details["wind_from_direction"]
        ),
      })),
    },
  ]
}

const getTemperaturePlots = ({
  forecast,
  intl,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  return [
    {
      type: "line",
      yAxis: yAxis.temp,

      color: lunaColors.air_temperature,
      negativeColor: lunaColors.air_temperature_minus,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("air_temperature"),

      name: intl.formatMessage({ id: "air_temperature" }),
      id: "air_temperature",
      /*
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            options: { y },
          } = this as any
          const name = intl.formatMessage({ id: "air_temperature_short" })
          const temperature = roundToDecimals(y, 1)
          return `
                <span style="color:${color}">\u25CF</span>
                ${name}: <b>${temperature} °C</b><br/>`
        },
      },
      */
      lineWidth: 3,
      data: forecast.properties.timeseries.map(({ time, data }) => {
        return [time.valueOf(), data.instant.details["air_temperature"]]
      }),
    },
    {
      type: "line",
      yAxis: yAxis.temp,
      color: lunaColors.dew_point_temperature,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("dew_point_temperature"),
      name: intl.formatMessage({ id: "dew_point_temperature" }),
      id: "dew_point_temperature",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            options: { y },
          } = this as any
          const name = intl.formatMessage({ id: "dew_point_temperature_short" })
          const temperature = roundToDecimals(y, 1)
          return `
                <span style="color:${color}">\u25CF</span>
                ${name}: <b>${temperature} °C</b><br/>`
        },
      },
      lineWidth: 3,
      data: forecast.properties.timeseries.map(({ time, data }) => {
        return [time.valueOf(), data.instant.details["dew_point_temperature"]]
      }),
    },
  ]
}

const getRelativHumidityPlots = ({
  forecast,
  intl,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  return [
    {
      type: "line",
      yAxis: yAxis.relative_humidity,
      color: lunaColors.relative_humidity,
      marker: {
        enabled: false,
      },
      visible: getSeriesVisibility("relative_humidity"),

      name: intl.formatMessage({ id: "relative_humidity" }),
      id: "relative_humidity",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            options: { y },
          } = this as any
          const name = intl.formatMessage({ id: "relative_humidity" })
          return `
                <span style="color:${color}">\u25CF</span>
                ${name}: <b>${roundToDecimals(y, 1)} %</b><br/>`
        },
      },
      lineWidth: 3,
      data: forecast.properties.timeseries.map(({ time, data }) => {
        return [time.valueOf(), data.instant.details["relative_humidity"]]
      }),
    },
  ]
}

const getPrecipitationPlots = ({
  forecast,
  intl,
}: ChartBasis): Highcharts.SeriesColumnOptions[] => {
  return [
    {
      type: "column",
      yAxis: yAxis.precipitation,
      color: lunaColors.precipitation,

      groupPadding: 0,
      pointPadding: 0.1,
      grouping: false,
      visible: getSeriesVisibility("precipitation_amount"),
      pointRange: 6 * 36e5,

      dataLabels: {
        enabled: true,
        filter: {
          operator: ">",
          property: "y",
          value: 0,
        },
        style: {
          fontSize: "14px",
        },
      },

      name: intl.formatMessage({ id: "precipitation" }),
      id: "precipitation",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            options: { y },
          } = this as any
          const name = intl.formatMessage({ id: "precipitation" })
          return `
                <span style="color:${color}">\u25CF</span>
                ${name}: <b>${roundToDecimals(y, 1)} mm</b><br/>`
        },
      },

      data: forecast.properties.timeseries.reduce((acc, { time, data }) => {
        const match = ["0:00", "6:00", "12:00", "18:00"].find(
          (timeStr) => timeStr === time.format("H:ss")
        )
        if (!match) {
          return acc
        }
        let prep = data.next_6_hours?.details["precipitation_amount"] || 0

        acc.push({
          x: time.valueOf(),
          y: prep,
        })

        return acc
      }, [] as Required<Highcharts.SeriesColumnOptions>["data"]),
    },
  ]
}

const getAccPrecipitationPlots = ({
  forecast,
  intl,
}: ChartBasis): Highcharts.SeriesColumnOptions[] => {
  return [
    {
      type: "column" as const,
      yAxis: yAxis.precipitation,
      color: lunaColors.precipitation_acc,

      index: 0,
      opacity: 100,

      groupPadding: 0,
      pointPadding: 0.1,
      grouping: false,
      visible: getSeriesVisibility("precipitation_acc"),
      pointRange: 6 * 36e5,

      dataLabels: {
        enabled: false,
        filter: {
          operator: ">" as const,
          property: "y" as const,
          value: 0,
        },
        style: {
          fontSize: "13px",
          color: "gray",
        },
      },

      name: intl.formatMessage({ id: "precipitation_acc" }),
      id: "precipitation_acc",
      tooltip: {
        pointFormatter: function () {
          const {
            color,
            options: { y },
          } = this as any
          const name = intl.formatMessage({ id: "precipitation_acc" })
          return `
                <span style="color:${color}">\u25CF</span>
                ${name}: <b>${roundToDecimals(y, 1)} mm</b><br/>`
        },
      },

      data: forecast.properties.timeseries.reduce((acc, { time, data }) => {
        const match = ["0:00", "6:00", "12:00", "18:00"].find(
          (timeStr) => timeStr === time.format("H:ss")
        )
        if (!match) {
          return acc
        }

        if (acc.length === 0) {
          acc_prep = 0
        }

        //let prep = data.next_6_hours?.details["precipitation_amount"] || 0
        acc_prep += data.next_6_hours?.details["precipitation_amount"] || 0

        const yvalue = roundToDecimals(acc_prep, 1)
        acc.push({
          x: time.valueOf(),
          y: yvalue,
        })
        return acc
      }, [] as Required<Highcharts.SeriesColumnOptions>["data"]),
    },
  ]
}

export const convertToKnots = ({
  metersPerSecond,
}: {
  metersPerSecond: number
}): number => {
  // one knot is 1852m/h.
  const knotConversionFactor = 3600 / 1852
  return metersPerSecond * knotConversionFactor
}

const roundToZeroDecimals = (num: number): number =>
  Number.parseFloat((Math.round((num * 1000) / 10) / 100).toFixed(0))

const generatePlotlineLabel = ({
  forecast,
  labelOptions,
}: {
  forecast: LocationForecastData
  labelOptions: XAxisPlotLinesLabelOptions
}) => {
  return {
    color: lunaColors.plotLine,
    dashStyle: "Solid",
    value: forecast.properties.timeseries[1].time.valueOf(),
    width: 0,
    label: {
      verticalAlign: "bottom",
      rotation: 0,
      text: `Some title`,
      ...labelOptions,
    },
  } as XAxisPlotLinesOptions
}

const plotDayBands = ({
  forecast,
  currentTimezone,
  intl,
}: ChartBasis): Highcharts.XAxisPlotBandsOptions[] => {
  const range = moment.range(
    forecast.properties.timeseries[0].time.clone().tz(currentTimezone),
    forecast.properties.timeseries[
      forecast.properties.timeseries.length - 1
    ].time
      .clone()
      .tz(currentTimezone)
  )
  return Array.from(range.by("days")).map((day) => {
    return {
      borderColor: lunaColors.veryLight,
      borderWidth: 2,
      color: lunaColors.transparent,
      from: day.startOf("day").valueOf(),
      to: day.endOf("day").valueOf(),
      label: {
        verticalAlign: "bottom",
        y: 70,
        text: day.locale(intl.locale).format("dddd Do MMM"),
        formatter: function () {
          const { text } = (this as any).options.label
          // Emphasize name of day, display rest in normal text
          return `<strong>${text
            .split(" ")
            .slice(0, 1)
            .map((str: string) => capitalize(str).slice(0, 3))
            .join()} </strong> ${text.split(" ").slice(1).join(" ")}`
        },
      },
    }
  })
}

const getWeatherSymbolPlots = ({
  forecast,
  intl,
}: ChartBasis): Highcharts.SeriesLineOptions[] => {
  return [
    {
      type: "line",
      yAxis: yAxis.symbol,
      marker: {
        enabled: true,
      },
      visible: getSeriesVisibility("weather_symbol"),
      lineWidth: 0,
      color: lunaColors.transparent,
      name: intl.formatMessage({ id: "weather_symbol" }),
      id: "weather_symbol",
      tooltip: {
        pointFormatter: function () {
          return ""
        },
      },
      dataGrouping: {
        enabled: true,
        groupPixelWidth: 150,
      },
      states: {
        inactive: {
          opacity: 1,
        },
      },
      data: forecast.properties.timeseries.reduce(
        (acc, { time, data }, currentIndex, currentArray) => {
          // Don't show weather icon at the very edges of graph
          if (currentIndex === 0 || currentIndex === currentArray.length - 1) {
            return acc
          }
          const match = ["0:00", "6:00", "12:00", "18:00"].find(
            (timeStr) => timeStr === time.format("H:ss")
          )
          if (!match) {
            return acc
          }
          acc.push({
            x: time.valueOf(),
            y: 0.92,
            marker: {
              height: 40,
              width: 40,
              //symbol: `url(/weathericons/${data.weather_symbol_code}.svg)`,
              symbol: `url(/meteogramweathericons/${data.next_6_hours?.summary.symbol_code}.svg)`,
            },
          })
          return acc
        },
        [] as Required<Highcharts.SeriesLineOptions>["data"]
      ),
    },
  ]
}

const getCompleteSeriesVisibility = () => {
  return (
    loadJSONFromLocalStorage<SeriesVisibility>({
      key: "MeteogramSeriesVisibility",
      returnIfNull: { ...defaultSeries },
    }) || defaultSeries
  )
}

/**
 * Purpose: Make it possible to create a getSeriesVisibility function that lets
 * itself be overwritten.
 * @param overrideVisibleSeries If this is 'true' then use default series. Otherwise allow object to the passed in.
 *
 */
const initialiseSeriesVisibilityFunc = (
  overrideVisibleSeries?: OverrideVisibilityOptions
) => {
  return function getSeriesVisibility(series: Series): boolean {
    if (typeof overrideVisibleSeries === "boolean") {
      return defaultSeries[series]
    } else if (overrideVisibleSeries) {
      return !!overrideVisibleSeries[series]
    }
    const seriesFromStorage = getCompleteSeriesVisibility()
    return seriesFromStorage[series]
  }
}

const saveSeriesVisibility = (series: Series, hidden: boolean) => {
  const seriesFromStorage = getCompleteSeriesVisibility()
  seriesFromStorage[series] = !hidden
  saveInLocalStorage<SeriesVisibility>({
    key: "MeteogramSeriesVisibility",
    data: seriesFromStorage,
  })
}

/**
 * Mutates the running chart if the activation/deactivation
 * of the wind series necessitates moving the temperature
 * yAxis.
 */
const maybeUpdateTemperatureYAxis = (chart: Chart): void => {
  const yAxises = chart.options.yAxis as YAxisOptions[]
  const temperatureAxis = yAxises.find(
    (axe: any) => axe.id === "air-temperature-series"
  ) as YAxisOptions
  if (!temperatureAxis || !temperatureAxis.labels) {
    throw new Error("temperature yAxis options not found, or misconfigured")
  }
  const offset = getTemperatureYAxisOffset()
  if (temperatureAxis.labels.x !== offset) {
    temperatureAxis.labels.x = offset
    chart.update({ yAxis: [temperatureAxis] })
  }
}

/**
 * Used to offset labels on the left yAxis depending
 * on whether or not wind yAxis is visible.
 */
const getTemperatureYAxisOffset = (): number => {
  return getSeriesVisibility("wind_speed_10_meter") ? 95 : 45
}
