import { createContext, useContext } from "react"

export type TimezoneID = "CET" | "UTC"

export type TimezoneOptions = {
  [K in TimezoneID]: string
}

interface TimezoneContextProps {
  currentTimezone: TimezoneID
  setCurrentTimezone: (timezone: TimezoneID) => void
}

export const TimezoneContext = createContext<TimezoneContextProps>({
  // this defaultValue only used if there is no provider in the tree
  currentTimezone: "CET",
  setCurrentTimezone: () => {},
})

export function useTimezone() {
  return useContext(TimezoneContext)
}
