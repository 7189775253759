import { createContext, useContext } from "react"

interface Props {
  username: string
  firstName: string
  lastName: string
}

export const LoggedInUserContext = createContext<Props>({
  username: "No user name",
  firstName: "No full name",
  lastName: "No last name",
})

export function useLoggedInUser() {
  return useContext(LoggedInUserContext)
}
