import { AvailableMapFileID } from "./AvailableMaps.service"
const modelStartValues = {
  ec00: { hour: 0, minute: 0, second: 0 },
  ec12: { hour: 12, minute: 0, second: 0 },
}

const timeIncrements = {
  ec00: { hour: 6 },
  ec12: { hour: 6 },
}

export const useMapDetails = ({ ec }: { ec: "ec00" | "ec12" }) => {
  const mapType: AvailableMapFileID = `${ec}_the850mspl_europa`
  const modelStart = modelStartValues[ec]
  const timeIncrement = timeIncrements[ec]
  return { mapType, modelStart, timeIncrement }
}
