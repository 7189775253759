import { Link as MuiLink } from "@mui/material"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

type MuiProps = Exclude<Parameters<typeof MuiLink>[0], "href">

type RouterLinkProps = Parameters<typeof RouterLink>[0]

type Props = MuiProps & RouterLinkProps

/**
 * Link combines Material UI's link with react-router-dom. This
 * gives us styling and zero-refresh navigation.
 *
 * Learn more: {@link https://mui.com/guides/routing/#link}
 */
export const Link = React.forwardRef(({ to, ...rest }: Props, ref) => {
  // If the link is a plain http link we trigger a normal browser navigation.
  if (typeof to === "string" && to.startsWith("http")) {
    return <MuiLink ref={ref as any} underline="always" {...rest} href={to} />
  }
  return (
    <MuiLink
      ref={ref as any}
      underline="always"
      {...rest}
      to={to}
      component={RouterLink}
    />
  )
})
