import { Container, Grid } from "@mui/material"
import OffshoreGraph from "../OffshoreGraph/OffshoreGraph"
import SignWeather from "../SignWeather"

interface Props {
  report: OffshoreReportData
}

export const OffshoreMenuGraph: React.FC<Props> = ({ report }) => {
  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={2}>
        <SignWeather offshoreData={report.offshoreData} />
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <OffshoreGraph report={report} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
