import { FC, ReactElement } from "react"
import TableHead from "@mui/material/TableHead"
import { MetCriteriaThresholdRows } from "./MetCriteriaTresholdRows"
import { ActiveCriteria } from "./OffshoreTable.hook"
import { ColumnGroup } from "./OffshoreTable"
import { TableCell, TableRow } from "@mui/material"

export const OffshoreTableHead: FC<{
  columnGroups: ColumnGroup[]
  activeCriteria: ActiveCriteria
  setActiveCriteria: (updateFunc: any) => void
}> = ({ activeCriteria, columnGroups, setActiveCriteria }) => {
  return (
    <TableHead
      sx={{
        "& th": {
          fontWeight: 700,
        },
      }}
    >
      <ParameterGroupsRow columnGroups={columnGroups}></ParameterGroupsRow>
      <ParametersRow columnGroups={columnGroups}></ParametersRow>
      <MetCriteriaThresholdRows
        columnGroups={columnGroups}
        activeCriteria={activeCriteria}
        setActiveCriteria={setActiveCriteria}
      />
    </TableHead>
  )
}

const ParameterGroupsRow: FC<{
  columnGroups: ColumnGroup[]
}> = ({ columnGroups }) => {
  const cells = columnGroups.reduce<ReactElement[]>(
    (acc, columnGroup, columnGroupIndex) => {
      const { columns } = columnGroup
      columns.forEach((column, columnIndex) => {
        const isLastInColumn = columnIndex === columns.length - 1
        if (!isLastInColumn) return

        const isLastColumnInRow =
          isLastInColumn && columnGroupIndex === columnGroups.length - 1

        const tableCell = (
          <TableCell
            key={column.id}
            align={column.align}
            component={"td" as any}
            sx={{
              "&:first-of-type": {
                whiteSpace: "nowrap",
              },
              transition: "background-color 0.5s ease-out",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "28px",
              textAlign: "left",
              ...(!isLastColumnInRow && { borderRight: `1px solid black` }),
              ...(columnGroupIndex === 0 && {
                position: "sticky",
                left: 0,
                zIndex: 4,
                backgroundColor: "#ffffff",
              }),
            }}
            colSpan={columnGroup.columns.length}
          >
            {columnGroup.header.label}
          </TableCell>
        )

        acc.push(tableCell)
      })
      return acc
    },
    []
  )

  return (
    <TableRow
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 3,
        backgroundColor: "#ffffff",
        borderCollapse: "separate",
      }}
    >
      {cells}
    </TableRow>
  )
}

const ParametersRow: FC<{ columnGroups: ColumnGroup[] }> = ({
  columnGroups,
}) => {
  const cellsInRow = columnGroups.flatMap((columnGroup, columnGroupIndex) => {
    const { columns } = columnGroup
    const cellsInColumnGroup = columns.map((column, columnIndex) => {
      const isLastInColumn = columnIndex === columns.length - 1
      const isVeryLastInRow =
        isLastInColumn && columnGroupIndex === columnGroups.length - 1

      const isLastColumnInGroup = isLastInColumn && !isVeryLastInRow

      const isFirstColumnInRow = columnGroupIndex === 0 && columnIndex === 0

      const tableCell = (
        <TableCell
          key={column.id}
          align={column.align}
          component={"td" as any}
          sx={{
            "&:first-of-type": {
              whiteSpace: "nowrap",
            },
            transition: "background-color 0.5s ease-out",
            ...(isLastColumnInGroup && {
              borderRight: `1px solid black`,
            }),
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
            fontWeight: 700,
            fontSize: "14px",
            fontStyle: "normal",

            verticalAlign: isFirstColumnInRow ? "top" : "inherit",
            ...(columnGroupIndex === 0 && {
              position: "sticky",
              left: 0,
              zIndex: 4,
              backgroundColor: "#ffffff",
            }),
          }}
        >
          <>
            <div
              style={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
              }}
            >
              {column.label}
              {column.label && column.unit && <br />}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              {column.unit}
            </div>
          </>
        </TableCell>
      )
      return tableCell
    })
    return cellsInColumnGroup
  })

  return (
    <TableRow
      sx={{
        position: "sticky",
        top: 60.5,
        zIndex: 3,
        backgroundColor: "#ffffff",
        borderBottom: (theme) => `1px solid ${theme.palette.common.black}`,
      }}
    >
      {cellsInRow}
    </TableRow>
  )
}
