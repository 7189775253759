import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
  Button,
  Menu,
  SelectChangeEvent,
} from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import { useIncrementWhenChanged } from "components/useIncrementWhenChanged"
import { useEffect, useState } from "react"
import { getLocationForecastData } from "../../services/dataFetcher"
import HighchartsReact from "highcharts-react-official"
import React, { useRef } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MeteogramHighcharts from "components/Meteogram/MeteogramHighcharts"
import { FormattedMessage } from "react-intl"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    meteogramBox: {
      backgroundColor: theme.palette.common.white,
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    root: {
      flexGrow: 1,
      display: "flex",
    },
    header: {
      paddingBottom: theme.spacing(4),
    },

    progress: {
      textAlign: "center",
      verticalAlign: "middle",
      margin: "20em",
    },

    gridContainerItem: {},
  })
)

type Props = {
  type?: "default" | "fullscreen-active"
  positions?: PositionList
  autoRefresh?: true
}

const Meteogram: React.FC<Props> = ({
  positions = [],
  autoRefresh = true,
  type,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const chartRef = useRef<HighchartsReact.RefObject>()
  const classes = useStyles()
  const [indexNumber, setIndexNumber] = useState(0)
  const [position, setPosition] = useState(positions[0])
  const [locationForecast, setLocationForecast] = useState<
    LocationForecastData | undefined
  >()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const exportOptions = {
    filename: `meteogram`,
    sourceWidth: 2500,
    sourceHeight: 900,
  }

  const handleChangePositionIndex = (event: SelectChangeEvent<number>) => {
    setIndexNumber(event.target.value as number)
    setPosition(positions[event.target.value as number])
  }

  const { increment } = useIncrementWhenChanged({ queryParam: "openDialog" })

  useEffect(() => {
    const loadLocationForecast = async () =>
      getLocationForecastData(
        position.latitude,
        position.longitude,
        position.altitude
      )
        .then((data) => {
          setLocationForecast(data)
        })
        .catch((err: Error) => {
          const message = "Failed to load LocationForecast Data."
          console.log(message, err)
        })
    loadLocationForecast()

    if (!autoRefresh) {
      return
    }

    const intervalID = setInterval(loadLocationForecast, 1000 * 60 * 10) // ten minute intervals.
    return () => {
      clearInterval(intervalID)
    }
  }, [position, autoRefresh])

  if (!locationForecast) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <Grid container sx={{ minWidth: "1170px" }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item xs="auto">
            <Typography variant="h2">Meteogram</Typography>
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            <Grid item>
              <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>
                    <FormattedMessage id="show_meteogram_for" />:
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl size="small">
                    <Select
                      style={{ height: "43px" }}
                      variant={"outlined"}
                      labelId="position-label"
                      id="thresholdWave"
                      value={indexNumber}
                      onChange={handleChangePositionIndex}
                    >
                      {positions.map((pos, index) => (
                        <MenuItem key={index} value={index}>
                          {pos.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                aria-controls="download-menu"
                fullWidth
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleClick}
                endIcon={<ExpandMoreIcon />}
                style={{ height: "43px" }}
              >
                <FormattedMessage id="downLoadPlot" />
              </Button>
              <Menu
                id="download-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  key="1"
                  onClick={() => {
                    // Need to use any here because Typescript
                    // does not pick up on the export module
                    const chart: any = chartRef.current?.chart
                    chart.exportChartLocal(exportOptions)
                    handleClose()
                  }}
                >
                  <FormattedMessage id="downLoadPNG" />
                </MenuItem>
                <MenuItem
                  key="2"
                  onClick={() => {
                    // Need to use any here because Typescript
                    // does not pick up on the export module
                    const chart: any = chartRef.current?.chart
                    chart.exportChartLocal({
                      ...exportOptions,
                      type: "image/svg+xml",
                    })
                  }}
                >
                  <FormattedMessage id="downLoadSVG" />
                </MenuItem>
              </Menu>
            </Grid>
            {type !== "fullscreen-active" && (
              <Grid item>
                <FullscreenDialogButton type="minimal" dialogID="meteogram" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.meteogramBox}>
          {locationForecast && (
            <>
              <Typography>
                {position.name} {locationForecast.geometry.coordinates[2]}{" "}
                <FormattedMessage id="masl" /> (
                {locationForecast.geometry.coordinates[1]} {", "}
                {locationForecast.geometry.coordinates[0]})
              </Typography>
              <MeteogramHighcharts
                key={increment}
                ref={chartRef}
                apiData={locationForecast}
              />
            </>
          )}
        </Grid>
      </Grid>
      {/* Avoid infinite recursion */}
      {type !== "fullscreen-active" && (
        <FullscreenDialog id="meteogram" title="Meteogram">
          {/* Support scrolling on mobile */}
          <Box
            padding={4}
            sx={{
              overflowX: {
                xs: "scroll",
                sm: "scroll",
                md: "scroll",
                lg: "initial",
              },
              margin: "-32px",
            }}
          >
            <Meteogram type="fullscreen-active" positions={positions} />
          </Box>
        </FullscreenDialog>
      )}
    </>
  )
}

export default Meteogram
