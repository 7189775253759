import { Box, styled } from "@mui/material"
import { useQueryParam, withDefault, StringParam } from "use-query-params"

const ContainerDefault = styled("div")(({ theme }) => ({
  display: "inline-block",
}))

const ContainerFullscreen = styled("div")(({ theme }) => ({
  display: "inline-block",
  flexFlow: "column",
}))

const ImagesDefault = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexFlow: "column nowrap",
  gap: theme.spacing(2),
  "& img": {
    flex: "0 1 auto",
    width: "100%",
    objectFit: "contain",
  },
  [theme.breakpoints.up("md")]: {
    flexFlow: "row nowrap",
  },
}))

const ImagesFullscreen = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexFlow: "row wrap",
  gap: theme.spacing(2),
  // Style immediate children because sometimes they're div's
  "& > *": {
    flex: "1 1 auto",
  },
  "& img": {
    objectFit: "contain",
    objectPosition: "left top",
    width: "100%",
    height: "100vh",
    maxHeight: "calc(100vh - 20rem)",
  },
  [theme.breakpoints.up("md")]: {
    flexFlow: "row nowrap",
  },
}))

type Props = {
  imagePlayer?: JSX.Element
}

export const MapRenderContainer: React.FC<Props> = ({
  children,
  imagePlayer,
}) => {
  const [openDialog] = useQueryParam("openDialog", withDefault(StringParam, ""))
  if (openDialog) {
    return (
      <ContainerFullscreen>
        <Box marginTop={-4}>{imagePlayer}</Box>
        <ImagesFullscreen>{children}</ImagesFullscreen>
      </ContainerFullscreen>
    )
  }
  return (
    <ContainerDefault>
      <ImagesDefault>{children}</ImagesDefault>
      {imagePlayer}
    </ContainerDefault>
  )
}
