import { Box, Container, Typography } from "@mui/material"
import ValidFrom from "components/ValidFrom"
import OffshoreTable from "../OffshoreTable/OffshoreTable"

interface Props {
  report: OffshoreReportData
}

export const OffshoreMenuTable: React.FC<Props> = ({ report }) => {
  const valid = report.qubaData.forecast[0].valid
  return (
    <Container
      sx={(theme) => ({
        //This is a hack to display the entire table on fullscreen. remove when radiobuttons get added.
        [theme.breakpoints.up(3000)]: {
          minWidth: 1750,
          position: "relative",
        },
      })}
    >
      <Box paddingLeft={2} paddingBottom={3}>
        <Typography variant="body2">
          <ValidFrom valid={valid} />
        </Typography>
      </Box>
      <Box paddingLeft={2} paddingRight={2} paddingBottom={5}>
        <OffshoreTable report={report} showLegendFilter={true} />
      </Box>
    </Container>
  )
}
