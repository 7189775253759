import { Dispatch, SetStateAction } from "react"

/**
 * StorageKey lets us namespace the data we save.
 */
type StorageKey =
  | "LunaOffshoreTableActiveCriteria"
  | "LunaOffshoreTableActiveColumns"
  | "LunaTimezone"
  | "LunaLocale"
  | "LunaOffshoreGraphSeriesVisibility"
  | "MeteogramSeriesVisibility"
  | "OceanCurrentSeriesVisibility"
  | "ThresholdWave"
  | "ThresholdWind"
  | "LunaWindUnit"

export function clearLocalStorage(key: "completely" & StorageKey) {
  if (key === "completely") {
    window.localStorage.clear()
  }
  return window.localStorage.removeItem(key)
}

export function saveInLocalStorage<T>({
  key,
  data,
}: {
  key: StorageKey
  data: T
}) {
  if (typeof data === "string") {
    return window.localStorage.setItem(key, data)
  }
  try {
    return window.localStorage.setItem(key, JSON.stringify(data))
  } catch (err) {
    console.log("Failed to save data in localstorage", err)
    throw new Error(`Unable to save '${key}' data`)
  }
}

export function loadJSONFromLocalStorage<T>({
  key,
  returnIfNull,
}: {
  key: StorageKey
  returnIfNull?: T
}): T | null {
  try {
    const stringifiedData = window.localStorage.getItem(key)
    if (stringifiedData === null) {
      return returnIfNull || null
    }
    return JSON.parse(stringifiedData)
  } catch (err) {
    console.log("Failed to load data from localstorage", err)
    throw new Error(`Unable to load '${key}' data`)
  }
}

export function loadStringFromLocalStorage<T>({
  key,
  returnIfNull,
}: {
  key: StorageKey
  returnIfNull: T
}): T {
  const data = window.localStorage.getItem(key)
  if (data === null) {
    return returnIfNull
  }
  return data as unknown as T
}

/**
 * Intercept React's setState function from React's useState
 * hook and persist any calls to it in LocalStorage as well.
 */
export function addLocalStoragePersistence<T>({
  key,
  setStateFunc,
}: {
  key: StorageKey
  setStateFunc: Dispatch<SetStateAction<T>>
}) {
  return function wrappedSetStateFunc(updateFunc: any) {
    setStateFunc((prevState) => {
      const newState = (() => {
        if (typeof updateFunc === "function") {
          return updateFunc(prevState)
        }
        /**
         * If it's not a function it's just a plain value that the caller wants
         * to save
         */
        return updateFunc
      })()
      saveInLocalStorage<T>({
        key,
        data: newState,
      })
      return newState
    })
  }
}
