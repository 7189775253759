import { CircularProgress } from "@mui/material"
import React from "react"

export default function LoadingSpinner() {
  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        margin: "20em",
      }}
    >
      <CircularProgress />
    </div>
  )
}
