import { useCallback } from "react"
import { getAvailableMaps } from "services/dataFetcher"
import { useQuery } from "react-query"

// Fetched from the luna-backend weathermap.go file.
export type AvailableMapFileID =
  | "meps_prec_prb5.0_24_norway"
  | "meps_prec_prb10_24_norway"
  | "meps_prec_prb20_24_norway"
  | "meps_prec_prb40_24_norway"
  | "meps_prec_prb60_24_norway"
  | "meps_prec_prb80_24_norway"
  | "meps_prec_prb100_24_norway"
  | "meps_prec_prb5.0_48_norway"
  | "meps_prec_prb10_48_norway"
  | "meps_prec_prb20_48_norway"
  | "meps_prec_prb40_48_norway"
  | "meps_prec_prb60_48_norway"
  | "meps_prec_prb80_48_norway"
  | "meps_prec_prb100_48_norway"
  | "meps_prec_prb5.0_24_eastnorway"
  | "meps_prec_prb10_24_eastnorway"
  | "meps_prec_prb20_24_eastnorway"
  | "meps_prec_prb40_24_eastnorway"
  | "meps_prec_prb60_24_eastnorway"
  | "meps_prec_prb80_24_eastnorway"
  | "meps_prec_prb100_24_eastnorway"
  | "meps_prec_prb5.0_48_eastnorway"
  | "meps_prec_prb10_48_eastnorway"
  | "meps_prec_prb20_48_eastnorway"
  | "meps_prec_prb40_48_eastnorway"
  | "meps_prec_prb60_48_eastnorway"
  | "meps_prec_prb80_48_eastnorway"
  | "meps_prec_prb100_48_eastnorway"
  | "ec_prec_prb5.0"
  | "ec_prec_prb10"
  | "ec_prec_prb20"
  | "ec_prec_prb40"
  | "ec_prec_prb60"
  | "ec_prec_prb80"
  | "ec_prec_prb100"
  | "ec_prec_acc"
  | "ec_tt"
  | "meps_tt"
  | "ec_rr"
  | "meps_rr"
  | "ec_arr"
  | "ec-1_arr"
  | "ec00_the850mspl_europa"
  | "ec12_the850mspl_europa"
  | "ec00_rh700_z700_europa"
  | "ec12_rh700_z700_europa"
  | "ec00_jet_europa"
  | "ec12_jet_europa"
  | "ec00_prec3t_europa"
  | "ec12_prec3t_europa"
  | "ec00_hydroprec6t_europa"
  | "ec12_hydroprec6t_europa"
  | "ec00_t850z850_europa"
  | "ec12_t850z850_europa"
  | "scandinavia_wind_2m"

export type MapsStructure = ProductURL[]

export type ProductURL = {
  product: string
  maps: MapData[]
}
export type MapData = { url: string; mtime: string }

export const isTypeMapsStructure = (
  elem: string[] | MapsStructure
): elem is MapsStructure => {
  return !!(elem as MapsStructure)[0].product
}

export const useAvailableMaps = () => {
  const loadFileData = useCallback(async () => {
    const data = await getAvailableMaps()

    return data
  }, [])

  const cacheKey = ["Maps"]

  const available = useQuery(cacheKey, loadFileData, {
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  return { available, useSpecificMaps }
}

export const useSpecificMaps = (
  map: AvailableMapFileID | AvailableMapFileID[]
) => {
  const loadFileData = useCallback(async () => {
    const data = await getAvailableMaps(map)

    return data
  }, [map])

  const cacheKey = ["Maps", map]

  const result = useQuery(cacheKey, loadFileData, {
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  return result
}

export const getMapsFromData = (
  name: string,
  data: MapsStructure | undefined
): MapData[] => {
  const maps = (
    (data || []).find(({ product }) => product === name) || {
      maps: [] as MapData[],
    }
  ).maps

  return maps
}
