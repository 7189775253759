import React from "react"
import { Container, Grid, Theme, Typography, Box } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import ForecastSummary from "./ForecastSummary"
import { useIntl } from "react-intl"
import EXWWGraph from "./EXWWGraph/EXWWGraph"
import SummaryBox from "./SummaryBox"
import Hidden from "@mui/material/Hidden"
import { XWWReportData } from "./dataFetcher"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    paper: {
      height: "100%",
    },
    summery_box: {
      margin: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      border: "1px solid #E9E9E9",
      boxShadow:
        "0px 0px 40px rgba(0, 0, 0, 0.05), 0px 0px 10px rgba(0, 0, 0, 0.1)",
    },
    highchart_box: {
      margin: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
    },
  })
)

export interface XWWPageContentProps {
  platform?: string
  onPlatformChange?: (e: React.ChangeEvent<{ value: unknown }>) => void
  report: XWWReportData
}

const XWWPageContent: React.FC<XWWPageContentProps> = ({
  platform,
  report,
  onPlatformChange,
}) => {
  const intl = useIntl()
  const classes = useStyles()
  const { xwwData } = report
  if (!report) {
    return <>Nothing to show</>
  }
  return (
    <Container className={classes.root} maxWidth={"xl"}>
      <Grid container spacing={2}>
        <Hidden lgDown>
          <Grid item xs={12}>
            <Box paddingLeft={2} paddingBottom={3}>
              <Typography variant="h1">
                EXWW - Ekofisk eXtreme Wave Warning
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={3} className={classes.summery_box}>
          <SummaryBox xwwData={xwwData} />
        </Grid>
        <Grid item xs={12} lg={8} className={classes.highchart_box}>
          <EXWWGraph
            currentPlatform={platform}
            onPlatformChange={onPlatformChange}
            report={report}
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid item lg={3}></Grid>
          <Grid item xs={12} lg={5}>
            <Box padding={3}>
              <ForecastSummary xwwData={xwwData} intl={intl.locale} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default XWWPageContent
