import { Box } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import { Theme } from "@mui/material/styles"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import Toolbar from "@mui/material/Toolbar"
import { TransitionProps } from "@mui/material/transitions"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import queryString from "query-string"
import React, { ReactElement, Fragment } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { useIncrementWhenChanged } from "./useIncrementWhenChanged"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    toolbarContent: {
      marginLeft: theme.spacing(1),
    },
  })
)

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    const { children, ...rest } = props
    return (
      <Slide
        direction="up"
        ref={ref}
        {...{
          ...rest,
          children: children as ReactElement<any, any>,
        }}
      />
    )
  }
)

interface Props {
  id: string
  title: string | ReactElement
  showChildrenAlsoWhenClosed?: boolean
}

/**
 * FullscreenDialog is meant to be used in combination with
 * the FullscreenDialogButton component. This component react to
 * the presence of its ID in the url as ?openDialog=some-dialog-id.
 *
 * It will render any children by default. And if it activates it
 * will re-render children in a fullscreen dialog.
 */
export const FullscreenDialog: React.FunctionComponent<Props> = ({
  id,
  title,
  showChildrenAlsoWhenClosed,
  children,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const { openDialog, ...restOfParams } = queryString.parse(location.search)
  const isOpen = id === openDialog
  const handleClose = () => {
    const newSearchString = (() => {
      if (Object.keys(restOfParams).length > 0) {
        return `?${queryString.stringify(restOfParams)}`
      }
      return ""
    })()
    history.push(`${location.pathname}${newSearchString}`)
  }

  // Trigger re-render when closing the modal.
  const { increment } = useIncrementWhenChanged({ queryParam: "openDialog" })

  return (
    <>
      {showChildrenAlsoWhenClosed && (
        <Fragment key={increment}>{children}</Fragment>
      )}
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} variant="elevation" elevation={1}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              {/* SVG contains whitespace, override viewbox to zoom in. */}
              <CloseIcon viewBox="5 5 15 15" />
            </IconButton>
            <Typography
              variant="h3"
              component="h1"
              className={classes.toolbarContent}
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box padding={4}>{children}</Box>
      </Dialog>
    </>
  )
}
