import { DurationLikeObject } from "luxon"
import { AvailableMapFileID } from "./AvailableMaps.service"

export const generateMapDetails = (): {
  today: AvailableMapFileID
  yesterday: AvailableMapFileID
  timeIncrement: DurationLikeObject
  modelStart: DurationLikeObject
} => {
  return {
    today: "ec_arr",
    yesterday: "ec-1_arr",
    timeIncrement: { hour: 6 },
    modelStart: { hour: 6, minute: 0, second: 0 },
  }
}
