import React, { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import LoadingSpinner from "./LoadingSpinner"

interface Props {
  // Url to some markdown file.
  url: string
}

export const MarkdownLoader: React.FC<Props> = ({ url }) => {
  const [content, setContent] = useState("")
  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((text) => {
        // If no file is found we'll always get a status 200 because this is
        // a single-page app. So, we check if we get what looks like a index.html
        // because that would be an error.
        if (text.startsWith("<!DOCTYPE")) {
          return setContent(
            "Error. Code was not able to load explanation page."
          )
        }
        setContent(text)
      })
  }, [url])
  if (!content) {
    return <LoadingSpinner />
  }
  return <ReactMarkdown plugins={[gfm]} children={content} />
}
