import React from "react"
import { Container, Grid, Theme, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import Hidden from "@mui/material/Hidden"
import AlertBox from "./AlertBox"
import NextForecastTime from "./NextForecastTime"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import FromToDateString from "./FromToDateString"
import { useTimezone } from "@luna/luna-core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: "inline-flex",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
      border: 1,
      justifyContent: "space-evenly",
    },

    mdBox: {
      paddingBottom: theme.spacing(2),
    },
    stormName: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "22px",
      letterSpacing: "0.25px",
    },
    boxHeader: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    boxContent: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    emphasize: {
      fontSize: 22,
      fontWeight: "bold",
    },
    emc_box: {
      borderRadius: "10px",
      background: "#F8F7FD",
      marginBottom: theme.spacing(2),

      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    emc_boxHeader: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.25px",

      paddingLeft: "2em",
      paddingTop: "1.5em",
    },
    emc_boxContent: {
      fontStyle: "normal",
      fontWeight: "bold",
      paddingTop: "1em",
      marginRight: "10px",
    },
    empty: {
      marginTopp: theme.spacing(4),
      fontSize: "24px",
    },
  })
)

interface SummeryBoxProps {
  xwwData: XWWData
}

const SummeryBox: React.FC<SummeryBoxProps> = ({ xwwData }) => {
  const classes = useStyles()
  const { currentTimezone } = useTimezone()

  return (
    <Container>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          className={classes.box}
          spacing={2}
        >
          <Hidden only={["md"]}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <FormattedMessage id="exww_storm" />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.stormName}>
                {xwwData.storm}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography variant="body1">
                <FormattedMessage id="forecast_number" />
              </Typography>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {xwwData.forecast_nr}
              </Typography>
            </Grid>

            <Grid item xs={6} lg={6}>
              <Typography variant="body1">
                <FormattedMessage id="published_from_met" />
              </Typography>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {moment(xwwData.published)
                  .tz(currentTimezone)
                  .format("DD.MM.YYYY HH:mm zz")}
              </Typography>
            </Grid>

            <NextForecastTime
              color={xwwData.alert_level}
              time={xwwData.next_forecast_time}
            />
            <Grid item xs={6} lg={6}>
              <Typography variant="body1">
                <FormattedMessage id="contact_person" />
              </Typography>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {xwwData.createdBy} <br></br> Tlf. 905 88 934
              </Typography>
            </Grid>

            <Grid container item xs={12} lg={12} className={classes.box}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <FormattedMessage id="awareness_level" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AlertBox color={xwwData.alert_level} />
              </Grid>
            </Grid>

            <Grid container item xs={12} md={9} lg={12} className={classes.box}>
              <Grid item xs={12}>
                <Typography className={classes.boxHeader}>
                  <FormattedMessage id="max_in_storm" />
                </Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={5}
                lg={12}
                className={classes.emc_box}
              >
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "28px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    EMC
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "28px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {xwwData.max_emc_direction}° / {xwwData.max_emc} m
                  </Typography>
                  <Typography className={classes.boxContent}>
                    <FromToDateString
                      from={xwwData.max_emc_date_from}
                      to={xwwData.max_emc_date_to}
                    />
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={5}
                lg={12}
                className={classes.emc_box}
              >
                <Grid item xs={6} lg={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "28px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    <FormattedMessage id="wind" />
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      lineHeight: "20px",
                      letterSpacing: "0.25px",
                    }}
                  >
                    [10m,10min]
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "28px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {xwwData.max_wind_direction}° / {xwwData.max_wind}{" "}
                    <FormattedMessage id="wind_unit" />
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      lineHeight: "20px",
                      letterSpacing: "0.25px",
                    }}
                  >
                    <FromToDateString
                      from={xwwData.max_wind_date_from}
                      to={xwwData.max_wind_date_to}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden only={["xs", "sm", "lg", "xl"]}>
            <Grid container item md={8} spacing={2}>
              <Grid item md={6}>
                <Typography variant="body2">
                  <FormattedMessage id="exww_storm" />
                  {"  "}&nbsp;
                  <span className={classes.stormName}>{xwwData.storm}</span>
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body2">
                  <FormattedMessage id="forecast_number" />
                  {"  "}&nbsp;
                  <span className={classes.boxHeader + " " + classes.emphasize}>
                    {xwwData.forecast_nr}
                  </span>
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography variant="body2">
                  <FormattedMessage id="contact_person" />
                </Typography>
              </Grid>
              <Grid item md={10}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {xwwData.createdBy} Tlf. 905 88 934
                </Typography>
              </Grid>
            </Grid>
            <Grid container item md={4}>
              <Grid item md={4} className={classes.mdBox}>
                <Typography variant="body2">
                  <FormattedMessage id="published_from_met" />
                </Typography>
              </Grid>
              <Grid item md={8}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {moment(xwwData.published)
                    .tz(currentTimezone)
                    .format("DD.MM.YYYY HH:mm zz")}
                </Typography>
              </Grid>

              <NextForecastTime
                color={xwwData.alert_level}
                time={xwwData.next_forecast_time}
              />
            </Grid>

            <Grid container item md={8} className={classes.box}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <FormattedMessage id="max_in_storm" />
                </Typography>
              </Grid>

              <Grid container item md={5} className={classes.emc_box}>
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    EMC
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "17px",
                      letterSpacing: "0.25px",
                      textAlign: "right",
                    }}
                  >
                    {xwwData.max_emc_direction}° / {xwwData.max_emc} m
                  </Typography>
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "10px",
                      lineHeight: "16px",
                      letterSpacing: "0.25px",
                      textAlign: "right",
                    }}
                  >
                    <FromToDateString
                      from={xwwData.max_emc_date_from}
                      to={xwwData.max_emc_date_to}
                    />
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item md={6} className={classes.emc_box}>
                <Grid item xs={6} lg={6} style={{ paddingRight: "2em" }}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "bold", textAlign: "right" }}
                  >
                    <FormattedMessage id="wind" />
                  </Typography>
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "10px",
                      lineHeight: "16px",
                      letterSpacing: "0.25px",
                      textAlign: "right",
                    }}
                  >
                    [10m/10min]
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      lineHeight: "17px",
                      letterSpacing: "0.25px",
                      textAlign: "right",
                    }}
                  >
                    {xwwData.max_wind_direction}° / {xwwData.max_wind}{" "}
                    <FormattedMessage id="wind_unit" />
                  </Typography>
                  <Typography
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "10px",
                      lineHeight: "16px",
                      letterSpacing: "0.25px",
                      textAlign: "right",
                    }}
                  >
                    <FromToDateString
                      from={xwwData.max_wind_date_from}
                      to={xwwData.max_wind_date_to}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={4}>
              <Grid item xs={12}>
                <Typography>
                  <FormattedMessage id="awareness_level" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AlertBox color={xwwData.alert_level} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={9}
          lg={12}
          className={classes.box}
        ></Grid>
      </Grid>
    </Container>
  )
}

export default SummeryBox
