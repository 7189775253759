import { AppRoutesEnergyCustomer } from "AppRoutesEnergyCustomer"
import { AppRoutesOffshore } from "AppRoutesOffshoreCustomer"
import { FooterEnergy } from "components/FooterEnergy"
import { FooterOffshore } from "components/FooterOffshore"
import { useUserProducts } from "components/UserProductsProvider"
import EmptyFrontPage from "./pages/FrontPageEmpty"

export function AppRoutes() {
  const userProducts = useUserProducts()
  const { products = [], customerType } = userProducts
  if (customerType === "offshore") {
    return (
      <>
        <AppRoutesOffshore />
        <FooterOffshore />
      </>
    )
  }
  if (products.length === 0) {
    return (
      <>
        <EmptyFrontPage />
        <FooterEnergy />
      </>
    )
  }
  return (
    <>
      <AppRoutesEnergyCustomer />
      <FooterEnergy />
    </>
  )
}
