import moment from "moment"

import { config } from "../../app-luna-frontend/src/config"

// Parse strings into numbers
export const parseCriteriaData = (criterias: RawCriteria[]) => {
  return criterias.map(({ criteriaByDegree, ...rest }) => ({
    ...rest,
    criteriaByDegree: criteriaByDegree.map((str) => Number.parseFloat(str)),
  })) as Criteria[]
}

// Parse strings into dates and numbers where relevant.
export function parseXWWForecastData(rawForecast: RawXWWForecast): XWWForecast {
  const { forecast, meta, ...rest } = rawForecast
  const { id, longitude, latitude, ...restOfStation } = meta.station
  return {
    ...rest,
    meta: {
      ...meta,
      run: moment.utc(meta.run),
      station: {
        id: Number.parseInt(id),
        latitude: Number.parseFloat(latitude),
        longitude: Number.parseFloat(longitude),
        ...restOfStation,
      },
    },
    forecast: forecast.map(({ valid, data }) => ({
      valid: moment.utc(valid),
      data: Object.entries(data).reduce((acc, [key, value]) => {
        acc[key] = Number.parseFloat(value)
        return acc
      }, {} as any),
    })),
  }
}

interface XWWReport {
  id: string
  data: {
    xwwData: XWWData
    qubaData: RawXWWForecast
    criteriaData: RawCriteria[]
  }
  dataSchemaVersion: string
}

export interface XWWReportData {
  xwwData: XWWData
  qubaData: XWWForecast
  criteriaData: Criteria[]
}

interface XWWOutlookReport {
  id: string
  data: {
    xwwOutlookData: XWWOutlookData
  }
  dataSchemaVersion: string
}

export interface XWWOutlookReportData {
  xwwOutlookData: XWWOutlookData
}

export async function getXWWReportData(
  position: string,
  configValues?: {
    reportId?: string
  }
): Promise<XWWReportData> {
  const { reportId = "latest" } = configValues || {}
  return fetch(
    `${config.api.lunaUrl}/api/v1/products/xww/reports/${position}/${reportId}`,
    {
      headers: {
        authorization: await authorizationHeader(),
      },
    }
  )
    .then(checkStatus)
    .then((res) => res.json())
    .then(
      ({
        dataSchemaVersion,
        data: { qubaData, criteriaData, xwwData },
      }: XWWReport) => {
        if (Number.parseInt(dataSchemaVersion) !== 1) {
          throw new Error(
            `Unexpected data schema version: ${dataSchemaVersion}`
          )
        }
        return {
          criteriaData: parseCriteriaData(criteriaData),
          qubaData: parseXWWForecastData(qubaData),
          xwwData,
        }
      }
    )
}

const checkStatus = (res: Response) => {
  if (res.status >= 200 && res.status < 300) {
    return res
  } else {
    var error = new Error("Not found")
    throw error
  }
}

export async function getXWWOutlookReportData(
  position: string,
  configValues?: {
    reportId?: string
  }
): Promise<XWWOutlookReportData> {
  const { reportId = "latest" } = configValues || {}
  return fetch(
    `${config.api.lunaUrl}/api/v1/products/xwwoutlook/reports/${position}/${reportId}`,
    {
      headers: {
        authorization: await authorizationHeader(),
      },
    }
  )
    .then(checkStatus)
    .then((res) => res.json())
    .then(
      ({ dataSchemaVersion, data: { xwwOutlookData } }: XWWOutlookReport) => {
        if (Number.parseInt(dataSchemaVersion) !== 1) {
          throw new Error(
            `Unexpected data schema version: ${dataSchemaVersion}`
          )
        }
        return {
          xwwOutlookData,
        }
      }
    )
}

declare global {
  interface Window {
    configuredKeycloak: Keycloak.KeycloakInstance
  }
}

const authorizationHeader = async (): Promise<string> => {
  if (process.env.NODE_ENV === "test") {
    return "" // do nothing when testing
  }
  const keycloak = window.configuredKeycloak
  try {
    // Refreshes if soon to be expired
    const refreshed = await keycloak.updateToken(60)
    if (refreshed) {
      console.log("Token was successfully refreshed")
    }
  } catch (err) {
    console.log("Failed to refresh the token, or the session has expired")
  }
  return "Bearer " + keycloak.token
}

export async function getImage(path: string): Promise<string> {
  return fetch(`${config.api.lunaUrl}${path}`, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then(checkStatus)
    .then((res) => res.blob())
    .then((res) => URL.createObjectURL(res))
}
