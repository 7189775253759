import { useState, useEffect } from "react"
import { fetchMessageAlertsJSON } from "services/dataFetcher"
import { updateLocalStorageMessages } from "services/messageStorageHelper"
import { MessageAlert } from "./MessageType"
import { Message } from "./Message"

interface Props {
  messages: MessageAlert[] | undefined
}

type MessageProps = {
  autoRefresh?: true
}

export const useMessages = (props?: MessageProps) => {
  const { autoRefresh } = props || {}
  const [messages, setMessages] = useState<MessageAlert[] | undefined>([])

  useEffect(() => {
    const activeMessageData = () => {
      fetchMessageAlertsJSON()
        .then((data) => {
          setMessages(data)
          updateLocalStorageMessages(data)
        })
        .catch((err: Error) => {
          const message = "Failed to load user messages"
          console.log(message, err)
        })
    }
    activeMessageData()

    if (!autoRefresh) {
      return
    }

    const intervalID = setInterval(activeMessageData, 1000 * 60 * 10) // ten minute intervals.
    return () => {
      clearInterval(intervalID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh])

  return { messages }
}

export const MessageAlerts: React.FC<Props> = ({ messages }: Props) => {
  if (!messages) {
    return null
  }

  return (
    <>
      {messages.map(
        (msg) =>
          // message is only stored in localstorage if it is closed
          !window.localStorage.getItem(msg.id) && (
            <Message
              key={msg.id}
              id={msg.id}
              type={msg.type}
              valid_from={msg.valid_from}
              valid_to={msg.valid_to}
              en_GB={msg.en_GB}
              no_NB={msg.no_NB}
            />
          )
      )}
    </>
  )
}
