import { Box, ThemeProvider, Theme } from "@mui/material"
import "moment"
import "moment-timezone"
import "moment/locale/nb"
import React, { useCallback, useEffect, useMemo } from "react"
import { IntlProvider } from "react-intl"
import { createLunaTheme } from "./createLunaTheme"
import Header from "./Header"
import {
  useAppLocale,
  useAppTimezone,
  useAppWindUnit,
} from "./LunaAppContext.hook"
import { TimezoneContext, TimezoneID } from "./timezoneContext"
import { LoggedInUserContext } from "./loggedInUserContext"
import { WindUnitContext } from "./WindUnitContext"

interface Props {
  /**
   * Activate to specify that the app is in a preview mode,
   * this locks language to 'nb' and timezone to 'Europe/Oslo'.
   *
   * @deprecated use 'type' prop instead.
   */
  previewMode?: boolean

  /**
   * 'preview' enables preview mode, this locks language
   * to 'nb' and timezone to 'Europe/Oslo'.
   *
   * 'energy' and 'offshore' toggles type of Header to show.
   *
   * Default Header to show is 'offshore' if no property is
   * specified.
   */
  type?: "preview" | "energy" | "offshore"

  loggedInUser?: {
    username?: string
    firstName?: string
    lastName?: string
  }
  /**
   * When embedded within another application that uses
   * Material UI we give the consumer a chance to control
   * how the inner-theme will affect the outer.
   *
   * See also:
   * https://material-ui.com/styles/advanced/#theme-nesting
   */
  overrideTheme?: ({
    defaultTheme,
    lunaTheme,
  }: {
    defaultTheme: any
    lunaTheme: Theme
  }) => Theme
}

const lunaTheme = createLunaTheme()

/**
 * Extract necessary logic to support previewing components
 * from components such as the EXWW graph.
 */
export const LunaAppContext: React.FC<Props> = ({
  overrideTheme,
  type = "offshore",
  previewMode,
  loggedInUser,
  children,
}) => {
  const configuredTheme = overrideTheme
    ? (defaultTheme: Theme) => overrideTheme({ defaultTheme, lunaTheme })
    : lunaTheme

  const isPreviewActive = useMemo(() => {
    return type === "preview" || previewMode
  }, [type, previewMode])

  const {
    locale,
    messages,
    handleLocaleChange: handleLocaleChangeBase,
  } = useAppLocale()

  useEffect(() => {
    window.document.documentElement.lang = locale
  }, [])

  const handleLocaleChange = useCallback(() => {
    if (isPreviewActive) {
      return // do nothing
    }
    handleLocaleChangeBase()
  }, [handleLocaleChangeBase])

  const {
    currentTimezone: currentTimezoneBase,
    setCurrentTimezone: setCurrentTimezoneBase,
  } = useAppTimezone()

  const setCurrentTimezone = useCallback(
    (timezone: TimezoneID) => {
      if (isPreviewActive) {
        return // do nothing
      }
      setCurrentTimezoneBase(timezone)
    },
    [setCurrentTimezoneBase]
  )

  const { windUnit, handleWindUnitChange } = useAppWindUnit()

  const configuredLocale = useMemo(() => {
    if (isPreviewActive) {
      return "nb"
    }
    return locale
  }, [locale, type, previewMode])

  const currentTimezone = useMemo(() => {
    if (["preview", "energy"].includes(type) || previewMode) {
      return "UTC"
    }
    return currentTimezoneBase
  }, [currentTimezoneBase, type, previewMode])

  return (
    <ThemeProvider theme={configuredTheme}>
      <LoggedInUserContext.Provider
        value={{
          username: loggedInUser?.username || "No username",
          firstName: loggedInUser?.firstName || "No firstName",
          lastName: loggedInUser?.lastName || "No lastName",
        }}
      >
        <TimezoneContext.Provider
          value={{ currentTimezone, setCurrentTimezone }}
        >
          <IntlProvider
            locale={configuredLocale}
            messages={messages[configuredLocale]}
          >
            <WindUnitContext.Provider
              value={{ windUnit, handleWindUnitChange }}
            >
              <Header type={type} handleLocaleChange={handleLocaleChange} />
              <Box
                style={{
                  backgroundColor: lunaTheme.palette.background.default,
                }}
              >
                {children}
              </Box>
            </WindUnitContext.Provider>
          </IntlProvider>
        </TimezoneContext.Provider>
      </LoggedInUserContext.Provider>
    </ThemeProvider>
  )
}
