import { authorizationHeader, checkStatus } from "./dataFetcher"
import { XMLParser } from "fast-xml-parser"
import { config } from "../config"
import { DateTime } from "luxon"

export async function getMetComment(path: string): Promise<string> {
  return fetch(`${config.api.lunaUrl}${path}`, {
    headers: {
      authorization: await authorizationHeader(),
    },
    cache: "no-store",
  })
    .then(checkStatus)
    .then((res) => res.text())
}

export async function getLastModifiedDate({
  url,
}: {
  url: string
}): Promise<{ lastModified: DateTime | null }> {
  return fetch(url, {
    headers: {
      authorization: await authorizationHeader(),
    },
    method: "HEAD",
  })
    .then(checkStatus)
    .then((res) => {
      const lastModified = res.headers.get("last-modified")
      if (lastModified === null) {
        return { lastModified }
      }
      return { lastModified: DateTime.fromHTTP(lastModified) }
    })
    .catch((err) => {
      console.log(`Failed to fetch url '${url}', error was`, err)
      throw err
    })
}

export async function getXMLAsJSON<T>(endpoint: string): Promise<T> {
  return fetch(endpoint)
    .then(checkStatus)
    .then((res) => res.text())
    .then((text) => {
      const parser = new XMLParser()
      const result = parser.parse(text)
      if (Object.keys(result).length === 0) {
        throw new Error(`Got empty XML data from ${endpoint}`)
      }
      return result
    })
}
