export enum WeatherElements {
  WIND_SPEED_10_M = "wind_speed",
  WIND_GUST_10_M = "max(wind_speed_of_gust PT10M)",
  WAVE_PERIOD_MEAN = "sea_surface_wave_mean_period",
  WAVE_PERIOD_PEAK = "sea_surface_wave_period_at_variance_spectral_density_maximum",
  SIGNIFICANT_WAVE_HEIGHT = "sea_surface_wave_significant_height",
  MEAN_WAVE_DIRECTION = "sea_surface_wave_from_direction",
  MAX_WAVE_HEIGHT = "sea_surface_wave_maximum_height",
  SWELL = "sea_surface_swell_wave_significant_height",
  WIND_DIRECTION = "wind_from_direction",
  AIR_TEMPERATURE = "air_temperature",
  DEW_POINT_TEMPERATURE = "dew_point_temperature",
  SEA_TEMPERATURE = "sea_surface_temperature",
  PRESSURE = "air_pressure_at_sea_level",
}

// converted using https://transform.tools/json-to-typescript
export interface ObservedDataBase {
  data: ObservedData
}

export interface ObservedData {
  tstype: string
  tseries: TSeries[]
}

export interface TSeries {
  header: Header
  observations: Observation[]
}

export interface Header {
  id: Id
  extra: Extra
  available: Available
}

export interface Id {
  level: number
  parameterid: number
  sensor: number
  stationid: number
}

export interface Extra {
  element: Element
  station: Station
  timeseries: Timeseries
}

export interface Element {
  description: string
  id: string
  name: string
  unit: string
}

export interface Station {
  location: Location[]
  shortname: string
}

export interface Location {
  from: string
  to: string
  value: Value
}

export interface Value {
  "elevation(masl/hs)": string
  latitude: string
  longitude: string
}

export interface Timeseries {
  geometry?: Geometry
  quality: Quality
  timeoffset: string
  timeresolution: string
}

export interface Geometry {
  level: Level
}

export interface Level {
  value: string
  unit?: string
}

export interface Quality {
  exposure: Exposure[]
  performance: Performance[]
}

export interface Exposure {
  from: string
  to: string
  value: string
}

export interface Performance {
  from: string
  to: string
  value: string
}

export interface Available {
  from: string
}

export interface Observation {
  time: string
  body: Body
}

export interface Body {
  qualitycode: string
  value: string
}
