import { Box, Grid, List, Typography as T } from "@mui/material"
import { DownloadListItem } from "components/DownloadListItem"
import { useUserProducts } from "components/UserProductsProvider"
import { FormattedMessage } from "react-intl"

export const DownloadPage = () => {
  const userProducts = useUserProducts()
  const { data_downloads = [] } = userProducts
  const hasDownload =
    userProducts.hasProduct("data_download") && data_downloads.length > 0

  return (
    <>
      <Box pb={4}>
        <Box marginBottom={4}>
          <T variant="h1">
            <FormattedMessage id="downloaddata" />
          </T>
        </Box>

        {hasDownload ? (
          <Grid container>
            <Grid item md={6}>
              <List dense>
                {data_downloads.map((download) => (
                  <DownloadListItem key={download.id} item={download} />
                ))}
              </List>
            </Grid>
          </Grid>
        ) : (
          <T variant="body1">
            Du har ikke nedlasting konfigurert. Kontakt meteorologisk institutt
            om du ønsker dette.
          </T>
        )}
      </Box>
    </>
  )
}
