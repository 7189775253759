import { useState, Dispatch, SetStateAction } from "react"
import { ColumnID } from "./OffshoreTable"
import {
  loadJSONFromLocalStorage,
  addLocalStoragePersistence,
} from "../../../services/localStorageService"

export type CriteriaTypes = "yellow" | "red"

export type ActiveCriteria = {
  red: {
    [K in ColumnID]: string
  }
  yellow: {
    [K in ColumnID]: string
  }
}

export type SetActiveCriteria = Dispatch<SetStateAction<ActiveCriteria>>

export const useOffshoreTableCriteriaState = () => {
  const activeCriteriaFromStorage = loadJSONFromLocalStorage<ActiveCriteria>({
    key: "LunaOffshoreTableActiveCriteria",
    returnIfNull: {
      yellow: {},
      red: {},
    } as ActiveCriteria,
  })
  const [activeCriteria, setActiveCriteria] = useState<ActiveCriteria>(
    activeCriteriaFromStorage as ActiveCriteria
  )
  return {
    activeCriteria,
    setActiveCriteria: addLocalStoragePersistence<ActiveCriteria>({
      key: "LunaOffshoreTableActiveCriteria",
      setStateFunc: setActiveCriteria,
    }),
  }
}
export interface DisabledColumns {
  [columnId: string]: boolean
}

export const useDisabledColumns = () => {
  const key = "LunaDisabledColumns"

  const [disabledColumns, setDisabledColumns] = useState<DisabledColumns>(
    loadJSONFromLocalStorage<DisabledColumns>({
      key,
      returnIfNull: {} as DisabledColumns,
    })!
  )

  return {
    disabledColumns,
    setDisabledColumns: addLocalStoragePersistence({
      key,
      setStateFunc: setDisabledColumns,
    }),
  }
}
