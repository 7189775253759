import React from "react"
import { Typography, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from "react-intl"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      verticalAlign: "top",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      letterSpacing: "0.25px",
      alignItems: "center",
      borderColor: "#B1B0B0",
      borderWidth: "0.5px",
      borderStyle: "solid",
      borderRadius: "10px",
      padding: "10px",
      marginTopp: theme.spacing(4),
      marginRight: "2rem",
    },
    alertLevelYellow: {
      backgroundColor: "#E1C500",
      color: "black",
    },
    alertLevelGreen: {
      backgroundColor: "#00832C",
      color: "white",
    },
    alertLevelWhite: {
      backgroundColor: "white",
      color: "black",
    },
    alertLevelBlack: {
      backgroundColor: "black",
      color: "white",
    },
    alertLevelPurple: {
      backgroundColor: "purple",
      color: "white",
    },
    alertLevelRed: {
      backgroundColor: "red",
      color: "black",
    },
  })
)

interface Props {
  color: string
}

const AlertBox: React.FC<Props> = ({ color }) => {
  const classes = useStyles()

  switch (color.toUpperCase()) {
    case "GULT": {
      return (
        <Typography className={`${classes.root} ${classes.alertLevelYellow}`}>
          <FormattedMessage id={color} />
        </Typography>
      )
    }

    case "GRØNT": {
      return (
        <Typography className={`${classes.root} ${classes.alertLevelGreen}`}>
          <FormattedMessage id={color} />
        </Typography>
      )
    }

    case "HVITT": {
      return (
        <Typography className={`${classes.root} ${classes.alertLevelWhite}`}>
          <FormattedMessage id={color} />
        </Typography>
      )
    }

    case "RØDT": {
      return (
        <Typography className={`${classes.root} ${classes.alertLevelRed}`}>
          <FormattedMessage id={color} />
        </Typography>
      )
    }

    case "SVART": {
      return (
        <Typography className={`${classes.root} ${classes.alertLevelBlack}`}>
          <FormattedMessage id={color} />
        </Typography>
      )
    }

    case "LILLA": {
      return (
        <Typography className={`${classes.root} ${classes.alertLevelPurple}`}>
          <FormattedMessage id={color} />
        </Typography>
      )
    }

    default: {
      return <Typography>&nbsp;</Typography>
    }
  }
}

export default AlertBox
