import { FormattedMessage } from "react-intl"
import { FooterBase } from "./FooterBase"

export const FooterEnergy = () => {
  return (
    <FooterBase
      sections={[
        {
          title: "",
          points: [{ email: "luna@met.no" }],
        },
        {
          title: <FormattedMessage id="mfc_technical_contact" />,
          points: [{ email: "luna-help@met.no" }],
        },
      ]}
    />
  )
}
