import { Paper, Box, Grid, Typography, Container } from "@mui/material"
import { Radar } from "components/Radar/Radar"
import { useUserProducts } from "components/UserProductsProvider"
import { FormattedMessage } from "react-intl"

export const WeatherRadarPage = () => {
  const userProducts = useUserProducts()

  const hasRadar = userProducts.hasProduct("radar")
  const { radars } = userProducts

  return (
    <>
      <Container>
        <Box sx={{ px: 3 }}>
          <Typography variant="h2" component="h1">
            <FormattedMessage id="weatherradar" />
          </Typography>
          <Grid
            direction="row"
            container
            spacing={2}
            justifyContent="space-between"
          >
            {hasRadar && radars.length > 0 ? (
              radars.map((radar, i) => {
                return (
                  <Grid item sm={12} md={6} key={radar.id}>
                    <Paper variant="elevation">
                      <Box padding={4} marginTop={4}>
                        <Typography variant="subtitle1" component="h3">
                          <Radar radar={radar} index={i} />
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                )
              })
            ) : (
              <Typography variant="body1">
                Du har ikke værradar konfigurert. Kontakt meteorologisk
                institutt om du ønsker dette.
              </Typography>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  )
}
