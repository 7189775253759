import { MapProductID } from "components/UserProductIDs.service"
import { useMemo } from "react"
import { AccumulatedRainMap } from "./AccumulatedRainMap"
import { ChanceOfRainMap } from "./ChanceOfRainMap"
import { EquivalentPotentialTemperatureMap } from "./EquivalentPotentialTemperatureMap"
import { HumidityMap } from "./HumidityMap"
import { JetWindsMap } from "./JetWindsMap"
import { Precipitation3hMap } from "./Precipitation3hMap"
import { Precipitation6hMap } from "./Precipitation6hMap"
import { PrecipitationMap } from "./PrecipitationMap"
import { Temperature850Map } from "./Temperature850Map"
import { TemperatureMap } from "./TemperatureMap"
import { WindMap } from "./WindMap"

type MapDefinition = {
  url: string
  title: string
  Map: React.FC
}

export const mapDefinitions: Record<MapProductID, MapDefinition> = {
  accumulated_rain: {
    url: "/accumulated-rain",
    title: "map_acc_precip_map",
    Map: AccumulatedRainMap,
  },
  chance_of_rain: {
    url: "/chance-of-rain",
    title: "probability_precipitation",
    Map: ChanceOfRainMap,
  },
  temperature850: {
    url: "/temperature850",
    title: "map_temperature_850hPa",
    Map: Temperature850Map,
  },
  temperature: {
    url: "/temperature",
    title: "map_temperature",
    Map: TemperatureMap,
  },
  jet_winds: {
    url: "/jet-winds",
    title: "map_jet_winds",
    Map: JetWindsMap,
  },
  humidity: {
    url: "/humidity",
    title: "map_humidity",
    Map: HumidityMap,
  },
  precipitation: {
    url: "/precipitation",
    title: "map_precipitation",
    Map: PrecipitationMap,
  },
  precipitation3h: {
    url: "/precipitation3h",
    title: "map_precip_3h",
    Map: Precipitation3hMap,
  },
  precipitation6h: {
    url: "/precipitation6h",
    title: "map_precip_6h",
    Map: Precipitation6hMap,
  },
  wind: {
    url: "/wind",
    title: "map_wind",
    Map: WindMap,
  },
  equivalPotTemp: {
    url: "/equivalpottemperature",
    title: "map_eq_pot_temp_850",
    Map: EquivalentPotentialTemperatureMap,
  },
}

type UseActiveMapDefinitions = (productIds: MapProductID[]) => Array<{
  id: MapProductID
  value: MapDefinition
}>

export const useActiveMapDefinitions: UseActiveMapDefinitions = (
  productIds
) => {
  const activeMapDefinitions = useMemo(() => {
    return (
      Object.entries(mapDefinitions)
        .reduce((acc, item) => {
          const [id, value] = item as [MapProductID, MapDefinition]
          if (productIds.includes(id)) {
            acc.push({ id, value })
          }
          return acc
        }, [] as ReturnType<UseActiveMapDefinitions>)
        // Sort the definitions by the displayed title of the Map
        .sort((a, b) => {
          if (a.value.title < b.value.title) {
            return -1
          } else if (a.value.title > b.value.title) {
            return 1
          }
          return 0
        })
    )
    // Converting simple array to string to avoid unecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds.join("-")])
  return activeMapDefinitions
}
