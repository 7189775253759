import { Box, CircularProgress, Grid, Slider } from "@mui/material"
import { Pause, PlayArrow } from "@mui/icons-material"
import { useEffect, useMemo, useRef, useState } from "react"
import { NumberParam, useQueryParam, withDefault } from "use-query-params"
import { BorderedIconButton } from "../../BorderedIconButton"

type SliderProps = Parameters<typeof Slider>[0]

type Props = {
  loading?: boolean
  imageList: ImagePair[] | string[]
  marks?: SliderProps["marks"]
  queryParam?: string
}

export const ImagePlayer = ({
  imageList,
  loading,
  marks,
  queryParam = "step",
}: Props) => {
  const [sliderIndex, setSliderIndex] = useQueryParam(
    queryParam,
    withDefault(NumberParam, 0)
  )
  const [isPlaying, setIsPlaying] = useState(false)
  const timerRef = useRef<number>()

  const processedMarks = useMemo(() => {
    return marks
  }, [marks])

  useEffect(() => {
    if (!isPlaying) {
      clearInterval(timerRef.current)
      return
    }
    timerRef.current = window.setInterval(function loopTime() {
      setSliderIndex((val) => {
        if (val === null || val === undefined || val >= imageList.length - 1) {
          return 0
        }
        return val + 1
      }, "replaceIn")
    }, 500)
    return () => {
      clearInterval(timerRef.current)
    }
  }, [imageList.length, isPlaying, setSliderIndex])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <BorderedIconButton
          onClick={() => setIsPlaying((prev) => !prev)}
          aria-label={
            isPlaying
              ? "stop automatisk bytting av bildefiler"
              : "start automatisk bytting av bildefiler"
          }
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </BorderedIconButton>
      </Grid>
      <Grid item xs>
        <Box marginTop={1} marginLeft={3} marginRight={-3}>
          <Slider
            marks={processedMarks}
            value={sliderIndex}
            onChange={(_, newValue) => {
              if (sliderIndex === newValue) {
                // Prevent unecessary updates
                return
              }

              // Unsure when newValue will be an array of numbers.
              if (Array.isArray(newValue)) {
                setSliderIndex(newValue[0], "replaceIn")
                return
              }
              setSliderIndex(newValue, "replaceIn")
            }}
            min={0}
            max={imageList.length - 1}
            step={1}
            getAriaValueText={(value) => `Image number ${value}`}
            aria-labelledby="discrete-slider-always"
          />
        </Box>
      </Grid>
    </Grid>
  )
}
