import { FormattedMessage } from "react-intl"
import { FooterBase } from "./FooterBase"

export const FooterOffshore = () => {
  return (
    <FooterBase
      sections={[
        {
          title: <FormattedMessage id="mfc_title" />,
          points: [{ email: "mfc@met.no" }, { phone: "+47 90 28 12 68" }],
        },
        {
          title: <FormattedMessage id="mfc_technical_contact" />,
          points: [{ email: "luna-help@met.no" }],
        },
      ]}
    />
  )
}
