import { useCallback } from "react"
import { getAvailableRadars } from "services/dataFetcher"
import { useQuery } from "react-query"
import { DateTime } from "luxon"

export const isSequenceRadarType = (
  elem: Date[] | RadarOptions["type"][]
): elem is RadarOptions["type"][] => {
  return isSequenceElementRadarType(elem[0])
}

export const isSequenceElementRadarType = (
  elem: Date | RadarOptions["type"]
): elem is RadarOptions["type"] => {
  return typeof elem === "string" && !!elem
}

export const useRadar = (radarOptions: RadarSequence) => {
  const { area, type, content, time, sequence, product_name } = radarOptions

  const loadFileData = useCallback(async () => {
    if (!area || !type || !content) {
      console.error(
        " missing values for area, type or content" + area + type + content
      )
      return []
    }
    const data = []

    let compareDate: DateTime = DateTime.utc()
      .minus({
        day: 1,
      })
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 })

    if (isSequenceRadarType(sequence) && sequence.length > 1) {
      for (const elem of sequence) {
        try {
          const radarData = await getAvailableRadars({
            area,
            type: elem,
            content,
            time,
          })

          compareDate = compareDate.plus({ hour: 1 })

          for (let i = 0; i < radarData.length; i++) {
            if (compareDate.toISO() === radarData[i].params.time.toISO()) {
              data.push(radarData[i])
            }
          }
        } catch (error) {
          console.error(`${area + type + content + time} are missing `, error)
        }
      }
    } else {
      const radarData = await getAvailableRadars({
        area,
        type,
        content,
        time,
      })
      let isGottenComparisonDate = false
      let firstDate: DateTime = DateTime.utc()

      data.push(
        ...radarData.filter((radar, index) => {
          const {
            params: { time: radarTime },
          } = radar
          const radarDateTime = DateTime.fromISO(radarTime)
          const { day: radarDay } = radarDateTime
          // only save the first date we encounter
          if (!isGottenComparisonDate && !firstDate) {
            firstDate = radarDateTime
            isGottenComparisonDate = true
          }

          const { day } = firstDate
          if (day !== radarDay) {
            // if the date isnt the one mentioned, keep the image!
            return true
          }

          // is the image from earlier in the day than specified? Skip it!
          return radarDateTime && radarDateTime < time
        })
      )
    }

    return data
  }, [area, type, content, sequence, time])

  const cacheKey = [
    "Maps",
    product_name,
    area,
    type,
    content,
    time.toString(),
    ...sequence,
  ]
  const images = useQuery(cacheKey, loadFileData, {
    cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  return images
}
