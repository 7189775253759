import React from "react"
import * as L from "leaflet"
import "leaflet-graticule"
import "leaflet/dist/leaflet.css"
import placeSVG from "../images/place.svg"
export default class LeafletMap extends React.Component<ActiveForecastSummaryList> {
  componentDidMount() {
    let map = L.map("map").setView([58, 0], 5)
    const customIcon = L.icon({
      iconUrl: placeSVG,
      iconSize: [32, 42],
      iconAnchor: [13, 24],
    })

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map)

    L.latlngGraticule({
      showLabel: true,
      zoomInterval: [
        { start: 2, end: 3, interval: 30 },
        { start: 4, end: 4, interval: 10 },
        { start: 5, end: 6, interval: 5 },
        { start: 7, end: 10, interval: 1 },
      ],
    }).addTo(map)

    for (let i in this.props) {
      let pos = this.props[i]
      L.marker([pos.latitude, pos.longitude], {
        title: pos.station,
        icon: customIcon,
      })
        .addTo(map)
        .bindTooltip(`<b>${pos.station}</b>`, {
          permanent: true,
          direction: "right",
          offset: [0, 0],
        })
        .bindPopup(pos.station)
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "600px",
          }}
          id="map"
        />
      </>
    )
  }
}
