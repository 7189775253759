import {
  Box,
  Container,
  Grid,
  Paper,
  styled,
  Typography as T,
} from "@mui/material"

const PurplePaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textAlign: "center",
}))

export default function EmptyFrontPage() {
  return (
    <Container>
      <Box py={4}>
        <Grid container spacing={4}>
          <Grid item>
            <PurplePaper>
              <Box py={5}>
                <Grid container direction="row" justifyContent="center">
                  <Grid item sm={8}>
                    <T variant="h3" component="h1" paragraph>
                      Our research activity at The Norwegian Meteorological
                      Institute transforms science into world-class operational
                      services.
                    </T>
                    <T variant="body2">
                      Contact mfc@met.no for inquiries related to operational
                      services.
                    </T>
                  </Grid>
                </Grid>
              </Box>
            </PurplePaper>
          </Grid>

          <Grid item container direction="row" spacing={2}>
            <Grid item md={4}>
              <Paper style={{ height: "100%" }}>
                <Box padding={4}>
                  <T variant="h4" component="h2" paragraph>
                    Offshore/Maritime sector
                  </T>
                  <T variant="body2" paragraph>
                    Our weather products developed through four decades of close
                    contact with oil companies and other participants in the
                    maritime sector help secure and enhance maritime operations.
                    We (The Norwegian Meteorological Institute) participate in
                    selected tenders and ad hoc inquiries related to such
                    maritime operations.
                  </T>
                  <T variant="body2" paragraph>
                    The weather forecasting is performed at our maritime
                    specialised division in Bergen, the Division for Forecasting
                    Western Norway (Vervarslinga på Vestlandet).
                  </T>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4}>
              <Paper style={{ height: "100%" }}>
                <Box padding={4}>
                  <T variant="h4" component="h2" paragraph>
                    Renewable energy sector
                  </T>
                  <T variant="body2" paragraph>
                    We (The Norwegian Meteorological Institute) participate in
                    selected tenders and ad hoc inquiries related to the energy
                    sector, especially for renewables needing high-quality
                    forecasts of weather parameters for energy production and to
                    secure daily operations. Our strategy is to keep and further
                    develop our expertise in weather forecasting assisting the
                    government together with the industry to reach a more
                    sustainable society.
                  </T>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4}>
              <Paper style={{ height: "100%" }}>
                <Box padding={4}>
                  <T variant="h4" component="h2" paragraph>
                    Other sectors
                  </T>
                  <T variant="body2" paragraph>
                    In general, we are interested in assignments increasing our
                    professional development that will benefit our services
                    towards the general public and governmental partners.
                  </T>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
