import { useEffect, useState } from "react"

type Input = {
  queryParam: "openDialog" | string
}

/**
 * Purpose: When doing things like closing a full screen dialog
 * we need to re-render certain components. This hook helps us
 * to that by using the key property on components like so:
 *
 * const increment = useIncrementWhenChanged({queryParam: 'openDialog'})
 *
 * // Changing the key property will force a re-render.
 * <button key={increment} />
 */
export const useIncrementWhenChanged = ({ queryParam }: Input) => {
  const [increment, setIncrement] = useState(0)

  const val = new URLSearchParams(window.location.search).get(queryParam) || ""

  useEffect(() => {
    setIncrement((prevVal) => prevVal + 1)
  }, [val])

  return { increment }
}
