import { SvgIconProps } from "@mui/material"
import React from "react"

export const LunaLogo: React.FC<SvgIconProps> = (props) => {
  return (
    <svg
      width="60"
      height="48"
      viewBox="0 0 60 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Luna logo</title>
      <path
        d="M2.07227 0H5.06827V16.8H13.6363V19.798H2.07227V0Z"
        fill="white"
      />
      <path
        d="M18.5669 13.9717C18.5669 15.0842 18.8469 15.9044 19.4069 16.4323C19.9669 16.9414 20.6856 17.196 21.5629 17.196C22.4402 17.196 23.1589 16.9414 23.7189 16.4323C24.2789 15.9044 24.5589 15.0842 24.5589 13.9717V5.20404H27.4989V19.798H24.5589V18.4121C24.4469 18.6195 24.2789 18.8269 24.0549 19.0343C23.8496 19.2417 23.5976 19.4209 23.2989 19.5717C23.0002 19.7226 22.6642 19.8451 22.2909 19.9394C21.9362 20.0337 21.5722 20.0808 21.1989 20.0808C20.5829 20.0808 19.9389 19.9677 19.2669 19.7414C18.6136 19.5151 18.0162 19.1569 17.4749 18.6667C16.9336 18.1764 16.4856 17.5542 16.1309 16.8C15.7949 16.0269 15.6269 15.103 15.6269 14.0283V5.20404H18.5669V13.9717Z"
        fill="white"
      />
      <path
        d="M34.2941 19.798H31.3541V5.20404H34.2941V6.47677C34.4061 6.26936 34.5648 6.07138 34.7701 5.88283C34.9941 5.67542 35.2555 5.4963 35.5541 5.34545C35.8528 5.17576 36.1795 5.04377 36.5341 4.94949C36.9075 4.85522 37.2808 4.80808 37.6541 4.80808C38.2701 4.80808 38.9141 4.91178 39.5861 5.11919C40.2768 5.3266 40.9021 5.67542 41.4621 6.16566C42.0221 6.63704 42.4795 7.25926 42.8341 8.03232C43.2075 8.78653 43.3941 9.72929 43.3941 10.8606V19.798H40.4541V10.9172C40.4541 9.80471 40.1648 8.99394 39.5861 8.48485C39.0261 7.9569 38.2888 7.69293 37.3741 7.69293C36.4595 7.69293 35.7128 7.9569 35.1341 8.48485C34.5741 8.99394 34.2941 9.80471 34.2941 10.9172V19.798Z"
        fill="white"
      />
      <path
        d="M52.5655 7.66465C51.8375 7.66465 51.2121 7.83434 50.6895 8.17374C50.1855 8.51313 49.8308 9.03165 49.6255 9.72929H46.6295C46.7601 8.76768 47.0308 7.96633 47.4415 7.32525C47.8521 6.68417 48.3375 6.18451 48.8975 5.82626C49.4761 5.44916 50.0828 5.18519 50.7175 5.03434C51.3708 4.8835 51.9868 4.80808 52.5655 4.80808C53.2001 4.80808 53.8628 4.90236 54.5535 5.09091C55.2628 5.26061 55.9161 5.57172 56.5135 6.02424C57.1108 6.47677 57.6055 7.09899 57.9975 7.89091C58.3895 8.66397 58.5855 9.65387 58.5855 10.8606V19.798H55.6455V18.4121C55.1788 18.9778 54.5815 19.402 53.8535 19.6848C53.1255 19.9488 52.2855 20.0808 51.3335 20.0808C50.6801 20.0808 50.0548 19.996 49.4575 19.8263C48.8601 19.6566 48.3375 19.402 47.8895 19.0626C47.4415 18.7044 47.0775 18.2519 46.7975 17.705C46.5361 17.1582 46.4055 16.5077 46.4055 15.7535C46.4055 15.3387 46.4521 14.9145 46.5455 14.4808C46.6575 14.0471 46.8348 13.6323 47.0775 13.2364C47.3201 12.8404 47.6375 12.4822 48.0295 12.1616C48.4401 11.8222 48.9441 11.5582 49.5415 11.3697C50.4188 11.1057 51.3428 10.9266 52.3135 10.8323C53.2841 10.7192 54.3855 10.6155 55.6175 10.5212V10.4929C55.5428 9.53131 55.2255 8.82424 54.6655 8.37172C54.1241 7.90034 53.4241 7.66465 52.5655 7.66465ZM51.8095 17.2242C52.2201 17.2242 52.6495 17.1865 53.0975 17.1111C53.5641 17.0168 53.9841 16.8566 54.3575 16.6303C54.7308 16.3852 55.0388 16.0552 55.2815 15.6404C55.5241 15.2067 55.6455 14.6505 55.6455 13.9717V13.1515C54.3575 13.2458 53.2375 13.3589 52.2855 13.4909C51.3335 13.604 50.5868 13.8209 50.0455 14.1414C49.8588 14.2545 49.6908 14.4148 49.5415 14.6222C49.4108 14.8296 49.3455 15.1219 49.3455 15.499C49.3455 16.1024 49.5601 16.5455 49.9895 16.8283C50.4188 17.0923 51.0255 17.2242 51.8095 17.2242Z"
        fill="white"
      />
      <path
        d="M47.0526 48L35.6842 36L24.3158 48L12.9474 36L2.84211 46.6667L0 43.6667L12.9474 30L24.3158 42L35.6842 30L47.0526 42L57.1579 31.6667L60 34.6667L47.0526 48Z"
        fill="white"
      />
    </svg>
  )
}
