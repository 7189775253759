import { GetApp } from "@mui/icons-material"
import {
  CircularProgress,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography as T,
} from "@mui/material"
import { useMemo, useState } from "react"
import { downloadFile } from "services/downloadFile"
import { useFileLastModified } from "./useFileLastModified"
import { config } from "config"

type Props = {
  item: DataDownload
}

export const DownloadListItem = ({
  item: { file_or_url, link_title },
}: Props) => {
  const [busy, setBusy] = useState(false)

  const { url, filename } = useMemo(() => {
    if (file_or_url.startsWith("http")) {
      const segments = file_or_url.split("/")
      const filename = segments[segments.length - 1]
      return { url: file_or_url, filename }
    }
    const filename: string = file_or_url
    return {
      url: `${config.api.filesUrl}/api/v1/products/customerfile/files/${file_or_url}`,
      filename,
    }
  }, [file_or_url])

  const { lastModified } = useFileLastModified({ url })

  return (
    <ListItem disableGutters>
      <ListItemButton
        divider
        sx={{
          backgroundColor: "#fff",
        }}
        onClick={(e) => {
          if (busy) {
            return // do nothing
          }
          setBusy(true)
          downloadFile({
            url,
            fallbackFilename: filename,
          }).finally(() => setBusy(false))
        }}
      >
        <ListItemText>
          <Grid container spacing={2}>
            <Grid item>
              <T
                variant="body1"
                fontWeight={700}
                sx={{
                  wordBreak: "break-all",
                }}
              >
                {link_title || file_or_url}
              </T>
            </Grid>
            {lastModified && <Grid item>{lastModified}</Grid>}
          </Grid>
        </ListItemText>
        <ListItemIcon sx={{ flexDirection: "row-reverse" }}>
          {busy ? (
            <CircularProgress
              sx={{
                width: "24px !important",
                height: "24px !important",
              }}
            />
          ) : (
            <GetApp
              sx={{
                width: "24px",
                height: "24px",
                color: (theme) => theme.palette.primary.dark,
              }}
            />
          )}
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  )
}
