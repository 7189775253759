import { SeriesLineOptions, SeriesWindbarbOptions } from "highcharts"
import {
  barbedOffset,
  defaultLineFormat,
  defaultWindbarbFormat,
  getWindDirectionVisibillity,
  yOffsetWindPlotLine,
} from "./graphOptionsUtils"
import { GraphConfig } from "../../../../@types/OffshoreGrapOption"
import {
  OFFSHORE_DATA_ELEMENTS,
  lunaColors,
  stack,
  staticLineConfig,
  staticBarbConfig,
  yAxis,
  obsMarkerConfig,
} from "./graphStaticValues"

export const ObservedBaseOffset = 40

export const ObservedOptionsConfig = (): GraphConfig => {
  let baseOffset = yOffsetWindPlotLine + ObservedBaseOffset
  let yOffsetWindArrow = yOffsetWindPlotLine + ObservedBaseOffset
  baseOffset = getWindDirectionVisibillity()
    ? baseOffset + barbedOffset
    : baseOffset
  let yOffsetWaveArrow = baseOffset
  // can be added when we get swell arrows for obs
  /*   baseOffset = getSwellDirectionVisibillity()
    ? baseOffset + barbedOffset
    : baseOffset
  let yOffsetSwellArrow = baseOffset */

  return {
    observed_wind_from_direction: {
      series: {
        ...staticBarbConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_wind_from_direction,
        color: lunaColors.wind_from_direction,
        stack: stack.wind_obs,
        vectorLength: 20,
        lineWidth: 1.5,
        dataGrouping: {
          enabled: true,
          groupPixelWidth: 40,
        },
        yOffset: yOffsetWindArrow,
      } as SeriesWindbarbOptions,
      tools: {
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_wind_speed,
          directionId: OFFSHORE_DATA_ELEMENTS.observed_wind_from_direction,
        },
        formatData: defaultWindbarbFormat,
      },
    },
    observed_wind_speed: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_wind_speed,
        color: lunaColors.observed_wind_speed,
        yAxis: yAxis.wind,
        stack: stack.wind_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_wind_speed,
          directionId: OFFSHORE_DATA_ELEMENTS.observed_wind_from_direction,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_max_wind_speed_10m: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_max_wind_speed_10m,
        color: lunaColors.wind_speed_of_gust_10_meter,
        yAxis: yAxis.wind,
        stack: stack.wind_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "wind_unit",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_max_wind_speed_10m,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_sea_surface_wave_mean_period: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_mean_period,
        color: lunaColors.sea_surface_wave_mean_period_TM02,
        yAxis: yAxis.period,
        stack: stack.swell_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "s",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_mean_period,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_sea_surface_wave_period_at_variance_spectral_density_maximum: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_period_at_variance_spectral_density_maximum,
        color: lunaColors.sea_surface_wave_peak_period,
        yAxis: yAxis.period,
        stack: stack.swell_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "s",
          },
        },
        values: {
          valueId:
            OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_period_at_variance_spectral_density_maximum,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_sea_surface_wave_significant_height: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_significant_height,
        color: lunaColors.sea_surface_wave_significant_height,
        yAxis: yAxis.wave,
        stack: stack.comb_sea_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "m",
          },
        },
        values: {
          valueId:
            OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_significant_height,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_sea_surface_maximum_wave_height: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_maximum_wave_height,
        color: lunaColors.sea_surface_maximum_wave_height,
        yAxis: yAxis.wave,
        stack: stack.comb_sea_obs,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "m",
          },
        },
        values: {
          valueId:
            OFFSHORE_DATA_ELEMENTS.observed_sea_surface_maximum_wave_height,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_sea_surface_significant_swell_wave_height: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_significant_swell_wave_height,
        color: lunaColors.sea_surface_significant_swell_wave_height,
        yAxis: yAxis.wave,
        stack: stack.comb_sea_obs,
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "m",
          },
          direction: {
            decimalPoints: 0,
            unit: "°",
          },
        },
        values: {
          valueId:
            OFFSHORE_DATA_ELEMENTS.observed_sea_surface_significant_swell_wave_height,
          directionId:
            OFFSHORE_DATA_ELEMENTS.observed_sea_surface_swell_wave_to_direction,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_sea_surface_wave_from_direction: {
      series: {
        ...staticBarbConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_from_direction,
        color: lunaColors.sea_surface_wave_to_direction,
        stack: stack.comb_sea_obs,
        vectorLength: 20,
        lineWidth: 2,
        dataGrouping: {
          enabled: true,
          groupPixelWidth: 30,
        },
        yOffset: yOffsetWaveArrow,
      } as SeriesWindbarbOptions,
      tools: {
        values: {
          directionId:
            OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_from_direction,
        },
        formatData: defaultWindbarbFormat,
      },
    },
    observed_air_pressure_at_sea_level: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_air_pressure_at_sea_level,
        color: lunaColors.air_pressure_at_sea_level,
        yAxis: yAxis.pressure,
        stack: stack.other_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "hPa",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_air_pressure_at_sea_level,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_air_temperature: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_air_temperature,
        color: lunaColors.air_temperature,
        negativeColor: lunaColors.air_temperature_minus,
        yAxis: yAxis.temp,
        stack: stack.temperature_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "°C",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_air_temperature,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_dew_point_temperature: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_dew_point_temperature,
        color: lunaColors.dew_point_temperature,
        yAxis: yAxis.temp,
        stack: stack.temperature_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "°C",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_dew_point_temperature,
        },
        formatData: defaultLineFormat,
      },
    },
    observed_sea_surface_temperature: {
      series: {
        ...staticLineConfig,
        ...obsMarkerConfig,
        id: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_temperature,
        color: lunaColors.sea_surface_temperature,
        yAxis: yAxis.temp,
        stack: stack.temperature_obs,
        dashStyle: "ShortDot",
      } as SeriesLineOptions,
      tools: {
        tooltipOptions: {
          value: {
            decimalPoints: 1,
            unit: "°C",
          },
        },
        values: {
          valueId: OFFSHORE_DATA_ELEMENTS.observed_sea_surface_temperature,
        },
        formatData: defaultLineFormat,
      },
    },
  }
}
