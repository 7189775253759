import { useEffect, useState } from "react"
import ReactDOM from "react-dom"

type Props = {
  // One or more documentIDs to choose from.
  where: "right-above-footer"
}

/**
 * Useful if you have some content that you want to "break" out
 * where it would be normally shown.
 */
export const BreakOutPortal: React.FC<Props> = ({ children, where }) => {
  const [mountPoint, setMountPoint] = useState<HTMLDivElement>()
  useEffect(() => {
    const parentElem = document.querySelector(`#${where}`)
    if (!parentElem) {
      throw new Error(`BreakOutPortal could not find mountpoint #'${where}'`)
    }
    // Must create a new child-element to mount unto because multiple
    // react portals cannot mount the same div.
    const childElem = document.createElement("div")
    parentElem.appendChild(childElem)
    setMountPoint(childElem)
    return () => {
      parentElem.removeChild(childElem)
    }
  }, [where])
  if (!mountPoint) {
    return null
  }
  return ReactDOM.createPortal(children, mountPoint)
}
