import { Grid, Typography, styled } from "@mui/material"
import { Component, ErrorInfo } from "react"

interface State {
  eventId?: string
  hasError?: boolean
  error?: Error
}

const GridResponsive = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    root: {
      padding: theme.spacing(2),
    },
  },
  [theme.breakpoints.up("sm")]: {
    root: {
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(8),
    },
  },
}))

export default class ErrorBoundary extends Component {
  state: State = {}

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo)
  }

  render() {
    const { hasError, error } = this.state
    if (hasError) {
      return (
        <GridResponsive container spacing={2}>
          <Grid item sm={6}>
            <Typography variant={"h6"}>
              <p>Hei! En feil har dessverre inntruffet.</p>
              <p>Feilmeldingen var: {error && error.message}</p>
            </Typography>
          </Grid>
        </GridResponsive>
      )
    }

    //when there's not an error, render children untouched
    return this.props.children
  }
}
