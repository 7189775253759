import React from "react"
import moment, { Moment } from "moment"
import { useTimezone } from "@luna/luna-core"
import { FormattedMessage, useIntl } from "react-intl"

interface Props {
  valid: Moment | string
}

const LastPublishedWhen: React.FC<Props> = ({ valid }) => {
  const { currentTimezone } = useTimezone()
  const intl = useIntl()
  const parsedDate = typeof valid === "string" ? moment(valid) : valid
  if (!valid) {
    return (
      <>
        <strong>
          <FormattedMessage id="valid_from" />:
        </strong>{" "}
        Date unavailable
      </>
    )
  }
  return (
    <>
      <strong>
        <FormattedMessage id="valid_from" />
        {": "}
      </strong>
      {parsedDate
        .locale(intl.locale)
        .tz(currentTimezone)
        .format("DD.MM.YYYY HH:mm zz")}
    </>
  )
}

export default LastPublishedWhen
