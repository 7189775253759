import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  CircularProgress,
  Grid,
  Slider,
  Typography,
  useTheme,
} from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import { getCurrentEPSData } from "services/offshore.service"
import HighchartsReact from "highcharts-react-official"
import OceanCurrentHighcharts from "./OceanCurrentHighcharts"
import { useIncrementWhenChanged } from "components/useIncrementWhenChanged"
import CurrentRose, { CurrentData } from "components/CurrentRose/CurrentRose"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Pause, PlayArrow } from "@mui/icons-material"
import { BorderedIconButton } from "components/BorderedIconButton"
import moment from "moment"
import { useTimezone } from "@luna/luna-core"

type Props = { current?: Current }
export const OceanCurrent: React.FC<Props> = ({ current }) => {
  const [currentSliderValue, setCurrentSliderValue] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const { currentTimezone } = useTimezone()
  const intl = useIntl()
  const theme = useTheme()

  const chartRef = useRef<HighchartsReact.RefObject>()

  const [currentEPSForecast, setCurrentEPSForecast] =
    useState<OffshoreForecast | undefined>()

  const isTablet = useMediaQuery("(max-width: 1024px)")

  const { increment } = useIncrementWhenChanged({ queryParam: "openDialog" })

  useEffect(() => {
    if (isPlaying) {
      const intervalID = setInterval(() => {
        setCurrentSliderValue((prev) => {
          if (
            currentEPSForecast &&
            prev === currentEPSForecast.forecast.length - 1
          ) {
            return 0
          }
          return prev + 1
        })
      }, 600)
      return () => clearInterval(intervalID)
    }
  }, [isPlaying, currentEPSForecast])

  useEffect(() => {
    const loadCurrentEPSData = async () =>
      current &&
      getCurrentEPSData(current.interpol_pos_id)
        .then((data) => {
          setCurrentEPSForecast(data)
        })
        .catch((err: Error) => {
          const message = "Failed to load CurrentEPSForecast Data."
          console.log(message, err)
        })
    loadCurrentEPSData()

    const intervalID = setInterval(loadCurrentEPSData, 1000 * 60 * 10) // ten minute intervals.
    return () => {
      clearInterval(intervalID)
    }
  }, [current])

  if (!currentEPSForecast) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }
  const filterByDist = (items: any, n: number) => {
    const totalItems = items.length
    const selectedItems = []

    // Keep first element
    selectedItems.push(items[0])

    const interval = Math.floor((totalItems - 2) / (n + 1))

    // Spread rest of elements evenly-ish
    for (let i = 1; i <= n; i++) {
      const index = i * interval
      selectedItems.push(items[index])
    }

    // Keep last element
    selectedItems.push(items[totalItems - 1])

    return selectedItems
  }

  const filteredData = filterByDist(
    currentEPSForecast.forecast,
    isTablet ? 2 : 7
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2">
          <FormattedMessage id="ocean_current" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{current?.title}</Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <CurrentRose
          data={
            currentEPSForecast?.forecast?.[currentSliderValue]
              ?.data as CurrentData
          }
        />
      </Grid>
      <Grid item sx={{ backgroundColor: theme.palette.common.white }}>
        <BorderedIconButton
          onClick={() => setIsPlaying((prev) => !prev)}
          aria-label={
            isPlaying
              ? "stop avspilling av størmrose-data"
              : "start avspilling av størmrose-data"
          }
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </BorderedIconButton>
      </Grid>
      <Grid item xs sx={{ backgroundColor: theme.palette.common.white }}>
        <Box>
          <Slider
            value={currentSliderValue}
            onChange={(_, value) => setCurrentSliderValue(value as number)}
            min={0}
            max={currentEPSForecast.forecast.length - 1}
            step={1}
            valueLabelFormat={(value) =>
              moment(currentEPSForecast.forecast[value].valid)
                .locale(intl.locale)
                .tz(currentTimezone)
                .format("DD MMM YYYY HH:mm")
            }
            valueLabelDisplay="auto"
            sx={{
              my: 2,
            }}
            marks={
              isTablet
                ? [
                    {
                      value: 0,
                      label: moment(currentEPSForecast?.forecast?.[0].valid)
                        .locale(intl.locale)

                        .tz(currentTimezone)
                        .format("DD/MM/YY HH:mm"),
                    },
                    {
                      value: currentEPSForecast.forecast.length - 1,
                      label: moment(
                        currentEPSForecast.forecast?.[
                          currentEPSForecast.forecast.length - 1
                        ].valid
                      )
                        .locale(intl.locale)

                        .tz(currentTimezone)
                        .format("DD/MM/YY HH:mm"),
                    },
                  ]
                : (currentEPSForecast.forecast
                    .map((e, index) =>
                      index % 18 === 0
                        ? {
                            value: index,
                            label: moment(e.valid)
                              .locale(intl.locale)
                              .tz(currentTimezone)
                              .format("DD MMM YY HH:mm"),
                          }
                        : false
                    )
                    .filter(Boolean) as { value: number; label: string }[])
            }
            getAriaValueText={(value) => `Strømdata nummer ${value}`}
            aria-labelledby="discrete-slider-always"
          />
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ backgroundColor: theme.palette.common.white }}>
        <OceanCurrentHighcharts
          key={increment}
          ref={chartRef}
          apiData={currentEPSForecast}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%", // Ensure full width
          }}
        >
          {filteredData.map(({ valid, data }) => (
            <Box
              key={valid}
              sx={{
                px: 2,
                ml: 2,
                py: 2,
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              <CurrentRose data={data as CurrentData} minified={true} />
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}
