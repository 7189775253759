import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import { Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import { IconButton } from "@mui/material"
import H from "../images/synopmapicons/hoytrykk.png"
import L from "../images/synopmapicons/lavtrykk.png"
import YR from "../images/synopmapicons/yr.png"
import Regnbyger from "../images/synopmapicons/regnbyger.png"
import Snøbyger from "../images/synopmapicons/snobyger.png"
import Tåke from "../images/synopmapicons/toke.png"
import Torden from "../images/synopmapicons/torden.png"
import Sluddbyger from "../images/synopmapicons/sluddbyge.png"
import Underkjolt_yr from "../images/synopmapicons/underkjolt_yr.png"
import Underkjolt_regn from "../images/synopmapicons/underkjolt_regn.png"
import Byger from "../images/synopmapicons/byger.png"
import Regn from "../images/synopmapicons/regn.png"
import Haglbyge from "../images/synopmapicons/haglbyge.png"
import Tordenvær_med_hagl from "../images/synopmapicons/tordenvar_med_hagl.png"
import Snø from "../images/synopmapicons/sno.png"
import Tropisk_syklon from "../images/synopmapicons/tropisk_syklon.png"
import F3 from "../images/synopmapicons/f3.png"
import S4 from "../images/synopmapicons/s4.png"
import Varmfront from "../images/synopmapicons/varmfront.png"
import Kaldfront from "../images/synopmapicons/kaldfront.png"
import Okklusjon from "../images/synopmapicons/okklusjon.png"
import { FormattedMessage } from "react-intl"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

const TableOfSymbols = styled("table")(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  borderSpacing: 0,
  "& tr td": {
    borderBottom: "1px solid black",
  },
  "& tr:last-of-type td": {
    borderBottom: "transparent",
  },
  "& td": {
    padding: theme.spacing(2),
    height: "80px",
  },
  "& tr td:first-child": {
    borderRight: "1px solid black",
    width: "60px",
    textAlign: "center",
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          aria-label="close"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function SymbolExplanation() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        style={{ height: "43px" }}
        variant="outlined"
        size="large"
        onClick={handleClickOpen}
      >
        <FormattedMessage id="symbol_explanation" />
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={false}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <FormattedMessage id="symbol_explanation" />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom component="div">
            <Grid container spacing={2} justifyContent="space-around">
              <Grid sm={12} md={3} item>
                <TableOfSymbols>
                  <tbody>
                    <tr>
                      <td>
                        <img src={H} alt="stor bokstav H" />
                      </td>
                      <td>
                        <FormattedMessage id="high_pressure" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={L} alt="stor bokstav L" />
                      </td>
                      <td>
                        <FormattedMessage id="low_pressure" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src={Regnbyger}
                          alt="grønn sirkel over grønn trekant som peker ned"
                        />
                      </td>
                      <td>
                        <FormattedMessage id="rain_showers" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src={Snøbyger}
                          alt="grønn snøkrystall over trekant som peker ned"
                        />
                      </td>
                      <td>
                        <FormattedMessage id="snow_showers" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Tåke} alt="tre grå horisontale striper" />
                      </td>
                      <td>
                        <FormattedMessage id="fog" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Torden} alt="Rød R med pil inni." />
                      </td>
                      <td>
                        <FormattedMessage id="thunder" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={YR} alt="Grønne sitatmerker" />
                      </td>
                      <td>
                        <FormattedMessage id="drizzle" />
                      </td>
                    </tr>
                  </tbody>
                </TableOfSymbols>
              </Grid>
              <Grid sm={12} md={3} item>
                <TableOfSymbols>
                  <tbody>
                    <tr>
                      <td>
                        <img src={Sluddbyger} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="wintry_showers" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Underkjolt_yr} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="freezing_drizzle" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Underkjolt_regn} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="freezing_rain" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Byger} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="showers" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Regn} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="rain" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Haglbyge} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="hail_showers" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Tordenvær_med_hagl} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="thunder_with_hail" />
                      </td>
                    </tr>
                  </tbody>
                </TableOfSymbols>
              </Grid>
              <Grid sm={12} md={4} item>
                <TableOfSymbols>
                  <tbody>
                    <tr>
                      <td>
                        <img src={Snø} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="snow" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Tropisk_syklon} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="tropical_cyclone" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={F3} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="f3_pressure_decrease" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={S4} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="s3_pressure_increase" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Varmfront} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="warm_front" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Kaldfront} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="cold_front" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={Okklusjon} alt="" />
                      </td>
                      <td>
                        <FormattedMessage id="occluded_front" />
                      </td>
                    </tr>
                  </tbody>
                </TableOfSymbols>
              </Grid>
            </Grid>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}
