import React from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { AlertBox } from "@luna/luna-xww"
import { Link } from "components/Link"

interface ForecastProps {
  props: rowProps
}

interface rowProps {
  title: string
  uri: string
  datetime: string
  alertColor?: string
}

const SpecialForecastListRow: React.FC<ForecastProps> = ({ props }) => {
  const theme = useTheme()

  const colorGray = "#555555"

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.background.default}`,
        padding: 2,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={9}
      >
        <Grid item xs={12} sm={5}>
          <Link
            to={props.uri}
            variant={"body1"}
            color="inherit"
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            <Typography>{props.title}</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant="body2" style={{ color: colorGray }}>
            {props.datetime}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={3} sm={3}>
        {alertBox(props)}
      </Grid>
    </Grid>
  )
}

function alertBox(props: rowProps) {
  if (props.alertColor) {
    return <AlertBox color={props.alertColor} />
  } else {
    return <></>
  }
}

export default SpecialForecastListRow
