import { Box, Grid, InputLabel, Typography, Paper } from "@mui/material"
import { FullscreenDialog } from "components/FullscreenDialog"
import { FullscreenDialogButton } from "components/FullscreenDialogButton"
import RadioButtons from "components/RadioButtons"

import { useMemo } from "react"
import { createEnumParam, useQueryParams, withDefault } from "use-query-params"
import { useMapDetails } from "./JetWindsMap.service"

import { MapRenderImages } from "./MapRenderer/MapRenderImages"
import { useSpecificMaps, getMapsFromData } from "./AvailableMaps.service"
import { RenderAvailableChildren } from "components/WeatherMaps/MapRenderer/RenderAvailableChildren"
import { useIntl } from "react-intl"
/**
 * This component shows two Weather images side by side. And
 * lets you compare them.
 */
export const JetWindsMap = () => {
  const [query, setQuery] = useQueryParams({
    map: withDefault(createEnumParam(["ec00", "ec12"]), "ec00"),
  })
  const intl = useIntl()
  const map = query.map as "ec00" | "ec12"

  const { mapType } = useMapDetails({ ec: map })
  const { data, isSuccess, isLoading } = useSpecificMaps(mapType)
  const images = getMapsFromData(mapType, data)

  const mapTitle = useMemo(() => {
    return `ECMWF ${map === "ec00" ? "00" : "12"} UTC ${intl.formatMessage({
      id: "map_jet_wind",
    })} 300 hPa`
  }, [map, intl])

  return (
    <>
      <Box pb={2}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h2" component="h1">
              {mapTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item>
                <InputLabel id="select-map-label">
                  {intl.formatMessage({
                    id: "show_model",
                  })}
                  :
                </InputLabel>
              </Grid>
              <Grid item>
                <RadioButtons
                  buttons={[
                    { value: "ec00", label: "EC00" },
                    { value: "ec12", label: "EC12" },
                  ]}
                  onChange={(value: any) =>
                    setQuery({ map: value }, "replaceIn")
                  }
                  selected={map}
                />
              </Grid>
              <Grid item>
                <FullscreenDialogButton
                  type="minimal"
                  dialogID="fullscreen-map"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <RenderAvailableChildren
        images={images}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <FullscreenDialog
              title={mapTitle}
              id="fullscreen-map"
              showChildrenAlsoWhenClosed
            >
              <MapRenderImages imageURLs={images}>
                {(selectedImage) => {
                  return <img alt="kart over vind" src={selectedImage} />
                }}
              </MapRenderImages>
            </FullscreenDialog>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Typography variant="subtitle1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_explanation",
                })}
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_jetstream_exp_text",
                })}
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                {intl.formatMessage({
                  id: "map_jetstream_exp_text_wind",
                })}
                :
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_jetstream_exp_text_wind_long",
                })}
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", padding: 10 }}
              >
                {intl.formatMessage({
                  id: "map_jetstream_exp_text_geopot",
                })}
                :
              </Typography>
              <Typography variant="body1" style={{ padding: 10 }}>
                {intl.formatMessage({
                  id: "map_jetstream_exp_text_geopot_long",
                })}
                .
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </RenderAvailableChildren>
    </>
  )
}
