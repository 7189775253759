import { SeriesVisibility } from "../../../../@types/OffshoreGrapOption"

export const observedId = "observed"
export const observedAffix = observedId + "_"

export const lunaColors = {
  sea_surface_wave_significant_height: "#2416C7",
  sea_surface_wave_to_direction: "#2416C7",
  sea_surface_maximum_wave_height: "#ADBFFF",
  almostBlack: "#323232",
  veryLight: "rgba(96, 98, 113, 0.2)",
  transparent: "rgba(255,255,255,0 )",
  sea_surface_significant_swell_wave_height: "#25BEFF",
  sea_surface_swell_wave_to_direction: "#25BEFF",
  air_temperature: "#EA684B",
  air_temperature_minus: "#4BE0EA",
  dew_point_temperature: "#FFBA53",
  sea_surface_temperature: "#0d78c6",
  wind_from_direction: "#000000",
  wind_speed_10_meter: "#000000",
  wind_speed_50_meter: "#797575",
  wind_speed_100_meter: "#6A00BD",
  wind_speed_150_meter: "#DBAEFF",
  wind_speed_of_gust_10_meter: "#000000",
  wind_speed_of_gust_50_meter: "#797575",
  wind_speed_of_gust_100_meter: "#6A00BD",
  wind_speed_of_gust_150_meter: "#DBAEFF",
  gust_speed_50_meter: "#D1D1D1",
  observed_wind_speed: "#000000",
  air_pressure_at_sea_level: "#6DE879",
  sea_surface_wave_mean_period_TM02: "#6A00BD",
  sea_surface_wave_peak_period: "#DBAEFF",
  sea_surface_swell_wave_period: "#E965FF",
  sea_surface_elevation: "#009D94",
  plotLine: "#E0E0E0",
  observed_plot_bands: "#F8F7FD",
}

export enum OFFSHORE_DATA_ELEMENTS {
  wind_speed_10_meter = "wind_speed_10_meter",
  wind_speed_50_meter = "wind_speed_50_meter",
  wind_speed_100_meter = "wind_speed_100_meter",
  wind_speed_150_meter = "wind_speed_150_meter",
  // wind_speed_200_meter = "wind_speed_200_meter",
  // wind_speed_250_meter = "wind_speed_250_meter",
  // wind_speed_300_meter = "wind_speed_300_meter",
  sea_surface_wave_to_direction = "sea_surface_wave_to_direction",
  sea_surface_swell_wave_to_direction = "sea_surface_swell_wave_to_direction",
  wind_from_direction = "wind_from_direction",
  wind_from_direction_50_meter = "wind_from_direction_50_meter",
  wind_from_direction_100_meter = "wind_from_direction_100_meter",
  wind_from_direction_150_meter = "wind_from_direction_150_meter",
  // wind_from_direction_200_meter = "wind_from_direction_200_meter",
  // wind_from_direction_250_meter = "wind_from_direction_250_meter",
  // wind_from_direction_300_meter = "wind_from_direction_300_meter",
  wind_speed_of_gust_10_meter = "wind_speed_of_gust_10_meter",
  wind_speed_of_gust_50_meter = "wind_speed_of_gust_50_meter",
  wind_speed_of_gust_100_meter = "wind_speed_of_gust_100_meter",
  wind_speed_of_gust_150_meter = "wind_speed_of_gust_150_meter",
  // wind_speed_of_gust_200_meter = "wind_speed_of_gust_200_meter",
  // wind_speed_of_gust_250_meter = "wind_speed_of_gust_250_meter",
  // wind_speed_of_gust_300_meter = "wind_speed_of_gust_300_meter",
  sea_surface_maximum_wave_height = "sea_surface_maximum_wave_height",
  sea_surface_temperature = "sea_surface_temperature",
  sea_surface_wave_mean_period_TM02 = "sea_surface_wave_mean_period_TM02",
  sea_surface_elevation = "sea_surface_elevation",
  weather_symbol_code = "weather_symbol_code",
  weather_symbol_text = "weather_symbol_text",
  air_temperature = "air_temperature",
  dew_point_temperature = "dew_point_temperature",
  sea_surface_significant_swell_wave_height = "sea_surface_significant_swell_wave_height",
  visibility = "visibility",
  visibility_text = "visibility_text",
  sea_surface_wave_peak_period = "sea_surface_wave_peak_period",
  sea_surface_swell_wave_period = "sea_surface_swell_wave_period",
  air_pressure_at_sea_level = "air_pressure_at_sea_level",
  sea_surface_wave_significant_height = "sea_surface_wave_significant_height",
  sea_surface_maximum_wave_factor = "sea_surface_maximum_wave_factor",
  confidence = "confidence",
  weather_symbol = "weather_symbol",

  observed_sea_surface_wave_significant_height = "observed_sea_surface_wave_significant_height",
  observed_wind_speed = "observed_wind_speed",
  observed_max_wind_speed_10m = "observed_max_wind_speed_10m",
  observed_sea_surface_wave_period_at_variance_spectral_density_maximum = "observed_sea_surface_wave_period_at_variance_spectral_density_maximum",
  observed_sea_surface_wave_mean_period = "observed_sea_surface_wave_mean_period",
  observed_air_pressure_at_sea_level = "observed_air_pressure_at_sea_level",
  observed_sea_surface_temperature = "observed_sea_surface_temperature",
  observed_sea_surface_significant_swell_wave_height = "observed_sea_surface_significant_swell_wave_height",
  observed_sea_surface_swell_wave_to_direction = "observed_sea_surface_swell_wave_to_direction",
  observed_air_temperature = "observed_air_temperature",
  observed_dew_point_temperature = "observed_dew_point_temperature",
  observed_sea_surface_maximum_wave_height = "observed_sea_surface_maximum_wave_height",
  observed_wind_from_direction = "observed_wind_from_direction",
  observed_sea_surface_wave_from_direction = "observed_sea_surface_wave_from_direction",
}

export const defaultSeries: SeriesVisibility = {
  [OFFSHORE_DATA_ELEMENTS.wind_speed_10_meter]: true,
  [OFFSHORE_DATA_ELEMENTS.wind_speed_50_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_speed_100_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_speed_150_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_speed_200_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_speed_250_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_speed_300_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_wave_to_direction]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_to_direction]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_from_direction]: true,
  [OFFSHORE_DATA_ELEMENTS.wind_from_direction_50_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_from_direction_100_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_from_direction_150_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_from_direction_200_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_from_direction_250_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_from_direction_300_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_10_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_50_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_100_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_150_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_200_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_250_meter]: false,
  // [OFFSHORE_DATA_ELEMENTS.wind_speed_of_gust_300_meter]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_maximum_wave_height]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_temperature]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_wave_mean_period_TM02]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_elevation]: false,
  [OFFSHORE_DATA_ELEMENTS.weather_symbol_code]: false,
  [OFFSHORE_DATA_ELEMENTS.weather_symbol_text]: false,
  [OFFSHORE_DATA_ELEMENTS.air_temperature]: false,
  [OFFSHORE_DATA_ELEMENTS.dew_point_temperature]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_significant_swell_wave_height]: false,
  [OFFSHORE_DATA_ELEMENTS.visibility]: false,
  [OFFSHORE_DATA_ELEMENTS.visibility_text]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_wave_peak_period]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_swell_wave_period]: false,
  [OFFSHORE_DATA_ELEMENTS.air_pressure_at_sea_level]: false,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_wave_significant_height]: true,
  [OFFSHORE_DATA_ELEMENTS.sea_surface_maximum_wave_factor]: false,
  [OFFSHORE_DATA_ELEMENTS.confidence]: false,
  [OFFSHORE_DATA_ELEMENTS.weather_symbol]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_significant_height]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_wind_speed]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_max_wind_speed_10m]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_period_at_variance_spectral_density_maximum]:
    false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_mean_period]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_air_pressure_at_sea_level]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_temperature]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_significant_swell_wave_height]:
    false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_swell_wave_to_direction]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_air_temperature]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_dew_point_temperature]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_maximum_wave_height]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_wind_from_direction]: false,
  [OFFSHORE_DATA_ELEMENTS.observed_sea_surface_wave_from_direction]: false,
}

export const yAxis = {
  wind: 0,
  wave: 1,
  temp: 2,
  pressure: 3,
  period: 4,
  symbol: 5,
}

export const stack = {
  wind: "wind",
  wind_obs: "wind_obs",
  comb_sea: "comb_sea",
  swell: "swell",
  temperature: "temperature",
  comb_sea_obs: "comb_sea_obs",
  swell_obs: "swell_obs",
  temperature_obs: "temperature_obs",
  other: "other",
  other_obs: "other_obs",
}

export const staticLineConfig = {
  type: "line",
  lineWidth: 2,
}

export const obsMarkerConfig = {
  marker: { enabled: undefined, enabledThreshold: 2 },
}

export const staticBarbConfig = {
  type: "windbarb",
  enableMouseTracking: false,
  states: {
    inactive: {
      opacity: 1,
    },
  },
}
