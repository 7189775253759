import {
  Box,
  Tab,
  Tabs,
  tabsClasses,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import CircularProgress from "@mui/material/CircularProgress"
import {
  HelpOutline,
  History,
  Map,
  ViewModuleOutlined,
  AutoGraph,
  ArrowDownward,
} from "@mui/icons-material"

import invert from "lodash/invert"
import React, { ChangeEvent, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import { getOffshoreReportData } from "../../services/offshore.service"

import { MessageAlerts, useMessages } from "components/Message/MessageAlerts"

import { OffshoreMenuGraph } from "components/Offshore/OffshoreMenu/OffshoreMenuGraph"
import { OffshoreMenuTable } from "components/Offshore/OffshoreMenu/OffshoreMenuTable"
import { OffshoreMenuMaps } from "components/Offshore/OffshoreMenu/OffshoreMenuMaps"
import { OffshoreMenuHistory } from "components/Offshore/OffshoreMenu/OffshoreMenuHistory"
import { OffshoreMenuExplanation } from "components/Offshore/OffshoreMenu/OffshoreMenuExplanation"
import { useUserProducts } from "components/UserProductsProvider"
import { OffshoreMenuCurrent } from "./OffshoreMenu/OffshoreMenuCurrent"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      textAlign: "center",
      verticalAlign: "middle",
      margin: "20em",
    },

    gridContainerItem: {
      backgroundColor: theme.palette.common.white,
    },
  })
)

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Typography>
  )
}

/**
 * Implements vertical icon.
 * Fix found here: https://github.com/mui-org/material-ui/issues/11653#issuecomment-571892544
 */
const TabLabel: React.FC<{ icon: React.FunctionComponent<any> }> = ({
  icon: Icon,
  children,
}) => {
  return (
    <Box py={2}>
      <Icon style={{ verticalAlign: "middle", marginRight: "10px" }} />
      {children}
    </Box>
  )
}

interface Params {
  offshoreSubPage:
    | "summary"
    | "table"
    | "map"
    | "history"
    | "current"
    | "explanation"
  reportId: string
  orderId: string
}

interface TabMap {
  [key: string]: number
}
const tabMap: TabMap = {
  summary: 0,
  table: 1,
  map: 2,
  history: 3,
  current: 4,
  explanation: 5,
}

/**
 * This hook loads report data and re-loads that report data on a given
 * interval.
 */
export const useOffshoreData = () => {
  const [report, setReport] = React.useState<OffshoreReportData | undefined>()
  const { orderId } = useParams<Params>()

  useEffect(() => {
    const loadReportData = (orderId: string) =>
      getOffshoreReportData({ orderId })
        .then((data) => {
          setReport(data)
        })
        .catch((err: Error) => {
          const message = "Failed to load Offshore report."
          console.log(message, err)
          // Re-throw error so it's caught by <ErrorBoundary />
          throw new Error(message)
        })
    loadReportData(orderId)
    const intervalID = setInterval(
      () => loadReportData(orderId),
      1000 * 60 * 10
    ) // ten minute intervals.
    return () => {
      clearInterval(intervalID)
    }
  }, [orderId])
  return { report }
}

const useTabsState = () => {
  const history = useHistory()
  const { offshoreSubPage, orderId } = useParams<Params>()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const handleTabChange = (event: ChangeEvent<{}>, value: any) => {
    const newUrl = invert(tabMap)[value]
    history.push(`/offshore/${newUrl}/${orderId}`)
  }
  useEffect(() => {
    const currentPageIndex = tabMap[offshoreSubPage]
    setTabIndex(currentPageIndex)
  }, [offshoreSubPage, orderId])
  return { tabIndex, handleTabChange }
}

const OffshoreWeatherReport: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { tabIndex, handleTabChange } = useTabsState()
  const { report } = useOffshoreData()
  const { messages } = useMessages({ autoRefresh: true })
  const userProducts = useUserProducts()
  const { currents = [] } = userProducts

  const hasOceanCurrent =
    userProducts.hasProduct("current") && currents.length > 0

  if (!report) {
    return (
      <Box className={classes.progress}>
        <CircularProgress />
      </Box>
    )
  }

  let offshore_current

  if (hasOceanCurrent && currents) {
    offshore_current = currents.find(
      (c) => c.offshore_order === report.offshoreData.order_id
    )
  }

  return (
    <>
      <MessageAlerts messages={messages} />
      <Box
        sx={{
          my: 2,
          mx: 3,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          {...(isDesktop
            ? { variant: "standard" }
            : {
                variant: "scrollable",
              })}
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.22 },
            },
          }}
        >
          <Tab
            sx={{
              backgroundColor: "background.paper",
              minWidth: 120,
            }}
            label={
              <TabLabel icon={AutoGraph}>
                <FormattedMessage id="graph" />
              </TabLabel>
            }
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              backgroundColor: "background.paper",
              marginLeft: 1,
              minWidth: 120,
            }}
            label={
              <TabLabel icon={ViewModuleOutlined}>
                <FormattedMessage id="table" />
              </TabLabel>
            }
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              backgroundColor: "background.paper",
              marginLeft: 1,
              minWidth: 120,
            }}
            label={
              <TabLabel icon={Map}>
                <FormattedMessage id="map" />
              </TabLabel>
            }
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              backgroundColor: "background.paper",
              marginLeft: 1,
              minWidth: 120,
            }}
            label={
              <TabLabel icon={History}>
                <FormattedMessage id="history" />
              </TabLabel>
            }
            {...a11yProps(3)}
          />
          {hasOceanCurrent ? (
            <>
              <Tab
                sx={{
                  backgroundColor: "background.paper",
                  marginLeft: 1,
                  minWidth: 120,
                }}
                label={
                  <TabLabel icon={ArrowDownward}>
                    <FormattedMessage id="ocean_current" />
                  </TabLabel>
                }
                {...a11yProps(4)}
              />
              <Tab
                sx={{
                  backgroundColor: "background.paper",
                  marginLeft: 1,
                  minWidth: 120,
                }}
                label={
                  <TabLabel icon={HelpOutline}>
                    <FormattedMessage id="explanation" />
                  </TabLabel>
                }
                {...a11yProps(5)}
              />
            </>
          ) : (
            <Tab
              sx={{
                backgroundColor: "background.paper",
                marginLeft: 1,
                minWidth: 120,
              }}
              label={
                <TabLabel icon={HelpOutline}>
                  <FormattedMessage id="explanation" />
                </TabLabel>
              }
              {...a11yProps(4)}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <OffshoreMenuGraph report={report} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <OffshoreMenuTable report={report} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <OffshoreMenuMaps />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <OffshoreMenuHistory report={report} />
      </TabPanel>
      {hasOceanCurrent ? (
        <>
          <TabPanel value={tabIndex} index={4}>
            <OffshoreMenuCurrent current={offshore_current} />
          </TabPanel>
          <TabPanel value={tabIndex} index={5}>
            <OffshoreMenuExplanation />
          </TabPanel>
        </>
      ) : (
        <TabPanel value={tabIndex} index={4}>
          <OffshoreMenuExplanation />
        </TabPanel>
      )}
    </>
  )
}

export default OffshoreWeatherReport
