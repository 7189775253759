import React from "react"
import { Theme, Typography } from "@mui/material"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import { FormattedMessage } from "react-intl"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(5),
    },
    header: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(1),
    },
  })
)

interface Props {
  xwwData: XWWData
  intl: string
}

const ForecastSummary: React.FC<Props> = ({ xwwData, intl }) => {
  const locale = intl === "nb" ? "no" : "en"
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.header} variant={"h4"}>
        <FormattedMessage id="weather_description" />
      </Typography>
      <Typography>{xwwData[`message_${locale}`]}</Typography>

      <Typography className={classes.header} variant={"h5"}>
        <FormattedMessage id="max_hs_in_storm" />
      </Typography>
      <Typography>{xwwData[`maxHSmessage_${locale}`]}</Typography>

      <Typography className={classes.header} variant={"h5"}>
        <FormattedMessage id="yellow_alert_level_message" />
      </Typography>
      <Typography>{xwwData[`alertLevelMessage_${locale}`]}</Typography>

      <Typography className={classes.header} variant={"h5"}>
        <FormattedMessage id="gust_over_60_knots" />
      </Typography>
      <Typography>{xwwData[`gustOver60KnotsMessage_${locale}`]}</Typography>

      <Typography className={classes.header} variant={"h5"}>
        <FormattedMessage id="lightning_condition" />
      </Typography>
      <Typography>{xwwData[`lightningMessage_${locale}`]}</Typography>
    </>
  )
}

export default ForecastSummary
