import { LunaAppContext } from "@luna/luna-core"
import { KeycloakProfile } from "keycloak-js"
import "moment"
import "moment-timezone"
import "moment/locale/nb"
import { BrowserRouter, Route } from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import { QueryProvider } from "./components/QueryProvider"
import { AppRoutes } from "./AppRoutes"
import { AppSecurity } from "./AppSecurity"
import ErrorBoundary from "./components/ErrorBoundary"
import { UserLoader } from "./components/UserLoader"
import { UserProductsProvider } from "components/UserProductsProvider"
import AppVersionChecker from "AppVersionChecker"

export default function App() {
  return (
    <ErrorBoundary>
      <AppSecurity>
        <>
          <AppVersionChecker />
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <UserLoader>
                {({ username, firstName, lastName }: KeycloakProfile) => (
                  <UserProductsProvider>
                    {({ customerType }) => (
                      <LunaAppContext
                        type={customerType}
                        loggedInUser={{ username, firstName, lastName }}
                      >
                        <QueryProvider>
                          <AppRoutes />
                        </QueryProvider>
                      </LunaAppContext>
                    )}
                  </UserProductsProvider>
                )}
              </UserLoader>
            </QueryParamProvider>
          </BrowserRouter>
        </>
      </AppSecurity>
    </ErrorBoundary>
  )
}
