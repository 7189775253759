import { CircularProgress } from "@mui/material"
import { DateTime } from "luxon"
import { IntlShape, useIntl } from "react-intl"
import { useQuery } from "react-query"
import { capitalizeFirstLetter } from "services/capitalizeFirstLetter"
import { getXMLAsJSON } from "services/energy.service"

type QueryResult = {
  parameter: {
    name: "content_type" | "product" | "time" | string
    value: string
  }[]
  uri: string
}

type AvailabilityData = {
  available: {
    query: QueryResult[] | QueryResult
  }
}

const endpoints = {
  "analyse-kart": `https://api.met.no/weatherapi/subjectiveforecast/1.0/available?product=analyse_map&content_type=image%2Fpng`,
  "prognose-kart":
    "https://api.met.no/weatherapi/subjectiveforecast/1.0/available?product=prognose_map&content_type=image%2Fpng",
}

type Props = {
  type: "analyse-kart" | "prognose-kart"
  children: (data: {
    latest: QueryResult
    formattedDate: string
  }) => JSX.Element
}

/**
 * XMLForecastLoader handles loading data from and xml endpoint
 * and selecting the most recent image to show. It also formats
 * the date as a properly formatted date.
 */
export const XMLForecastLoader = ({ type, children }: Props) => {
  const result = useQuery(
    [type],
    () => {
      // Select correct endpoint based on specified type. And
      // specify a concrete type for the return type.
      return getXMLAsJSON<AvailabilityData>(endpoints[type])
    },
    {
      cacheTime: 0,
    }
  )
  const intl = useIntl()

  if (result.isError) {
    return <>Failed to load map</>
  }

  if (!result.data) {
    return <CircularProgress />
  }

  const latestImage = findLatestImage(result.data)
  if (!latestImage) {
    return <>No image to load</>
  }

  const date = formatDate({ entry: latestImage, intl })
  return children({ latest: latestImage, formattedDate: date })
}

function findLatestImage(data: AvailabilityData): QueryResult | undefined {
  if (!Array.isArray(data.available.query)) {
    return data.available.query
  }
  return data.available.query[data.available.query.length - 1]
}

function formatDate({
  entry,
  intl,
}: {
  entry: QueryResult
  intl: IntlShape
}): string {
  const { parameter = [] } = entry
  const dateParam = parameter.find(({ name }) => name === "time")
  if (!dateParam) {
    return ""
  }
  const date = DateTime.fromISO(dateParam.value)
  return `${capitalizeFirstLetter(
    date.setLocale(intl.locale).toUTC().toFormat("cccc")
  )} ${date
    .setLocale(intl.locale)
    .toUTC()
    .toLocaleString(DateTime.DATETIME_FULL)}`
}
