import { useCallback } from "react"
import { useQuery } from "react-query"
import { loadLunaFile } from "services/dataFetcher"
import { NumberParam, useQueryParam, withDefault } from "use-query-params"

/**
 * Loads a series of `ImagePairs` using Keycloak token and
 * returns data URIs containing the image data.
 *
 * See also:
 * {@link ./useImageQuery}
 * {@link https://css-tricks.com/data-uris/}
 */
export const useImagePairQuery = (images: ImagePair[]) => {
  const [index] = useQueryParam("step", withDefault(NumberParam, 0))

  const loadFileData = useCallback(async (): Promise<ImagePair[]> => {
    const data = []
    // Load max 2 images at the same time to avoid overloading server.
    for (const pair of images) {
      const [yesterday, today] = await Promise.all([
        pair.yesterday ? loadLunaFile(pair.yesterday) : "",
        pair.today ? loadLunaFile(pair.today) : "",
      ])
      data.push({ yesterday, today })
    }
    return data
  }, [images])

  const cacheKey = ["ImageComparer", (images[0] || { today: "initial" }).today]

  const result = useQuery(cacheKey, loadFileData, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  // Fetch only the image located at the current index.
  const loadInitialFileData = useCallback(async (): Promise<ImagePair[]> => {
    const data: ImagePair[] = []
    const pairToLoad = images[index]
    const [yesterday, today] = await Promise.all([
      pairToLoad.yesterday ? loadLunaFile(pairToLoad.yesterday) : "",
      pairToLoad.today ? loadLunaFile(pairToLoad.today) : "",
    ])
    // Placing the data at the exact index we expect it to be.
    data[index] = { yesterday, today }
    return data
    // Disabling warning here because we do not want to trigger
    // re-render when the index value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images /* index, */])

  const initialCacheKey = [
    "ImageComparerInitial",
    (images[index] || { today: "default" }).today,
  ]

  const initialResult = useQuery(initialCacheKey, loadInitialFileData, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  if (!result.isLoading) {
    return { ...result, isInitialData: false }
  }

  return { ...initialResult, isInitialData: true }
}
