import { CircularProgress, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { getMetComment } from "services/energy.service"

type Props = {
  fileName?: string
}
export const TextForecast = ({ fileName = "MET_Synoptic" }: Props) => {
  const { locale } = useIntl()

  const [metCommentText, setMetCommentText] = React.useState<
    string | undefined
  >()
  const metCommentUrl = `/text/${fileName}-${locale.toUpperCase()}.html`

  useEffect(() => {
    const metCommentText = () =>
      getMetComment(metCommentUrl)
        .then((data) => {
          setMetCommentText(data)
        })
        .catch((err: Error) => {
          const message = "Failed to load Met Comment text"
          console.log(message, err)

          setMetCommentText(
            "Feilmelding: Siden var ikke i stand til å laste inn kommentar fra meteorolog."
          )
        })
    metCommentText()

    const intervalID = setInterval(metCommentText, 1000 * 60 * 10) // ten minute intervals.
    return () => {
      clearInterval(intervalID)
    }
  }, [metCommentUrl])

  if (!metCommentText) {
    return <CircularProgress color="inherit" />
  }

  return (
    <>
      <Typography variant="h3" component="h2" gutterBottom>
        <FormattedMessage id={"met_comment"} />
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: metCommentText }} />
    </>
  )
}
