import { Box, Tab, Tabs, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { getOffshoreMaps } from "../../../services/offshore.service"

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const OffshoreMaps: React.FC = () => {
  const intl = useIntl()

  const [offshoreMaps, setOffshoreMaps] = React.useState<
    Array<string> | undefined
  >()
  useEffect(() => {
    const mapUrls = [
      "/maps/EC_mslp12.png",
      "/maps/EC_mslp36.png",
      "/maps/EC_mslp60.png",
    ]
    getOffshoreMaps(mapUrls)
      .then((data) => {
        setOffshoreMaps(data)
      })
      .catch((err: Error) => {
        const message = "Failed to load offshore maps"
        console.log(message, err)
        throw new Error(message)
      })
  }, [])

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary">
        <Tab label={intl.formatMessage({ id: "analysis" })} />
        <Tab label={`+12${intl.formatMessage({ id: "hour_short" })}`} />
        <Tab label={`+36${intl.formatMessage({ id: "hour_short" })}`} />
        <Tab label={`+60${intl.formatMessage({ id: "hour_short" })}`} />
      </Tabs>
      <TabPanel key={0} value={value} index={0}>
        <img
          src="https://api.met.no/weatherapi/subjectiveforecast/1.0/?product=analyse_map"
          alt="analyse map"
        />
      </TabPanel>
      {offshoreMaps ? (
        offshoreMaps.map((map, index) => (
          <TabPanel key={index + 1} value={value} index={index + 1}>
            <img src={map} alt={index.toString()} />
          </TabPanel>
        ))
      ) : (
        <p>failed to load maps</p>
      )}
    </div>
  )
}
export default OffshoreMaps
