import { authorizationHeader, checkStatus } from "./dataFetcher"
import { DateTime } from "luxon"

/**
 * This is a hack to enable authenticated file downloads
 * we make authenticated requests using Keycloak. Download
 * the file data into the browser before putting it on a link
 * and clicking on it.
 *
 * It first tries to inspect content-disposition for a
 * filename, if that's not present it'll fall back to
 * use the fallbackFilename.
 */
export async function downloadFile({
  url,
  fallbackFilename = "",
}: {
  url: string
  fallbackFilename?: string
}) {
  return fetch(url, {
    headers: {
      authorization: await authorizationHeader(),
    },
  })
    .then(checkStatus)
    .then(async (res) => {
      const filename =
        readFilename(res.headers.get("content-disposition")) || fallbackFilename
      const blob = await res.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = filename
      // we need to append the element to the dom -> otherwise it will not work in firefox
      document.body.appendChild(a)
      a.click()
      //afterwards we remove the element again
      a.remove()
    })
    .catch((err) => {
      console.log(`Failed to fetch url '${url}', error was`, err)
      // re-throwing to be caught by Sentry
      throw err
    })
}

/**
 * Parse filenames from content-disposition headers that look
 * like this: attachment; filename="manedsvarsel.ppt"
 * @param contentDisposition
 * @returns
 */
function readFilename(contentDisposition: string | null): string | undefined {
  if (!contentDisposition) {
    return
  }
  const re = /filename="(.*)"/i
  const match = re.exec(contentDisposition)
  if (match === null) {
    return
  }
  return match[1]
}

/**
 * This is a hack to enable authenticated file downloads
 * we make authenticated requests using Keycloak.
 */
export async function downloadFileMetadata({
  url,
}: {
  url: string
}): Promise<{ lastModified: DateTime | null }> {
  return fetch(url, {
    headers: {
      authorization: await authorizationHeader(),
    },
    method: "HEAD",
    cache: "no-store",
  })
    .then(checkStatus)
    .then((res) => {
      const lastModified = res.headers.get("last-modified")
      if (lastModified === null) {
        return { lastModified }
      }
      return { lastModified: DateTime.fromHTTP(lastModified) }
    })
    .catch((err) => {
      console.log(`Failed to fetch url '${url}', error was`, err)
      // re-throwing to be caught by Sentry
      throw err
    })
}
