export const highchartsLocalizations: Map<string, any> = new Map<string, any>()
highchartsLocalizations.set("en", {
  noData: "There is no data.",
  loading: "Loading...",
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  decimalPoint: ".",
  printChart: "Print diagram",
  numericSymbols: ["k", "M", "G", "T", "P", "E"],
  resetZoom: "Reset zoom",
  resetZoomTitle: "Reset zoom level 1:1",
  thousandsSep: " ",
  plotBands: "test",
})
highchartsLocalizations.set("nb", {
  decimalPoint: ",",
  loading: "Laster...",
  months: [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
  ],
  noData: "Det er ingen data.",
  numericSymbols: ["k", "M", "G", "T", "P", "E"],
  printChart: "Skriv ut diagram",
  resetZoom: "Nullstill zoom",
  resetZoomTitle: "Nullstill zoomnivå 1:1",
  shortMonths: [
    "jan.",
    "feb.",
    "mar.",
    "apr.",
    "mai",
    "jun.",
    "jul.",
    "aug.",
    "sep.",
    "okt.",
    "nov.",
    "des.",
  ],
  shortWeekdays: undefined,
  thousandsSep: " ",
  weekdays: [
    "Søndag",
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
  ],
})
