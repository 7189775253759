import React from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import { Link } from "components/Link"

interface Props {
  oceancurrent: Current
}

export const OceanCurrentListRow: React.FC<Props> = ({ oceancurrent }) => {
  const theme = useTheme()

  const linkTo = "/oceancurrent/" + oceancurrent.interpol_pos_id

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.background.default}`,
        padding: 2,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={9}
      >
        <Grid item xs={12} sm={5}>
          <Link
            to={linkTo}
            variant={"body1"}
            color="inherit"
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            <Typography>{oceancurrent.title}</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}
