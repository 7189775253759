import LoadingSpinner from "components/LoadingSpinner"

type Props = {
  images: any
  isSuccess: boolean
  isLoading: boolean
}
/**
 * This component is intended show children or error-messages based
 * on booleans isSuccess/isLoading or the length of the images-array.
 * It was created to consolidate similar code used in all weatherMaps
 * to more easily enable changing the feedback-message or behavior of all
 * weatherMaps. It is usually positioned in these weatherMaps in order
 * to enable changing button-values and then render the images
 *
 * @param images an array of arbitrary values. Usually MapData[], but this component doesnt need to know anything other than "array"
 * @param isSuccess a boolean usually returned by react-query telling us if the network request completed successfully
 * @param isLoading a boolean telling the component to show the LoadingSpinner
 * @param children the components the component wraps
 *
 * @returns LoadingSpinner, Errormessage or renders children
 */
export const RenderAvailableChildren: React.FC<Props> = ({
  images,
  isSuccess,
  isLoading,
  children,
}) => {
  if (isLoading) {
    return <LoadingSpinner />
  }
  if (!isSuccess) {
    return <> Noe gikk galt </>
  }

  if (images.length === 0) {
    return (
      <> Dette produktet mangler bilder. Kontakt meteorologisk institutt. </>
    )
  }

  return <>{children}</>
}
