import { Box, Paper, Tab, Tabs } from "@mui/material"
import {
  allMapProductIDs,
  isMapProduct,
  MapProductID,
} from "components/UserProductIDs.service"
import { useUserProducts } from "components/UserProductsProvider"
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom"
import { config } from "../../config"
import { MetaTags } from "../../MetaTags"
import { useActiveMapDefinitions } from "./WeatherMapsRouteDefinitions"
import { useIntl } from "react-intl"

export const WeatherMapsRoute = () => {
  const { path } = useRouteMatch()
  const location = useLocation()
  const intl = useIntl()

  const { products: productsToFilter = [] } = useUserProducts()

  // Show all map products on localhost
  const products =
    config.api.environment === "localhost"
      ? allMapProductIDs
      : productsToFilter.filter(isMapProduct)

  const mapDefinitions = useActiveMapDefinitions(products as MapProductID[])

  if (products.length === 0 || mapDefinitions.length === 0) {
    return (
      <Paper>
        <Box padding={4}>
          Din bruker har ikke tilgang til noen kartprodukter.
        </Box>
      </Paper>
    )
  }

  return (
    <RedirectToFirstValidTab firstValidMapUrl={mapDefinitions[0].value.url}>
      <Paper>
        <Tabs
          value={location.pathname}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="tab-navigasjon for kart"
        >
          {mapDefinitions.map((definition) => {
            const {
              id,
              value: { title, url },
            } = definition
            return (
              <Tab
                key={id}
                label={intl.formatMessage({ id: title })}
                component={Link}
                value={`${path}${url}`}
                to={`${path}${url}`}
              />
            )
          })}
        </Tabs>
        <Box padding={3}>
          <Switch>
            {mapDefinitions.map((definition) => {
              const {
                id,
                value: { title, url, Map },
              } = definition
              return (
                <Route
                  key={id}
                  path={`${path}${url}`}
                  render={() => (
                    <>
                      <MetaTags title={intl.formatMessage({ id: title })} />
                      <Map />
                    </>
                  )}
                />
              )
            })}
          </Switch>
        </Box>
      </Paper>
    </RedirectToFirstValidTab>
  )
}

// Perform redirect before rendering <Tabs/> this
// prevents noise in the logs from Tabs not recognizing "/"
// as a valid value.
function RedirectToFirstValidTab({
  children,
  firstValidMapUrl,
}: {
  children: any
  firstValidMapUrl: string
}) {
  return (
    <Switch>
      <Redirect exact from="/maps" to={`/maps${firstValidMapUrl}`} />
      <Route path="*" render={() => children} />
    </Switch>
  )
}
